const initialState = {
  couponCode: '',
  coupon: null,
  couponChecked: false,
  upgradeSuccess: false,
};


export default function upgrade(state = initialState, { type, payload }) {
  switch(type) {
    case 'upgrade_updateProps': {
      return {
        ...state,
        ...payload,
      };
    }
    case 'emailAssistAI_Upgrade_SUCCESS':
    case 'bookingsAI_Upgrade_SUCCESS': {
      return {
        ...state,
        upgradeSuccess: true,
      };
    }
    case 'upgrade_getCouponInfo_SUCCESS': {
      return {
        ...state,
        coupon: payload,
        couponChecked: true,
      };
    }
    case 'upgrade_getCouponInfo_ERROR': {
      return {
        ...state,
        couponChecked: true,
      };
    }
    default:
      return state;
  }
}