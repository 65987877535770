import React from 'react';
import * as moment from 'moment';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SingleSelect from '../../../style-guide/select/single-select';
import OptionsForText from './options-for-text';
import OptionsForDate from './options-for-date';
import OptionsForYesNo from './options-for-yesno';
import OptionsForDropdown from './options-for-dropdown';

const useStyles = makeStyles({
  margin: {
    marginTop: '8px'
  },
  textfield: {
    maxWidth: '200px'
  },
  underline: {
    textDecoration: 'underline'
  }
});

export const BasicInfoDisplayState = props => {
  const classes = useStyles();
  const { fieldName, dataType, widget = '', constraintType, constraintValue = {}, onRemove } = props;
  return (
    <Grid
      container
      sx={{
        border: '1px solid #e0e0e0',
        padding: '6px',
        borderRadius: '4px',
        marginBottom: '8px',
        flexWrap: 'nowrap',
      }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item container sx={{ flexWrap: 'wrap', alignItems: 'center' }}>
        <Typography variant="subtitle2" component="span">
          {fieldName}
        </Typography>&nbsp;
        {
          (() => {
            if (widget.indexOf('dropdown') > -1) {
              return (
                <>
                  <Typography variant="body2" component="span" className={classes.underline}>
                    {constraintType}
                  </Typography>
                  &nbsp;
                  {
                    (constraintValue.array) && constraintValue.array.map(str => (
                      <Chip label={str} key={str} size="small"/>
                    ))
                  }
                </>
              );
            }
            if (dataType === 'date') {
              const diff = constraintValue.startDate && constraintValue.endDate
                ? moment(constraintValue.endDate).diff(constraintValue.startDate, 'days')
                : null;

              return (
                <Typography variant="body2" component="span" className={classes.underline}>
                  {(() => {
                    if (diff === 1) return 'last 24 hours';
                    if (diff < 31) return `last ${diff} days`;
                    return `from ${moment(constraintValue.endDate).format('l')} to ${moment(constraintValue.startDate).format('l')}`
                  })()}
                </Typography>
              )
            }
            if (dataType === 'number') {
              return (
                <>
                  <Typography variant="body2" component="span" className={classes.underline}>
                    {constraintType}
                  </Typography>
                  &nbsp;
                    {(() => {
                      if (constraintType === 'is between') {
                        return (
                          <Typography variant="subtitle2" component="span">
                            {constraintValue.start} and {constraintValue.end}
                          </Typography>
                        )
                      }
                      if (constraintValue.number) {
                        return (
                          <Typography variant="subtitle2" component="span">
                            {constraintValue.number}
                          </Typography>
                        );
                      }
                      return '';
                    })()}
                </>
              );
            }
            if (dataType === 'boolean') {
              return (
                <>
                  <Typography
                    variant="body2"
                    component="span"
                    className={classes.underline}
                  >
                    {constraintType}
                  </Typography>&nbsp;
                  {
                    constraintType === 'is equal to' && (
                      <Typography variant="subtitle2" component="span">
                        {constraintValue.boolean ? 'Yes' : 'No'}
                      </Typography>
                    )
                  }
                </>
              )
            }
            return (
              <>
                <Typography
                  variant="body2"
                  component="span"
                  className={classes.underline}
                >
                  {constraintType}
                </Typography>&nbsp;
                <Typography variant="subtitle2" component="span">
                  {constraintValue.string || ''}
                </Typography>
              </>
            )
          })()
        }
      </Grid>
      <Grid item>
        <IconButton onClick={onRemove} size="large">
          <HighlightOffIcon fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
};
export const NewBasicInfoFilter = (props) => {
  const classes = useStyles();
  const { allFields = [] } = props;
  const [fieldSelected, setField] = React.useState({
    fieldKey: undefined,
    fieldName: undefined
  });
  const { fieldKey, fieldName, rawFieldData } = fieldSelected;
  const [constraintType, setConstraintType] = React.useState('is exactly');
  const [constraintValue, setConstraintValue] = React.useState({});

  const onSelectField = newOption => {
    setField({
      fieldKey: newOption ? newOption.value : undefined,
      fieldName: newOption ? newOption.label : undefined,
      rawFieldData: newOption ? newOption.original : undefined
    });
  };

  const addFilter = () => {
    props.addFilter({
      fieldKey,
      fieldName,
      fieldId: rawFieldData ? rawFieldData.id : undefined,
      constraintType,
      constraintValue,
      ...rawFieldData,
    });
    setField({ fieldKey: undefined, fieldName: undefined });
    setConstraintType('is exactly');
    setConstraintValue({});
  };
  return (
    <div className={classes.margin}>
      <SingleSelect
        value={
          fieldName && fieldKey
            ? { label: fieldName, value: fieldKey, original: rawFieldData }
            : null
        }
        options={allFields.map(obj => ({
          label: obj.name,
          value: obj.key,
          original: obj
        }))}
        placeholder="Select a data field..."
        onChange={onSelectField}
      />
      {(() => {
        if (rawFieldData) {
          if (rawFieldData.widget && rawFieldData.widget.indexOf('dropdown') > -1) {
            return (
              <OptionsForDropdown
                options={rawFieldData.widgetJson.options}
                constraintType={constraintType}
                constraintValue={constraintValue}
                onChangeType={setConstraintType}
                onChangeValue={setConstraintValue}
                addFilter={addFilter}
              />
            )
          }
          if (rawFieldData?.dataType === 'string') {
            return (
              <OptionsForText
                constraintType={constraintType}
                constraintValue={constraintValue}
                onChangeType={setConstraintType}
                onChangeValue={setConstraintValue}
                addFilter={addFilter}
              />
            );
          }
          if (rawFieldData.dataType === 'date') {
            return (
              <OptionsForDate
                {...constraintValue}
                onChangeValue={setConstraintValue}
                addFilter={addFilter}
              />
            );
          }
          if (rawFieldData.dataType === 'boolean') {
            return (
              <OptionsForYesNo
                constraintType={constraintType}
                constraintValue={constraintValue}
                onChangeType={setConstraintType}
                onChangeValue={setConstraintValue}
                addFilter={addFilter}
              />
            )
          }
        }
      })()}
    </div>
  );
};
