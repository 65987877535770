import * as R from 'ramda';
import { combineReducers } from 'redux';
const initialCreateNewPropertyState = {
  open: false,
  propertyType: '',
  label: '',
  fieldType: {},
  options: [],
};
export const createNewProperty = (state = initialCreateNewPropertyState, { type, payload }) => {
  switch (type) {
    case '@PMsettings-createNewProperty/open':
      return {
        ...state,
        open: true,
        ...payload,
      };
    case '@PMsettings-createNewProperty/close':
      return initialCreateNewPropertyState;
    case '@PMsettings-createNewProperty/updateProps':
      return {
        ...state,
        ...payload,
      };
    case '@PMsettings-createNewProperty/onClickCreate_SUCCESS':
      return initialCreateNewPropertyState;
    default:
      return state;
  }
}

const initialEditPropertyState = {
  open: false,
  newName: '',
  widgetJson: {
    options: [],
  },
  newOptions: [],
};
export const editProperty = (state = initialEditPropertyState, { type, payload }) => {
  switch (type) {
    case '@PMsettings-editProperty/open':
      return {
        ...state,
        open: true,
        ...payload,
      };
    case '@PMsettings-editProperty/close':
      return initialEditPropertyState;
    case '@PMsettings-editProperty/updateProps':
      return {
        ...state,
        ...payload,
      };
    case '@PMsettings/updateProperty_SUCCESS':
      return initialEditPropertyState;
    default:
      return state;
  }
}

const initialManageProperties = {
  propertyType: 'partner',
};

export const manageProperties = (state = initialManageProperties, { type, payload, args }) => {
  switch (type) {
    case '@@router/LOCATION_CHANGE':
      if (
        payload.location.pathname === '/partner-management/settings/data-properties'
        && payload.location.query
        && payload.location.query.propertyType
      ) {
        return {
          ...state,
          propertyType: payload.location.query.propertyType,
        };
      }
      return state;
    case '@PMsettings-manageProperties/setType':
      return {
        ...state,
        propertyType: payload,
      };
    case '@PMsettings-createNewProperty/onClickCreate_SUCCESS':
      return {
        ...state,
        propertyType: R.path([0, 'propertyType'], args) || state.propertyType,
      };
    default:
      return state;
  }
};

export default combineReducers({
  createNewProperty,
  manageProperties,
  editProperty,
});
