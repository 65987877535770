import * as cnt from './constants';

const initialState = {
  activities: [],
  activitiesLoading: false,
  activitiesLoaded: false,
  contacts: [],
  contactsLoading: false,
  contactsLoaded: false,
  locations: [],
  locationsLoading: false,
  locationsLoaded: false,
  files: [],
  filesLoading: false,
  filesLoaded: false,
  partnerInfo: {},
  partnerInfoLoading: false,
  partnerInfoLoaded: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case `${cnt.GET_PARTNER_INFO}_STARTED`:
      return {
        ...state,
        partnerInfoLoading: true,
        contactsLoading: true,
        locationsLoading: true
      };
    case `${cnt.GET_PARTNER_INFO}_ERROR`:
      return {
        ...state,
        partnerInfoLoading: false,
        contactsLoading: false,
        locationsLoading: false,
        error: payload
      };
    case `${cnt.GET_PARTNER_INFO}_SUCCESS`:
      const { partner, contacts, tags, locations } = payload;
      return {
        ...state,
        contactsLoading: false,
        contactsLoaded: true,
        locationsLoading: false,
        locationsLoaded: true,
        partnerInfoLoading: false,
        partnerInfoLoaded: true,
        partnerInfo: partner,
        locations,
        tags,
        contacts,
        error: ''
      };
    case `${cnt.GET_FILES}_STARTED`:
      return { ...state, filesLoading: true };
    case `${cnt.GET_FILES}_SUCCESS`:
      return {
        ...state,
        files: payload.contracts,
        filesLoaded: true,
        filesLoading: false
      };
    case `${cnt.GET_FILES}_ERROR`:
      return { ...state, filesLoaded: false, filesLoading: false };
    case `${cnt.GET_ACTIVITY}_STARTED`:
      return { ...state, activitiesLoading: true };
    case `${cnt.GET_ACTIVITY}_SUCCESS`:
      return {
        ...state,
        activitiesLoaded: true,
        activitiesLoading: false,
        activities: payload
      };
    case `${cnt.GET_ACTIVITY}_ERROR`:
      return { ...state, activitiesLoaded: false, activitiesLoading: false };
    case cnt.CLEAR_STORE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
