import React, { useEffect, Suspense, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { defaultTheme } from '../../utils/mui-themes';
import SearchResults from './search-results';
import SearchResultsHeader from './search-results-header';
import Logs from './logs';
import LogsHeader from './logs-header';

// const BookingsAISignUp = React.lazy(() =>
//   import('./bookings-ai/index')
// );

const theme = createTheme(defaultTheme);

const useStyles = makeStyles({
  main: {
    padding: '0.5em',
  },
})

const AdminApp = ({
  tokenCheck,
  tokenCheckLoading,
  tokenLastUpdate,
})=> {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    const interval = setInterval(() => {
      tokenCheck();
    }, 30e3);
    tokenCheck();
    return () => clearInterval(interval);
  }, [tokenCheck]);

  const [searchString, setSearchTerm] = useState('');

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Grid container spacing={2} className={classes.main} alignItems="center">
          <Grid item xs={2}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Search"
              variant="outlined"
              value={searchString}
              onChange={event => {
                setSearchTerm(event.target.value);
              }}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  if (searchString.trim().length > 0) {
                    history.push(`/admin/search/${encodeURI(searchString)}`);
                  }
                  ev.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <Switch>
              <Route
                path="/admin/search/:searchString"
                component={SearchResultsHeader}
              />
              <Route
                path="/admin/logs/:entity/:id"
                component={LogsHeader}
              />
              <Route>Admin</Route>
            </Switch>
          </Grid>
          <Grid item xs={12}>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route
                  path="/admin/logs/:entity/:id"
                  component={Logs}
                />
                <Route
                  path="/admin/search/:searchString"
                  component={SearchResults}
                />
              </Switch>
            </Suspense>
          </Grid>
        </Grid>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const mapActions = {
  tokenCheck: () => ({
    type: '{admin} tokenCheck'
  }),
};

const mapState = ({ admin: {
  tokenCheckLoading,
  tokenLastUpdate,
} }) => ({
  tokenCheckLoading,
  tokenLastUpdate,
});

export default hot(module)(connect(mapState, mapActions)(AdminApp));
