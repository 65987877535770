import * as R from 'ramda';
import {
  takeLatest,
  select,
  delay,
  take,
  put,
} from 'redux-saga/effects';
import {
  ti2 as ti2Apis,
  generativeAi as genAiApis,
} from '../../../../api.js';
import { apiSaga } from '../../../../root-sagas.js';

export function* _getAccountInfo() {
  let account;
  while (true) {
    account = yield select(R.path(['account']));
    if (account && account.company && account.company.companyId) break;
    yield take('@app/GET_ACCOUNT_INFO_SUCCESS');
  }
  const {
    company: { companyId },
    travelMLSettings: {
      ti2: {
        token: ti2token,
      },
    },
  } = account;
  return { companyId, ti2token };
}
function* getAppKeyData(payload = {}) {
  delay(2000);
  const { companyId, ti2token } = yield _getAccountInfo();
  yield apiSaga('genAi_editAppKey_loadInitialData', ti2Apis.getAppKey, [{
    ...payload,
    companyId,
    ti2token,
  }])
}

function* getNotEnabledList() {
  const { companyId, ti2token } = yield _getAccountInfo();
  const { configuration: { productFilters = [] } } = yield select(state => state.generativeAi.partnerSettings.editAppKey)
  const { hint, integrationApp } = yield select(state => state.generativeAi.partnerSettings.metadata)
  yield apiSaga('genAi_editAppKey_getNotInternetEnabledOptions', genAiApis.getNotInternetEnabledOptions, [{
    productFilters,
    companyId,
    ti2token,
    hint,
    app: integrationApp,
  }])
}

function* onLocationChange({ payload }) {
  const pathname = R.pathOr('', ['location', 'pathname'], payload);
  if (pathname.includes('generative-ai/partner-settings') && pathname.includes('edit-app-key-configuration')) {
    const [hint, app] = pathname.replace('/generative-ai/partner-settings/', '')
      .replace('/edit-app-key-configuration', '').split('/');
    yield put({ type: 'genAi_partnerSettings_updateProps', payload: { integrationApp: app, hint, pageTitle: 'Product Filters' } });
    yield getAppKeyData({ app, hint });
    const { companyId, ti2token } = yield _getAccountInfo();
    yield apiSaga('genAi_editAppKey_loadAllAppKeys', ti2Apis.getAllAppKeys, [{ companyId, ti2token }])
    yield getNotEnabledList();
  }
}

function* saveConfiguration({ type }) {
  const { companyId, ti2token } = yield _getAccountInfo();
  const { configuration } = yield select(state => state.generativeAi.partnerSettings.editAppKey)
  const { hint, integrationApp } = yield select(state => state.generativeAi.partnerSettings.metadata)
  yield apiSaga(type, ti2Apis.updateAppKey, [{
    companyId,
    ti2token,
    configuration,
    hint,
    app: integrationApp,
  }])
}

// eslint-disable-next-line
export default [
  takeLatest('@@router/LOCATION_CHANGE', onLocationChange),
  takeLatest('genAi_editAppKey_saveConfiguration', saveConfiguration),
  takeLatest('genAi_editAppKey_saveConfiguration_SUCCESS', getNotEnabledList),
];