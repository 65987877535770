import { takeLatest, select, debounce } from 'redux-saga/effects';
import { apiSaga } from '../../../../root-sagas';
import {
  contracting as cApi,
} from '../../../../api';

function* loadList() {
  const { tagsSelected, list } = yield select(state => state.contracting.contractList);
  yield apiSaga('@contracting_contract_list/getList', cApi.getContractList, [{ tagsSelected, skipLoader: Boolean(list.length) }]);
}

function* getContractList() {
  const pathname = window.location.pathname;
  if (pathname.indexOf('contract-list') > -1) {
    yield loadList();
  }
}

function* loadListAgainInBackground({ args }) {
  const pathname = window.location.pathname;
  if (pathname.indexOf('contract-list') > -1) {
    if (args[0].secondTime) return;
    const { tagsSelected, list } = yield select(state => state.contracting.contractList);
    yield apiSaga('@contracting_contract_list/getList', cApi.getContractList, [{
      tagsSelected,
      skipLoader: Boolean(list.length),
      secondTime: true,
      skipCache: true,
    }]);
  }
}

// eslint-disable-next-line
export default [
  takeLatest([
    '@@router/LOCATION_CHANGE',
  ], getContractList),
  takeLatest([
    '@contracting_contract_list/loadList',
  ], loadList),
  debounce(500, [
    '@contracting_contract_list/getList_SUCCESS',
    '@contracting/updateWorkspaceContract_SUCCESS',
    '@contracting/deleteOrUndelete_SUCCESS',
    '@contracting/assignFolders_SUCCESS',
  ], loadListAgainInBackground),
  debounce(500, '@contracting_contract_list/updateTagsSelected', loadList)
];
