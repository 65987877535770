const initialState = {
  open: false,
  contactId: '',
  searchPartnersResult: [],
  searchPartnersSuccess: false,
};
// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case '@associate_partner/OPEN':
      return {
        ...state,
        open: true,
        contactId: payload.contactId,
      };
    case '@associate_partner/associatePartner_SUCCESS': 
    case '@associate_partner/CLOSE':
      return initialState;
    case '@associate_partner/searchPartners_SUCCESS':
      return {
        ...state,
        searchPartnersResult: payload.partners,
        searchPartnersSuccess: true,
      };
    default:
      return state;
  }
};
