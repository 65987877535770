import React from 'react';
import * as R from 'ramda';
import { Grid, Button } from '@mui/material';
import RadioButton from '../../../style-guide/radio-buttons/radio-button';
import MultiSelect from '../../../style-guide/select/creatable-multi-select';

const OptionsForDropdown = ({
  options,
  constraintType,
  constraintValue,
  onChangeType,
  onChangeValue,
  addFilter,
}) => {
  const constraints = [
    { type: 'is any of', tf: true },
    { type: 'is none of', tf: true },
    { type: 'is known' },
    { type: 'is unknown' }
  ];
  const onChangeRadio = t => () => {
    onChangeType(t);
    if (t.indexOf('known') > -1) onChangeValue({ array: undefined });
  };
  const onChangeArray = selectedOptions => onChangeValue({ array: selectedOptions.map(o => o.value) });
  return <Grid sx={{ marginTop: '8px' }} container direction="column">
    {constraints.map(obj => (
      <Grid item key={obj.type} container alignItems="center" sx={{ marginLeft: '8px' }}>
        <RadioButton
          label={obj.type || ''}
          color="secondary"
          checked={constraintType === obj.type}
          onChange={onChangeRadio(obj.type)}
        />
        {obj.tf && constraintType === obj.type && (
          <MultiSelect
            options={options}
            value={(constraintValue.array || []).map(str => ({ label: str, value: str }))}
            onChange={onChangeArray}
            helperText="Required *"
          />
        )}
      </Grid>
    ))}
    <Grid item sx={{ marginTop: '8px' }}>
      <Button
        disabled={
          !constraintType ||
          ((constraintType === 'is exactly' ||
            constraintType === 'contains' ||
            constraintType === 'does not contain') &&
            R.isEmpty(constraintValue))
        }
        variant="contained"
        color="secondary"
        size="small"
        onClick={addFilter}
      >
        add filter
      </Button>
    </Grid>
  </Grid>;
};

export default OptionsForDropdown;
