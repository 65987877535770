import { takeLatest } from 'redux-saga/effects';
import { apiSaga } from '../../../../root-sagas';
import {
  partnerManagement as pmApi,
} from '../../../../api';


function* getBounceList({
  payload: {
    location: { pathname }
  }
}) {
  if (pathname.indexOf('bounce-list') > -1) {
    yield apiSaga('@partner-management/getBounceList', pmApi.getBounceList, []);
  }
}

function* markDoneFromBounceList({ type, payload }) {
  yield apiSaga(type, pmApi.markDoneFromBounceList, [payload])
}

// eslint-disable-next-line
export default [
  takeLatest('@@router/LOCATION_CHANGE', getBounceList),
  takeLatest('@partner-management/markDoneFromBounceList', markDoneFromBounceList),
];
