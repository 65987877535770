import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { connect } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import { hot } from 'react-hot-loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import * as colors from '@mui/material/colors';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateTimePicker } from '@mui/x-date-pickers-pro';


const useStyles = makeStyles({
  cell: {
    padding: '0 0.5em 0 0',
    verticalAlign: 'top',
  }
})

const pageSize = 50;

const Logs = ({ logsLoading, logs, pathname, updateLogQueryParams, getLogs }) => {

  const css = useStyles();
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const [pageNumber, setPageNumber] = React.useState((() => {
    if (params.page) return parseInt(params.page);
    return 1;
  })());

  React.useEffect(() => {
    if (!params.picked) setPicked(Object.keys(logs));
  }, [logs, params.picked])

  const [picked, setPicked] = React.useState(() => {
    if (params.picked) return params.picked.split(',');
    return Object.keys(logs);
  });


  const [shownRow, setShownRow] = React.useState(-1);

  const toggleRow = key => {
    if (shownRow === key) {
      setShownRow(-1);
    } else {
      setShownRow(key);
    }
  }


  const [dateTime, setDateTime] = React.useState(moment());

  React.useEffect(() => {
    const newDate = moment(`${dateTime.format('YYYY-MM-DD\\THH:mm')}+00`);
    updateLogQueryParams({
      dateTime: newDate,
    });
  }, [dateTime, updateLogQueryParams]);

  const [dateTimeStart, setDateTimeStart] = React.useState(moment().subtract(8, 'hours'));

  React.useEffect(() => {
    const newDate = moment(`${dateTimeStart.format('YYYY-MM-DD\\THH:mm')}+00`);
    updateLogQueryParams({
      dateTimeStart: newDate,
    });
  }, [dateTimeStart, updateLogQueryParams]);

  const togglePicked = key => {
    if (Object.keys(logs).indexOf(key) < 0) return Object.keys(logs);
    let newPicked = []
    if (picked.indexOf(key) > -1) {
      newPicked = picked.filter(e => e !== key)
    } else {
      newPicked = [...picked, key];
    }
    setPicked(newPicked);
    window.history.replaceState(null, "", `${pathname}?page=${pageNumber}&picked=${newPicked.join(',')}`)
  }

  const gotoPage = newPage => {
    setPageNumber(newPage);
    window.history.replaceState(null, "", `${pathname}?page=${newPage}&picked=${picked.join(',')}`)
  }

  const loadingComponent = (
    <Box sx={{ width: 'auto' }}> 
      <Skeleton width="auto" />
      <Skeleton width="auto" />
      <Skeleton width="auto" />
      <Skeleton width="auto" />
    </Box>
  );
  const shownLogs = R.pick(picked, logs);
  let allLogs = Object.entries(shownLogs);
  allLogs = allLogs.map(([type, content]) => content.map(row => ({ type, ...row })));
  allLogs = R.unnest(allLogs);
  allLogs = R.sort((a, b) => (new Date(b['@timestamp'])).getTime() - (new Date(a['@timestamp'])).getTime(), allLogs);

  // if (allLogs.length < 1) {
  //   return <Typography>Nothing found</Typography>
  // }
  const allColors = Object.values(colors);
  const currentPageItems = allLogs.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

  const pageRow = (
    <TableRow>
      <TableCell colSpan={4}>
        {[...Array(Math.ceil(allLogs.length / pageSize)).keys()].map(page => (
          <Button
            onClick={() => { gotoPage(page + 1) }}
            key={page}
            style={{
              paddingRight: '1em',
            }}
            variant={(page + 1) === pageNumber ? 'contained' : 'text'}
            size="small"
          >{page + 1}</Button>
        ))} [ {allLogs.length} entries ]
      </TableCell>
    </TableRow>
  );
  const rowEl = (row, isActive) => {
    if (isActive) {
      return (
        <pre>
          {JSON.stringify(row, null, 2)}
        </pre>
      )
    }
    return Object.entries(row).map(([ field, value ], attributeIndex) => (
      <span key={field} style={{
        color: allColors[attributeIndex % allColors.length][900],
        marginRight: '1em',
      }}
      >{((() =>{ 
        if (typeof value === 'object') {
          return JSON.stringify(value);
        } else {
          return value.toString();
        }
      })() || '').substring(0,50)}</span>
    ));
  }
  return (
    <>
      <FormGroup row>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="Events From (GMT)"
            value={dateTimeStart}
            ampm={false}
            onChange={(newValue) => {
              setDateTimeStart(newValue);
            }}
          />
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="Events Until (GMT)"
            value={dateTime}
            ampm={false}
            onChange={(newValue) => {
              setDateTime(newValue);
            }}
          />
        </LocalizationProvider>&nbsp;&nbsp;
        <Button onClick={() => { getLogs();  }}>Requery</Button>
        {Object.keys(logs).map(key => (
          <FormControlLabel
            key={key}
            control={<Checkbox
              checked={picked.indexOf(key) > -1}
              onClick={() => togglePicked(key)}
            />}
            label={ `${key} [${logs[key].length}]` }
          />
        ))}
      </FormGroup>
      {logsLoading && loadingComponent }
      {!logsLoading && <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableBody>
            {pageRow}
            {currentPageItems.map((row, index) => (
              <TableRow
                key={index}
              >
                <TableCell
                  className={css.cell}
                  onClick={() => { toggleRow(index) }}
                >{index === shownRow ? <ZoomInMapIcon fontSize="small"/> : <ZoomOutMapIcon fontSize="small"/> }</TableCell>
                <TableCell className={css.cell}>{row.type}</TableCell>
                <TableCell className={css.cell}>{row['@timestamp']}</TableCell>
                <TableCell scope="row" className={css.cell}>
                  {rowEl(R.omit(['@timestamp', 'type'], row), shownRow === index)}
                </TableCell>
              </TableRow>
            ))}
            {pageRow}
          </TableBody>
        </Table>
      </TableContainer>}
    </>
  );
};


const mapActions = {
  updateLogQueryParams: payload => ({
    type: '{admin} updateLogQueryParams',
    payload,
  }),
  getLogs: () => ({
    type: '{admin} getLogs',
  }),
};

export default hot(module)(connect(({
  admin: {
    logsLoading,
    logs,
  },
  router: {
    location: {
      pathname,
    },
  },
}) => ({ logsLoading, logs, pathname }), mapActions)(Logs));
