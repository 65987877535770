import * as R from 'ramda';
import * as moment from 'moment';

const initialState = {
  data: {
    unit: 'day',
    numUnits: 2,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    breakdownByProduct: false,
    breakdownByPaxType: false,
    files: [],
    prompt: '',
  },
  allUploadedFiles: [],
  promptLibrary: [],
};

export default function revenueInsights(state = initialState, { type, payload, args }) {
  switch(type) {
    case 'genAi_revenueInsights_allAssistantFiles_SUCCESS':
      return {
        ...state,
        allUploadedFiles: payload,
      };
    case 'genAI_revenueInsights_updateData': {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };
    }
    case 'genAi_getPromptLibrary':
      return {
        ...state,
        loadingLibrary: true,
      };
    case 'genAi_getPromptLibrary_SUCCESS': {
      const shouldPopulatePrompt = !(state.tcthreadId && R.pathOr(0, ['jobs', 'messages', 'length'], state));
      return {
        ...state,
        loadingLibrary: false,
        promptLibrary: payload,
        data: {
          ...state.data,
          requiredInputs: shouldPopulatePrompt ? R.pathOr([], [0, 'prompts', 0, 'requiredInputs'], payload) : [],
          promptLabel: shouldPopulatePrompt ? R.pathOr('', [0, 'prompts', 0, 'label'], payload) : '',
          prompt: shouldPopulatePrompt ? R.pathOr('', [0, 'prompts', 0, 'prompt'], payload) : '',
        }
      };
    }
    case 'genAi_revenueInsights_chatThreads_SUCCESS': {
      const shouldNotPopulatePrompt = R.pathOr(0, [0, 'jobs', 'messages', 'length'], payload);
      return {
        ...state,
        chatThreads: payload,
        tcthreadId: R.path([0, 'id'], payload),
        jobs: R.pathOr({}, [0, 'jobs'], payload),
        data: {
          ...state.data,
          requiredInputs: shouldNotPopulatePrompt ? [] : state.data.requiredInputs,
          promptLabel: shouldNotPopulatePrompt ? '' : state.data.promptLabel,
          prompt: shouldNotPopulatePrompt ? '' : state.data.prompt,
        }
      }
    }
    case 'genAI_generateRevenueInsightsJob_SUCCESS':
      return {
        ...state,
        tcthreadId: payload.tcthreadId || state.tcthreadId,
        jobs: payload,
      };
    case 'genAI_generateRevenueInsights_STARTED':
      return {
        ...state,
        jobs: {
          ...state.jobs,
          status: 'queued',
        },
        loadingResponseError: '',
      };
    case 'genAI_generateRevenueInsights_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          files: [],
          requiredInputs: [],
          promptLabel: '',
          prompt: '',
        },
        jobs: {
          ...state.jobs,
          ...payload,
        },
      };
    case 'genAI_generateRevenueInsightsJob_ERROR':
    case 'genAI_generateRevenueInsights_ERROR':
      return {
        ...state,
        jobs: {
          ...state.jobs,
          status: 'failed',
        },
        loadingResponseError: R.path(['json', 'error'], payload) || 'An error occurred, please refresh your page and try again.',
      };
    case 'genAI_revenueInsights_startNewThread':
      return {
        ...state,
        showLoader: true,
      };
    case 'genAI_revenueInsights_startNewThread_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          promptLabel: R.pathOr('', ['promptLibrary', 0, 'prompts', 0, 'label'], state),
          requiredInputs: R.pathOr([], ['promptLibrary', 0, 'prompts', 0, 'requiredInputs'], state),
          prompt: R.pathOr('', ['promptLibrary', 0, 'prompts', 0, 'prompt'], state),
        },
        showLoader: false,
        tcthreadId: null,
        jobs: null,
      };
    default:
      return state;
  }
}
