import { cnts } from './actions';

const initialState = {
  newsfeed: {
    list: [],
    unreadCount: null,
    unseenCount: null
  },
  messaging: {
    latestMessages: []
  },
  connections: {
    pendingConnections: [],
    unreadAccepted: []
  }
};

export default function notifications(
  state = initialState,
  { type, payload, args }
) {
  switch (type) {
    case `${cnts.GET_SM_NOTIFICATIONS}_SUCCESS`:
      return {
        ...state,
        loading: false,
        messaging: {
          ...state.messaging,
          latestMessages: payload.latestMessages
        },
        connections: {
          pendingConnections: payload.pendingConnections,
          unreadAccepted: payload.unreadAccepted
        }
      };
    case `${cnts.GET_NF_NOTIFICATIONS}_SUCCESS`:
      return {
        ...state,
        newsfeed: payload.newsfeed
      };
    case `${cnts.ACCEPT_CONNECTION}_STARTED`:
    case `${cnts.IGNORE_CONNECTION}_STARTED`:
      return {
        ...state,
        connections: {
          ...state.connections,
          pendingConnections: state.connections.pendingConnections.filter(
            obj => obj._id !== args[0]
          )
        }
      };
    case cnts.UPDATE_NEWSFEED_PROPS:
      return {
        ...state,
        newsfeed: {
          ...state.newsfeed,
          ...payload
        }
      };
    default:
      return state;
  }
}
