import * as R from 'ramda';

const initialState = {
  partner: null,
  stagingAIRules: [],
  modifiedStagingAIRules: [],
};

export default function productMappings(state = initialState, { type, payload, args }) {
  switch(type) {
    case 'genAi_productMappings_loadStagingAIRules_SUCCESS': {
      return {
        ...state,
        stagingAIRules: payload.map(o => ({
          ...o,
          ...(o.body && o.body.aiRuleId ? {
            severity: 'success',
            alertMsg: `This mapping have been saved as a AI rule ${o.body.aiRuleId}.`,
          } : {}),
          body: {
            ...o.body,
            options: R.path(['body', 'options', 'length'], o) ? o.body.options : [{}],
          }
        })),
      }
    }
    case 'genAi_productMappings_loadInitialData_partner_SUCCESS':
      return {
        ...state,
        partner: payload,
      }
    case 'genAi_productMappings_updateStagingAIRule':
      return {
        ...state,
        modifiedStagingAIRules: R.uniq([...state.modifiedStagingAIRules, payload.updatedRule.id]),
        stagingAIRules: R.update(payload.ruleIndex, payload.updatedRule, state.stagingAIRules),
      }
    case 'genAi_productMappings_createRulesInBatch':
      return {
        ...state,
        creatingRules: true,
      };
    case 'genAi_productMappings_createRulesInBatch_DONE':
      return {
        ...state,
        creatingRules: false,
        doneCreatingRules: true,
      };
    case 'genAi_productMappings_saveARule_STARTED':
      return {
        ...state,
        stagingAIRules: state.stagingAIRules.map(r => r.id === R.path([1, 'stagingRuleId'], args) ? {
          ...r,
          saving: true,
          severity: 'info',
          alertMsg: 'Saving your changes as an AI Rule...',
        } : r),
      };
    case 'genAi_productMappings_saveARule_ERROR':
      return {
        ...state,
        stagingAIRules: state.stagingAIRules.map(r => r.id === R.path([1, 'stagingRuleId'], args) ? {
          ...r,
          saving: false,
          severity: 'error',
          alertMsg: payload,
        } : r),
      };
    case 'genAi_productMappings_saveARule_SUCCESS':
      return {
        ...state,
        modifiedStagingAIRules: state.modifiedStagingAIRules.filter(id => id !== R.path([1, 'stagingRuleId'], args)),
        stagingAIRules: state.stagingAIRules.map(r => r.id === R.path([1, 'stagingRuleId'], args) ? {
          ...r,
          saving: false,
          severity: 'success',
          alertMsg: `Your changes have been saved as a AI rule ${payload.id}.`,
          body: { ...r.body, aiRuleId: payload.id } } : r),
      };
    default:
      return state;
  }
}
