import React from 'react';
import {
  Grid,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material';
import * as moment from 'moment';
import StartDateEndDatePicker from '../../sharables/start-date-end-date-picker';

const OptionsForDate = props => {
  const {
    startDate,
    endDate,
    onChangeValue,
    addFilter,
  } = props;
  const [dateRadio, setDateRadio] = React.useState(startDate && endDate ? moment(endDate).diff(startDate) : undefined);
  const handleDateRadio = val => () => {
    setDateRadio(val);
    if (typeof val === 'number') {
      onChangeValue({
        startDate: moment()
          .subtract(val, 'days')
          .toISOString(),
        endDate: moment().toISOString()
      });
    }
  };
  const handleDateChange = dateKey => date => {
    onChangeValue({
      startDate,
      endDate,
      [dateKey]: date,
    });
  };
  return (
    <Grid container direction="column">
      {[
        {
          val: 1,
          label: 'last 24 hours'
        },
        {
          val: 3,
          label: 'last 3 days'
        },
        {
          val: 7,
          label: 'last 7 days'
        },
        {
          val: 30,
          label: 'last 30 days'
        },
        {
          val: 'custom',
          label: 'custom date range'
        }
      ].map((obj, i) => (
        <FormControlLabel
          key={i}
          label={obj.label}
          control={
            <Radio
              color="secondary"
              checked={dateRadio === obj.val}
              onChange={handleDateRadio(obj.val)}
            />
          }
        />
      ))}
      {dateRadio === 'custom' && (
        <StartDateEndDatePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={handleDateChange('startDate')}
          setEndDate={handleDateChange('endDate')}
        />
      )}
      <Grid item sx={{ marginTop: '8px' }}>
        <Button
          disabled={(dateRadio === 'custom' && !(startDate || endDate)) || !dateRadio}
          variant="contained"
          color="secondary"
          size="small"
          onClick={addFilter}
        >
          add filter
        </Button>
      </Grid>
    </Grid>
  );
};

export default OptionsForDate;
