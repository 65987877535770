
import * as R from 'ramda';
import moment from 'moment-timezone';
import { matchPath } from 'react-router';
import { push as pushAction } from 'connected-react-router';
import { takeLatest, select, put, delay } from 'redux-saga/effects';
import { account as accountApis } from '../../../../api.js';
import { apiSaga } from '../../../../root-sagas.js';
import docCookie from '../../../../utils/docCookie.js';

function* onLocationChange({ payload }) {
  const pathname = R.path(['location', 'pathname'], payload);
  if (pathname === '/generative-ai-sign-up/CleverCompose') {
    yield put(pushAction('/generative-ai-sign-up/emailAssist'));
  }
  const genAiProduct = R.path(
    ['params', 'genAiProduct'],
    matchPath(pathname, {
      path: '/generative-ai-sign-up/:genAiProduct',
      exact: true,
      strict: true
    }));
  if (genAiProduct) {
    yield put({ type: 'generativeAiSignUp_onLoad', payload: { genAiProduct } });
  }
  if (R.pathOr('', ['location', 'search'], payload).indexOf('showMeetingSchedules') > -1) {
    yield put({ type: 'generativeAiSignUp_openMeetingSchedules' });
  }
}

function* doSignUp({ type }) {
  const store = yield select(state => state.generativeAiSignUp);
  const payload = R.pick(['email',
    'password',
    'firstName',
    'lastName',
    'companyName',
    'dateFormat',
    'emailClient',
    'companyId',
    'userId',
    'genAiProduct',
  ], store);
  let referral;
  if (docCookie.getItem('rewardful.referral')) {
    try {
      const referralObj = JSON.parse(docCookie.getItem('rewardful.referral'));
      referral = referralObj.id;
    } catch (err) {
      console.log('error in parsing cookie', err);
    }
  }
  yield apiSaga(type, accountApis.signUpGenerativeAiUser, [{
    ...payload,
    referral,
    region: moment.tz.guess(),
  }]);
}

function* doEmailCheck({ type }) {
  const { email } = yield select(state => state.generativeAiSignUp);
  yield apiSaga(type, accountApis.decryptEmail, [{ email }]);
}

function* signUpSuccess() {
  const { autoLoginLink } = yield select(state => state.generativeAiSignUp);
  if (autoLoginLink) {
    yield put({
      type: 'generativeAiSignUp_successMessage',
      payload: 'One moment please, we are logging you in right now...'
    });
    yield delay(4000);
    window.location.assign(autoLoginLink);
  } else {
    yield put({
      type: 'generativeAiSignUp_successMessage',
      payload: `We have sent you an email with a link to automatically log in. Please check your inbox.`
    });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest('@@router/LOCATION_CHANGE', onLocationChange),
  takeLatest('generativeAiSignUp_doSignUp', doSignUp),
  takeLatest('generativeAiSignUp_doEmailCheck', doEmailCheck),
  takeLatest('generativeAiSignUp_doSignUp_SUCCESS', signUpSuccess),
];
