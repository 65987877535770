
const initialState = {
  folderPath: '',
  tagsSelected: [],
  list: [],
};
// eslint-disable-next-line
export default (state = initialState, { type, payload, args }) => {
  switch (type) {
    case '@contracting_contract_list/getList_SUCCESS':
      return {
        ...state,
        list: payload,
      };
    case '@contracting_contract_list/openFolder':
      return {
        ...state,
        ...payload,
      };
    case '@contracting/updateWorkspaceContract_SUCCESS':
      return {
        ...state,
        list: state.list.map(o => {
          if (args[0] && args[0].docIds && args[0].docIds.indexOf(o._id) > -1) {
            return {
              ...o,
              ...args[0].updater,
            };
          }
          return o;
        })
      };
    case '@contracting_contract_list/removeFilters':
      return {
        ...state,
        folderPath: '',
        tagsSelected: [],
      };
    case '@contracting_contract_list/updateTagsSelected':
      return {
        ...state,
        tagsSelected: payload,
      };
    default:
      return state;
  }
};
