
import { combineReducers } from 'redux';
import configuration from './configure-reducer';
import itineraries from './itineraries-reducer';
import revenueInsights from './revenueInsignts-reducer';
import editAppKey from './partner-settings/edit-app-key-configuration-reducer';
import productMappings from './partner-settings/product-mappings-reducer';
import aiRules from './ai-rules-reducer';
import partnerSettings from './partner-settings/reducer';

export default combineReducers({
  configuration,
  itineraries,
  revenueInsights,
  editAppKey,
  productMappings,
  aiRules,
  partnerSettings,
})