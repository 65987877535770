import { cnts, doReset } from './hello-actions';
import { takeLatest, put } from 'redux-saga/effects';
import { apiSaga } from '../../../root-sagas';
import { admin } from '../../../api';

function* confirmReset() {
  const confirmed = window.confirm('You want to reset?');
  if (confirmed) {
    yield put(doReset());
  }
}

function* resendWorkspaceMessage({ type, payload }) {
  yield apiSaga(type, admin.resendWorkspaceMessage, [payload]);
}

export const helloSagas = [
  takeLatest(cnts.RESET_BTN_CLICK, confirmReset),
  takeLatest('@admin/resendWorkspaceMessage', resendWorkspaceMessage),
];
