import { put, all, select, takeLatest, delay } from 'redux-saga/effects';
import { apiSaga } from '../../../root-sagas';
import {
  contracting as cApi,
  account as acctApi,
} from '../../../api';

function* loadDraftDiff() {
  const { rateDocId, originFrom } = yield select(state => state.contracting.ratesDocIframe);
  if (originFrom === 'edited') {
    yield delay(500);
    yield apiSaga('{dummy-account} loadDraftDiff', cApi.getDiffReport, [{ docs: `draft:${rateDocId}:${rateDocId}`, onlyGetSummary: true }]);
  }
}

function* loadDataForIframe() {
  const { rateDocId, collection, forImport } = yield select(state => state.contracting.ratesDocIframe);
  if (rateDocId && collection && window.location.href.indexOf('/assets-landing') === -1 && !forImport) {
    yield apiSaga('{dummy-account} loadDataForIframe', cApi.loadDataForIframe, [{ rateDocId, collection }]);
  }
}
function* checkIfRateComplete({ type, payload, next, shouldBlock }) {
  const { rateDocId, collection, originFrom } = yield select(state => state.contracting.ratesDocIframe);
  yield all([
    apiSaga(type, cApi.loadDataForIframe, [{ rateDocId, collection }]),
    loadDraftDiff(),
  ]);
  const { completionPercent, rawDiffData } = yield select(state => state.contracting.ratesDocIframe);
  const isADraft = collection === 'draft';
  const rateInfoComplete = (() => {
    if (!isADraft) return true;
    if (originFrom === 'initial') {
      return completionPercent === 100;
    }
    return Boolean(completionPercent === 100 && rawDiffData.length);
  })();
  if (rateInfoComplete) {
    yield put({ type: next, payload });
  } else if (shouldBlock || (!rawDiffData.length && originFrom === 'edited')) {
    // if the draft does not contain any changes, we need to block because backend won't process
    if (!rawDiffData.length && originFrom === 'edited') window.alert(`You haven't made any changes yet.`);
    else window.alert('Please complete the rate sheet before continuing');
  } else {
    const confirmed = window.confirm('This rate sheet is not complete, are you sure that you want to continue?');
    if (confirmed) {
      yield put({ type: next, payload });
    }
  }
}

function* onCloseIframe() {
  // Currenly this only happens in omni search results
  const { searchInput, contractingDate, createdDate, updatedDate } = yield select(state => state.contracting.search);
  if (searchInput && searchInput.trim()) {
    yield apiSaga('@contracting/search', acctApi.omniSearch, [{ searchInput, contractingDate, createdDate, updatedDate, skipLoader: true }]);
  }
}

function* acceptDummyTemplate({ type, payload }) {
  const confirm = window.confirm(`Please be informed that by accepting this rate sheet, you will no longer be able to request a signature from your supplier.`);
  if (confirm) {
    yield apiSaga(type, cApi.acceptDummyTemplate, [{
      rateDocId: payload.rateDocId,
      from: 'buyer-acct-cr',
    }]);
  }
}
function* afterAcceptDummyTemplate() {
  const { searchInput, contractingDate, createdDate, updatedDate } = yield select(state => state.contracting.search);
  if (searchInput && searchInput.trim()) {
    yield apiSaga('@contracting/search', acctApi.omniSearch, [{ searchInput, contractingDate, createdDate, updatedDate }]);
  }
}

function* doRequestSignature({ type, payload }) {
  yield apiSaga(type, cApi.sendRCMsg, [{ ...payload, who: 'Supplier' }]);
}
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest('{dummy-account} RateDocIframe_open', loadDataForIframe),
  takeLatest('{dummy-account} RateDocIframe_checkIfRateComplete', checkIfRateComplete),
  takeLatest('{dummy-account} RateDocIframe_close', onCloseIframe),
  takeLatest('{dummy-account} ACCEPT_DUMMY_TEMPLATE', acceptDummyTemplate),
  takeLatest('{dummy-account} ACCEPT_DUMMY_TEMPLATE_SUCCESS', afterAcceptDummyTemplate),
  takeLatest('{dummy-account} REQUEST_SIGNATURE', doRequestSignature),
  takeLatest('{dummy-account} REQUEST_SIGNATURE_SUCCESS', loadDataForIframe),
];
