import * as R from 'ramda';
import {
  takeLatest,
  select,
  fork,
  delay,
  all,
  // put,
} from 'redux-saga/effects';
import moment from 'moment';
import {
  generativeAi as genAiApis,
} from '../../../api.js';
import { apiSaga } from '../../../root-sagas.js';

function* getPromptLibrary() {
  yield apiSaga('genAi_getPromptLibrary', genAiApis.getPromptLibrary, [{ tool: 'revenueInsights' }])
}

function* onLocationChange({ payload }) {
  if (R.path(['location', 'pathname'], payload).includes('generative-ai/revenueInsights')) {
    yield all ([
      apiSaga('genAi_revenueInsights_chatThreads', genAiApis.getChatThreads, ['revenueInsights']),
      apiSaga('genAi_revenueInsights_allAssistantFiles', genAiApis.getAllAssets, [{ contentType: 'revenueInsights' }]),
      getPromptLibrary(),
    ]);
    yield pollingAssetsTask();
  }
}
const fillTemplate = (templateString, templateVars) =>{
  // eslint-disable-next-line
  const handler = new Function('templateVars', [
    'const tagged = ( ' + Object.keys(templateVars).join(', ') + ' ) =>',
      '`' + templateString + '`',
    'return tagged(...Object.values(templateVars))'
  ].join('\n'))

  return handler(templateVars)
}

function* pollingAssetsTask () {
  const { jobs } = yield select(state => state.generativeAi.revenueInsights);
  if (!(jobs && jobs.status)) return;
  const terminalStatus = ['completed', 'failed', 'cancelled', 'expired'];
  let shouldStop = terminalStatus.includes(jobs.status);
  while (jobs && jobs.status && !shouldStop) {
    const retVal = yield apiSaga('genAI_generateRevenueInsightsJob', genAiApis.checkOpenaiRunStatusAndMessages, [{
      jobs,
      skipLoader: true
    }]);
    if (retVal.status && !terminalStatus.includes(retVal.status)) {
      yield delay(3000);
    } else shouldStop = true;
  }
  return true;
}

function* doGenerateRevenueInsights() {
  const { data, jobs, tcthreadId } = yield select(state => state.generativeAi.revenueInsights);
  const companyId = yield select(R.path(['account', 'company', 'companyId']));
  // Step Two, call ai to generate quote
  data.files.forEach(file => {
    file.keyPath = `${companyId}/revenueInsights/${encodeURIComponent(file.name)}`
  })
  const apiPayload = {
    awsFiles: data.files,
    user_message: fillTemplate(data.prompt, { ...data, moment }),
    assistant_id: 'asst_FTaoLJ4y7DVqaDLzQrcrYTso',
    openai_thread_id: jobs ? jobs.openai_thread_id : null,
    tcthreadId,
    skipLoader: true,
  }
  console.log(apiPayload);
  const retVal = yield apiSaga('genAI_generateRevenueInsights', genAiApis.openaiAssistant, [apiPayload])
  if (retVal) {
    const { status } = retVal;
    // https://platform.openai.com/docs/assistants/how-it-works/runs-and-run-steps
    // using the terminal status to determine if the job is done
    if (status && !['completed', 'failed', 'cancelled', 'expired'].includes(status)) {
      yield fork(pollingAssetsTask);
    }
  }
}

function* startNewThread({ type }) {
  const { tcthreadId } = yield select(state => state.generativeAi.revenueInsights);
  yield apiSaga(type, genAiApis.deassocChatThread, [tcthreadId]);
}
// eslint-disable-next-line
export default [
  takeLatest('@@router/LOCATION_CHANGE', onLocationChange),
  takeLatest('genAI_revenueInsights_onClickSubmit', doGenerateRevenueInsights),
  takeLatest('genAI_revenueInsights_startNewThread', startNewThread),
];