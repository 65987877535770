import { all, takeLatest, select, put, delay } from 'redux-saga/effects';
import { apiSaga } from '../../../root-sagas';
import {
  pmCnts,
  partnerListCnts as plCnts,
  addPartnersCnts,
  importFile,
  sendAssetsCnts,
  setDefaults,
  setDefaultsPayload,
  unarchivePartner,
  unarchiveContact,
  doSearchWithParams
} from './pm-actions';
import {
  refreshPartnerInfo,
  refreshActivities as refreshPartnerActivities,
} from '../partner-details/__store__/sagas';
import {
  refreshContactInfo,
  refreshActivities as refreshContactActivities,
} from '../contact-details/__store__/sagas';
import * as partnerDetailsCnts from '../partner-details/__store__/constants';
import messageTemplatesSagas from '../message-templates-signature/__store__/sagas';
import {
  partnerManagement as pmApis,
  partner as partnerApi,
  contracting,
  marketing,
} from '../../../api';
import {
  AppState,
  PartnerListSearchParams,
  PartnerType,
  RateTemplate,
  RateTemplateGroup,
  ImportFileSuccessPayloadType,
  ImportedRowType,
  RecipientType,
  ContactType,
  Assets
} from 'AllTypes';
import { replace, push } from 'connected-react-router';
import queryString from 'query-string';
import * as R from 'ramda';
import { getFiltersCount } from '../partner-list/filters/filters';
import { initialSearchParams } from './partner-list-reducer';

function* handleLocChange({
  payload: { action: routerAction }
}: {
  type: '@@router/LOCATION_CHANGE';
  payload: { action: string };
}) {
  const location: { pathname: string; search: string, state: { contactids?: string[], partnerids?: string } } = yield select(
    state => state.router.location
  );
  if (location.pathname === '/partner-management/send-assets'
    || location.pathname === '/partner-management/create-workspace'
  ) {
    // page could be routed from the old app, need to wait till the app init with Account info first
    const accountLoaded: boolean = yield select(
      state => state.account.accountLoaded
    );
    if (!accountLoaded) {
      yield delay(2e3);
    }
    // make sure all partners are loaded for the recipients section
    yield put({ type: plCnts.DO_SEARCH_WITH_PARAMS, payload: initialSearchParams });
    const {
      user: { emailSignature },
    } = yield select(state => state.account);
    const query = queryString.parse(location.search || '');
    const defaultObj = {
      openpanel: query.openpanel,
      go_back_to: query.go_back_to,
      subject: query.subject || '',
      defaultMessage: query.message ? `Hi,<br><br>${query.message}` : '',
      defaultSignature: emailSignature,
      defaultOpenAssetType: query.open_asset_type,
      doNotNotify: location.pathname === '/partner-management/create-workspace' && query.sendRequest !== 'yes',
    } as setDefaultsPayload;
    yield put(setDefaults(defaultObj));
    const partnerids = (location.state || {}).partnerids || query.partnerids;
    const contactids = (location.state || {}).contactids || query.contactids;
    if (partnerids || contactids) {
      const partnerIds = Array.isArray(partnerids)
        ? partnerids
        : [partnerids];
      const contactIds = (Array.isArray(contactids)
        ? contactids
        : [contactids]
      ).filter(x => x);
      let allPartners: PartnerType[] = yield select(
        state => state.PM.partnerList.list.partners
      );
      while(!allPartners.length) {
        yield delay(1e3);
        allPartners = yield select(
          state => state.PM.partnerList.list.partners
        );
      }
      if (partnerids) {
        const foundPartners = allPartners.filter((obj: PartnerType) => {
          return partnerIds.indexOf(obj.id) > -1;
        });
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        defaultObj.recipients = <RecipientType[]>foundPartners.map(
          (obj: PartnerType) => ({
            partnerId: obj.id,
            contactIds: obj.contacts.map(contact => contact.id)
          })
        ).filter(obj => obj.contactIds.length);
      } else if (contactids) {
        const foundPartners = allPartners.filter((obj: PartnerType) => {
          return obj.contacts.some(c => contactIds.indexOf(c.id) > -1);
        });
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        defaultObj.recipients = <RecipientType[]>foundPartners.map(
          (obj: PartnerType) => ({
            partnerId: obj.id,
            contactIds: contactIds.filter(id =>
              obj.contacts.some(contact => contact.id === id)
            )
          })
        ).filter(obj => obj.contactIds.length);
      }
      yield put(setDefaults(defaultObj));
    }
    if (query.open_asset_type === 'rates') {
      yield getTemplates();
    }
    if (query.open_asset_type === 'mms') {
      yield getMMs();
    }
    if (query.templategroupid || query.templateids) {
      const templateIds = Array.isArray(query.templateids)
        ? query.templateids
        : [query.templateids];
      const templateGroupId = Array.isArray(query.templategroupid)
        ? query.templategroupid[0]
        : query.templategroupid;
      yield getTemplates();
      const { templates, templateGroups } = yield select(
        state => state.PM.sendAssets.rateTemplateData
      );
      const foundTemplates = templates.filter((obj: RateTemplate) => {
        if (templateGroupId && obj.templateGroups) {
          return obj.templateGroups.indexOf(templateGroupId) > -1;
        }
        return templateIds.indexOf(obj._id) > -1;
      });
      const foundTemplateGroup = templateGroups.filter(
        (obj: RateTemplateGroup) => obj._id === templateGroupId
      );
      defaultObj.rates = {
        templates: foundTemplates,
        templateGroup: foundTemplateGroup
      };
      yield put(setDefaults(defaultObj));
    }
  } else if (location.pathname === '/partner-management/create-rc-container') {
    const query = queryString.parse(location.search || '');
    yield put(
      setDefaults({
        doNotNotify: true,
        rateConversionParty: query.rateConversionParty,
        go_back_to: query.go_back_to
      } as setDefaultsPayload)
    );
  } else if (
    (location.pathname === '/partner-management/partner-list/partner' ||
      location.pathname === '/partner-management/partner-list/contact') &&
    routerAction !== 'REPLACE'
  ) {
    const fromUrl = (queryString.parse(location.search) as unknown) as {
      sortBy: string;
      basicInfoFilters: string;
      lastInteracted: string;
      hasTagsCheckedForPartner?: string;
      withoutTagsCheckedForPartner?: string;
      hasTagsForPartner: string;
      withoutTagsForPartner: string;
      hasTagsCheckedForContact?: string;
      withoutTagsCheckedForContact?: string;
      hasTagsForContact: string;
      withoutTagsForContact: string;
    };
    // There got to be a better way to do this
    // but no time to do it now
    const searchParams = {
      listBy:
        location.pathname === '/partner-management/partner-list/partner'
          ? 'partner'
          : 'contact',
      ...fromUrl,
      hasTagsCheckedForPartner: fromUrl.hasTagsCheckedForPartner === 'true',
      hasTagsForPartner: fromUrl.hasTagsForPartner
        ? JSON.parse(decodeURIComponent(fromUrl.hasTagsForPartner))
        : [],
      withoutTagsCheckedForPartner: fromUrl.withoutTagsCheckedForPartner === 'true',
      withoutTagsForPartner: fromUrl.withoutTagsForPartner
        ? JSON.parse(decodeURIComponent(fromUrl.withoutTagsForPartner))
        : [],
      hasTagsCheckedForContact: fromUrl.hasTagsCheckedForContact === 'true',
      hasTagsForContact: fromUrl.hasTagsForContact
        ? JSON.parse(decodeURIComponent(fromUrl.hasTagsForContact))
        : [],
      withoutTagsCheckedForContact: fromUrl.withoutTagsCheckedForContact === 'true',
      withoutTagsForContact: fromUrl.withoutTagsForContact
        ? JSON.parse(decodeURIComponent(fromUrl.withoutTagsForContact))
        : [],
      sortBy: fromUrl.sortBy ? JSON.parse(fromUrl.sortBy) : {},
      basicInfoFilters: fromUrl.basicInfoFilters
        ? JSON.parse(fromUrl.basicInfoFilters)
        : [],
      lastInteracted: fromUrl.lastInteracted
        ? JSON.parse(decodeURIComponent(fromUrl.lastInteracted))
        : {},
      fromUrl: true
    } as PartnerListSearchParams;
    yield put(doSearchWithParams(searchParams));
  }
}

function* initPM() {
  yield all([
    apiSaga(pmCnts.GET_TAGS, pmApis.getTags, []),
    apiSaga(pmCnts.GET_FIELDS, pmApis.getFields, []),
    apiSaga('@partner-management/getAllTemplates', pmApis.getAllTemplates, []),
  ]);
}

const getSearchParams = (state: AppState) => state.PM.partnerList.searchParams;

function* loadPartners() {
  const searchParams: PartnerListSearchParams = yield select(getSearchParams);
  yield apiSaga(plCnts.GET_PARTNERS, pmApis.getPartners, [searchParams]);
}

function* loadList() {
  const searchParams: PartnerListSearchParams = yield select(getSearchParams);
  if (searchParams.listBy === 'partner') {
    yield loadPartners();
  } else {
    yield apiSaga(plCnts.GET_CONTACTS, pmApis.getContacts, [searchParams]);
  }
}

function* refreshList() {
  const searchParams: PartnerListSearchParams = yield select(getSearchParams);
  if (searchParams.listBy === 'partner') {
    yield apiSaga(plCnts.REFRESH_PARTNERS, pmApis.getPartners, [searchParams]);
  } else {
    yield apiSaga(plCnts.REFRESH_CONTACTS, pmApis.getContacts, [searchParams]);
  }
}

function* loadListSuccess() {
  const location: { pathname: string; search: string } = yield select(
    state => state.router.location
  );
  // only for partner-list page
  if (location.pathname.indexOf('partner-list') > -1) {
    const searchParams: PartnerListSearchParams = yield select(getSearchParams);
    const { totalCount: appliedFilterCount } = getFiltersCount(searchParams);
    const pathname = `/partner-management/partner-list/${searchParams.listBy}`;
    let search = '';
    if (appliedFilterCount || searchParams.searchInput) {
      const qs = queryString.stringify({
        ...searchParams,
        defaultSearchInput: encodeURIComponent(searchParams.searchInput),
        sortBy: JSON.stringify(searchParams.sortBy),
        basicInfoFilters: JSON.stringify(searchParams.basicInfoFilters),
        hasTagsForPartner: encodeURIComponent(JSON.stringify(searchParams.hasTagsForPartner)),
        withoutTagsForPartner: encodeURIComponent(
          JSON.stringify(searchParams.withoutTagsForPartner)
        ),
        hasTagsForContact: encodeURIComponent(JSON.stringify(searchParams.hasTagsForContact)),
        withoutTagsForContact: encodeURIComponent(
          JSON.stringify(searchParams.withoutTagsForContact)
        ),
        lastInteracted: encodeURIComponent(
          JSON.stringify(searchParams.lastInteracted)
        )
      });
      search = `?${qs}`;
    }
    // update url when necessary
    if (pathname !== location.pathname || search !== location.search) {
      yield put(replace(`${pathname}${search}`));
    }
  }
}

function* doSaveTagChanges() {
  const { payload, items } = yield select(state => state.PM.editDialog);
  yield apiSaga(pmCnts.SAVE_TAG_CHANGES, pmApis.saveTagChanges, [
    { ...payload, items }
  ]);
}

function* doSaveContacts() {
  const { payload, items } = yield select(state => state.PM.editDialog);
  yield apiSaga(pmCnts.SAVE_CONTACTS_FOR_PARTNER, partnerApi.saveContacts, [
    {
      contacts: payload.contacts,
      deletedContacts: payload.deletedContacts,
      partnerId: items[0].id
    }
  ]);
}

function* doMergePartners() {
  const { payload } = yield select(state => state.PM.editDialog);
  yield apiSaga(pmCnts.MERGE_PARTNERS, pmApis.mergePartners, [
    {
      partnerIdsToMerge: payload.partnerIdsToMerge,
      partnerIdToKeep: payload.partnerIdToKeep
    }
  ]);
}

function* doArchivePartners() {
  const { payload, items } = yield select(state => state.PM.editDialog);
  yield apiSaga(pmCnts.ARCHIVE_PARTNERS, pmApis.archivePartners, [
    { ...payload, partnerIds: items.map(R.prop('id')) }
  ]);
}

function* doArchiveContacts() {
  const { items } = yield select(state => state.PM.editDialog);
  yield apiSaga(pmCnts.ARCHIVE_CONTACTS, pmApis.archiveContacts, [
    { contactIds: items.map(R.prop('id')) }
  ]);
}

function* afterArchiveSuccess() {
  const pathname: string = yield select(
    state => state.router.location.pathname
  );
  if (pathname.indexOf('partner-details') > -1) {
    yield put(push('/partner-management/partner-list/partner'));
  } else if (pathname.indexOf('contact-details') > -1) {
    yield put(push('/partner-management/partner-list/contact'));
  } else {
    yield refreshDataInPage();
  }
}

function* doUnarchivePartner({
  type,
  payload
}: ReturnType<typeof unarchivePartner>) {
  const { partnerId } = payload;
  yield apiSaga(type, pmApis.unarchivePartner, [
    {
      partnerId
    }
  ]);
}

function* doUnarchiveContact({
  type,
  payload
}: ReturnType<typeof unarchiveContact>) {
  const { contactId } = payload;
  yield apiSaga(type, pmApis.unarchiveContact, [
    {
      contactId
    }
  ]);
}

function* refreshDataInPage() {
  const pathname: string = yield select(
    state => state.router.location.pathname
  );
  if (
    pathname.indexOf('partner-list') > -1 ||
    pathname.indexOf('send-assets') > -1 ||
    pathname.indexOf('/partner-management/create-workspace') > -1 ||
    pathname.indexOf('/partner-management/create-rc-container') > -1
  ) {
    yield refreshList();
    yield apiSaga(pmCnts.GET_TAGS, pmApis.getTags, []);
  }
  if (pathname.indexOf('partner-details') > -1) {
    yield all([
      refreshPartnerInfo(),
      refreshPartnerActivities(),
    ]);
  }
  if (pathname.indexOf('contact-details') > -1) {
    yield all([
      refreshContactInfo(),
      refreshContactActivities(),
    ]);
  }
  if (pathname.indexOf('bounce-list') > -1) {
    yield apiSaga('@partner-management/getBounceList', pmApis.getBounceList, []);
  }
}

function* doCreateOnePartner() {
  const onePartner: PartnerType = yield select(
    state => state.PM.addPartners.manually.onePartner
  );
  yield apiSaga(addPartnersCnts.CREATE_ONE_PARTNER, pmApis.createOnePartner, [
    onePartner
  ]);
}

function* doExport() {
  const { items } = yield select(state => state.PM.editDialog);
  yield apiSaga(pmCnts.EXPORT_CSV, pmApis.exportCSV, [{ items }]);
}

function* doImport({ type, payload }: ReturnType<typeof importFile>) {
  yield apiSaga(type, pmApis.importFile, [payload]);
}

function* afterImportFileSuccess({
  payload
}: {
  type: typeof addPartnersCnts.IMPORT_FILE_SUCCESS;
  payload: ImportFileSuccessPayloadType;
}) {
  const redirectTo: string = yield select(
    state => state.router.location.query.redirect_to
  );
  if (redirectTo.indexOf('send-assets') > -1) {
    const { recipientsMap, recipients } = [
      ...payload.dataRowsExisting,
      ...payload.dataRowsNew
    ].reduce(
      (
        result: {
          recipientsMap: {
            [key: string]: {
              [key: string]: boolean | string;
            };
          };
          recipients: RecipientType[];
        },
        obj: ImportedRowType,
        idx: number,
        src: ImportedRowType[]
      ) => {
        if (result.recipientsMap[obj.partner.id]) {
          result.recipientsMap[obj.partner.id][obj.contact.id] = true;
        } else {
          result.recipientsMap[obj.partner.id] = {
            [obj.contact.id]: true
          };
        }
        if (idx === src.length - 1) {
          result.recipients = Object.keys(result.recipientsMap).map(id => ({
            partnerId: id,
            contactIds: Object.keys(result.recipientsMap[id])
          }));
        }
        return result;
      },
      {
        recipientsMap: {},
        recipients: []
      }
    );
    yield put({
      type: sendAssetsCnts.SELECT_RECIPIENTS_AFTER_IMPORT,
      payload: {
        recipientsMap,
        recipients,
        fileName: payload.fileName
      }
    });
  }
  yield apiSaga(pmCnts.GET_TAGS, pmApis.getTags, []);
}

function* afterCreateOnePartnerSuccess({
  payload
}: {
  type: typeof addPartnersCnts.CREATE_ONE_PARTNER_SUCCESS;
  payload: { partner: PartnerType; contacts: ContactType[] };
}) {
  yield refreshDataInPage();
  const pathname: string = yield select(
    state => state.router.location.pathname
  );
  if (
    pathname.indexOf('send-assets') > -1 ||
    pathname.indexOf('/partner-management/create-workspace') > -1 ||
    pathname.indexOf('/partner-management/create-rc-container') > -1
  ) {
    yield put({
      type: sendAssetsCnts.SELECT_PARTNER,
      payload: {
        partner: {
          ...payload.partner,
          contacts: payload.contacts
        },
        checked: true
      }
    });
  }
}

function* goBackFromImport() {
  const redirectTo: string = yield select(
    state =>
      state.router.location.query.redirect_to ||
      '/partner-management/partner-list/partner'
  );
  yield put(push(redirectTo));
}

function* getTemplates() {
  yield apiSaga(sendAssetsCnts.GET_TEMPLATES, contracting.getTemplates, []);
}

function* getMMs() {
  yield apiSaga(sendAssetsCnts.GET_MM, marketing.getMarketingMaterials, []);
}

const doSendAssets = (sendTestEmail: boolean) =>
  function* sendAssets() {
    const sendAssetsReducer: { assets: Assets, messageHtml: string } = yield select(
      state => state.PM.sendAssets
    );
    const emailSignature: string = yield select(state => state.account.user.emailSignature);
    yield apiSaga(
      sendTestEmail
        ? sendAssetsCnts.SEND_TEST_EMAIL
        : sendAssetsCnts.SEND_ASSETS,
      pmApis.sendAssets,
      [
        {
          ...R.pick(
            [
              'recipients',
              'subject',
              'messageHtml',
              'personalizeGreeting',
              'greeting',
              'folders',
              'doNotNotify',
              'rateConversionParty',
              'messageForDL'
            ],
            sendAssetsReducer
          ),
          messageHtml: `${sendAssetsReducer.messageHtml || ''} ${emailSignature || ''}`,
          rates: sendAssetsReducer.assets.rates,
          uploads: sendAssetsReducer.assets.uploads,
          mms: sendAssetsReducer.assets.mms,
          requests: sendAssetsReducer.assets.requests,
          sendTestEmail
        }
      ]
    );
  };

export const PM = [
  takeLatest('@@router/LOCATION_CHANGE', handleLocChange),
  takeLatest(pmCnts.INIT, initPM),
  takeLatest(plCnts.GET_PARTNERS, loadList),
  takeLatest(plCnts.DO_SEARCH_WITH_PARAMS, loadList),
  takeLatest(plCnts.SORT_LIST, loadList),
  takeLatest(`${partnerDetailsCnts.UPDATE_PARTNER_DETAILS}_SUCCESS`, loadList),
  takeLatest(plCnts.GET_PARTNERS_SUCCESS, loadListSuccess),
  takeLatest(plCnts.GET_CONTACTS_SUCCESS, loadListSuccess),
  takeLatest(pmCnts.SAVE_TAG_CHANGES, doSaveTagChanges),
  takeLatest(pmCnts.SAVE_CONTACTS_FOR_PARTNER, doSaveContacts),
  takeLatest(pmCnts.MERGE_PARTNERS, doMergePartners),
  takeLatest(`${pmCnts.MERGE_PARTNERS}_SUCCESS`, refreshDataInPage),
  takeLatest(pmCnts.ARCHIVE_PARTNERS, doArchivePartners),
  takeLatest(`${pmCnts.ARCHIVE_PARTNERS}_SUCCESS`, afterArchiveSuccess),
  takeLatest(pmCnts.ARCHIVE_CONTACTS, doArchiveContacts),
  takeLatest(`${pmCnts.ARCHIVE_CONTACTS}_SUCCESS`, afterArchiveSuccess),
  takeLatest(pmCnts.UNARCHIVE_PARTNER, doUnarchivePartner),
  takeLatest(pmCnts.UNARCHIVE_CONTACT, doUnarchiveContact),
  takeLatest(pmCnts.UNARCHIVE_PARTNER_SUCCESS, refreshDataInPage),
  takeLatest(pmCnts.UNARCHIVE_CONTACT_SUCCESS, refreshDataInPage),
  takeLatest(`${pmCnts.SAVE_TAG_CHANGES}_SUCCESS`, refreshDataInPage),
  takeLatest(`${pmCnts.SAVE_CONTACTS_FOR_PARTNER}_SUCCESS`, refreshDataInPage),
  takeLatest(addPartnersCnts.CREATE_ONE_PARTNER, doCreateOnePartner),
  takeLatest(
    addPartnersCnts.CREATE_ONE_PARTNER_SUCCESS,
    afterCreateOnePartnerSuccess
  ),
  takeLatest(pmCnts.EXPORT_CSV, doExport),
  takeLatest(addPartnersCnts.IMPORT_FILE, doImport),
  takeLatest(addPartnersCnts.IMPORT_FILE_SUCCESS, afterImportFileSuccess),
  takeLatest(addPartnersCnts.GO_BACK_FROM_IMPORT, goBackFromImport),
  takeLatest(sendAssetsCnts.GET_TEMPLATES, getTemplates),
  takeLatest(sendAssetsCnts.GET_MM, getMMs),
  takeLatest(sendAssetsCnts.SEND_ASSETS, doSendAssets(false)),
  takeLatest(sendAssetsCnts.SEND_TEST_EMAIL, doSendAssets(true)),
  ...messageTemplatesSagas,
];
