import React from 'react';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import StartDateEndDatePicker from '../../sharables/start-date-end-date-picker';
import SelectTags from '../../../style-guide/select/select-tags';
import { BasicInfoDisplayState, NewBasicInfoFilter } from './basic-info-filter';
import { initialSearchParams } from '../../__store__/partner-list-reducer';
import {
  UserType,
  PartnerListSearchParams,
  BasicInfoFilterType,
  PMTagType,
  PMFieldType
} from 'AllTypes';
import * as R from 'ramda';
import pluralize from 'pluralize';
import moment from 'moment';

const useStyles = makeStyles({
  content: {
    width: 450,
    padding: '16px',
    paddingBottom: '48px'
  },
  totalLabel: {
    color: '#366f98'
  },
  select: {
    width: '100%'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  number: {
    color: '#fff',
    backgroundColor: '#366f98',
    borderRadius: '50%',
    width: '24px',
    lineHeight: '24px',
    fontSize: '14px',
    textAlign: 'center'
  },
  applyBtn: {
    padding: '12px 16px',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  }
});
type Props = {
  open: boolean;
  onClose: () => void;
  searchParams: PartnerListSearchParams;
  allFields: PMFieldType[];
  allTags: PMTagType[];
  allOwners: UserType[];
  applyFilters?: (searchParams: PartnerListSearchParams) => void;
};

export const getFiltersCount = ({
  basicInfoFilters = [],
  owners = [],
  connectionStatuses = [],
  hasTagsCheckedForPartner,
  withoutTagsCheckedForPartner,
  hasTagsCheckedForContact,
  withoutTagsCheckedForContact,
  lastInteracted
}: PartnerListSearchParams) => {
  const obj = {
    totalCount: basicInfoFilters.length,
    owner: 0, // max 1
    connection: 0, // max 1
    tagsForPartner: 0, // max 2
    tagsForContact: 0, // max 2
    basicInfo: basicInfoFilters.length,
    interaction: 0
  };
  if (owners.length) {
    obj.totalCount++;
    obj.owner++;
  }
  if (connectionStatuses.length) {
    obj.totalCount++;
    obj.connection++;
  }
  if (hasTagsCheckedForPartner) {
    obj.totalCount++;
    obj.tagsForPartner++;
  }
  if (withoutTagsCheckedForPartner) {
    obj.totalCount++;
    obj.tagsForPartner++;
  }
  if (hasTagsCheckedForContact) {
    obj.totalCount++;
    obj.tagsForContact++;
  }
  if (withoutTagsCheckedForContact) {
    obj.totalCount++;
    obj.tagsForContact++;
  }
  if (lastInteracted && lastInteracted.startDate) {
    obj.totalCount++;
    obj.interaction++;
  }
  return obj;
};

const Filters: React.FC<Props> = props => {
  const {
    open,
    onClose,
    // allOwners = [],
    allTags = [],
    searchParams: searchParamsInProps,
    allFields,
    applyFilters
  } = props;
  const classes = useStyles();
  const [searchParams, setSearchParams] = React.useState<
    PartnerListSearchParams
  >(searchParamsInProps);
  React.useEffect(() => {
    if (searchParamsInProps.fromUrl) {
      setSearchParams({
        ...searchParamsInProps,
        fromUrl: false
      });
    }
  }, [searchParamsInProps, setSearchParams]);
  const {
    // owners = [],
    // connectionStatuses = [],
    hasTagsCheckedForPartner,
    hasTagsForPartner = [],
    withoutTagsCheckedForPartner,
    withoutTagsForPartner = [],
    hasTagsCheckedForContact,
    hasTagsForContact = [],
    withoutTagsCheckedForContact,
    withoutTagsForContact = [],
    basicInfoFilters = [],
    lastInteracted = {}
  } = searchParams;
  // const onChangeOwners = (values: SelectValueType) =>
  //   setSearchParams({
  //     ...searchParams,
  //     owners:
  //       values && Array.isArray(values)
  //         ? values.map(obj => ({
  //             userId: obj.value + '',
  //             name: obj.label
  //           }))
  //         : []
  //   });
  // const onChangeConnectionFilter = (name: string) => (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => setSearchParams({
  //     ...searchParams,
  //     connectionStatuses: event.target.checked
  //       ? [...connectionStatuses, name]
  //       : connectionStatuses.filter(x => x !== name)
  //   });
  const onChangeTagsCheck = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) =>
    setSearchParams({
      ...searchParams,
      [name]: event.target.checked
    });
  const onChangeTags = (name: string) => (values: PMTagType[]) =>
    setSearchParams({
      ...searchParams,
      [name]: values
    });
  const removeBasicInfoFilter = (i: number) => () =>
    setSearchParams({
      ...searchParams,
      basicInfoFilters: R.remove(i, 1, basicInfoFilters)
    });
  const addBasicInfoFilter = (payload: BasicInfoFilterType) => {
    setSearchParams({
      ...searchParams,
      basicInfoFilters: [...basicInfoFilters, payload]
    });
  };
  const [dateRadio, setDateRadio] = React.useState<number | string | undefined>(
    lastInteracted && lastInteracted.startDate
    ? moment(lastInteracted.endDate).diff(lastInteracted.startDate, 'days')
    : undefined
  );
  const handleDateRadio = (val: number | string) => () => {
    setDateRadio(val);
    if (typeof val === 'number') {
      setSearchParams({
        ...searchParams,
        lastInteracted: {
          startDate: moment()
            .subtract(val, 'days')
            .toISOString(),
          endDate: moment().toISOString()
        }
      });
    }
  };
  const handleDateChange = (dateKey: 'startDate' | 'endDate') => (
    date: Date | undefined
  ) => {
    setSearchParams({
      ...searchParams,
      lastInteracted: {
        ...searchParams.lastInteracted,
        [dateKey]: date
      }
    });
  };
  const counts = getFiltersCount(searchParams);
  const handleApplyFilters = () =>
    applyFilters ? applyFilters({
      ...searchParams,
      listBy: searchParamsInProps.listBy,
    }) : undefined;
  const handleClearFilters = () => {
    const resettedSearchP = {
      ...initialSearchParams,
      listBy: searchParamsInProps.listBy,
    };
    setSearchParams(initialSearchParams);
    // we don't need to apply filters if the initial filters are empty
    if (applyFilters && getFiltersCount(searchParamsInProps).totalCount)
      return applyFilters(resettedSearchP);
    return undefined;
  };
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div className={classes.content}>
        {/* next iteration
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid
              container
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Typography>Owners</Typography>
              <Typography className={classes.number}>{counts.owner}</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <MultiSelect
              className={classes.select}
              value={owners.map((obj: UserType) => ({
                label: obj.name || obj.email || obj.userId,
                value: obj.userId
              }))}
              onChange={onChangeOwners}
              options={allOwners.map((obj: UserType) => ({
                label: obj.name || obj.email || obj.userId,
                value: obj.userId
              }))}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid
            container
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Typography>Connections</Typography>
            <Typography className={classes.number}>
              {counts.connection}
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {[
            'connected',
            'pending - received',
            'pending - sent',
            'pending - suggested',
            'not on TourConnect'
          ].map((str: string) => (
            <FormControlLabel
              key={str}
              control={
                <Checkbox
                  checked={connectionStatuses.indexOf(str) > -1}
                  onChange={onChangeConnectionFilter(str)}
                />
              }
              label={str}
            />
          ))}
        </AccordionDetails>
      </Accordion>
        */}
        {
          ['Partner', 'Contact'].map((type, i) => {
            const hasTagsChecked = type === 'Partner' ? hasTagsCheckedForPartner : hasTagsCheckedForContact;
            const hasTags = type === 'Partner' ? hasTagsForPartner : hasTagsForContact;
            const withoutTagsChecked = type === 'Partner' ? withoutTagsCheckedForPartner : withoutTagsCheckedForContact;
            const withoutTags = type === 'Partner' ? withoutTagsForPartner : withoutTagsForContact;
            const tagsCount = type === 'Partner' ? counts.tagsForPartner : counts.tagsForContact;
            return (
              <Accordion key={i}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid
                    container
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography>{type} Tags</Typography>
                    <Typography className={classes.number}>{tagsCount}</Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasTagsChecked}
                        color="secondary"
                        onChange={onChangeTagsCheck(`hasTagsCheckedFor${type}`)}
                      />
                    }
                    label="has tag(s)"
                  />
                  {hasTagsChecked && (
                    <SelectTags
                      allTags={allTags}
                      tagSelected={hasTags}
                      onChange={onChangeTags(`hasTagsFor${type}`)}
                    />
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={withoutTagsChecked}
                        onChange={onChangeTagsCheck(`withoutTagsCheckedFor${type}`)}
                      />
                    }
                    label="without tag(s)"
                  />
                  {withoutTagsChecked && (
                    <SelectTags
                      allTags={allTags}
                      tagSelected={withoutTags}
                      onChange={onChangeTags(`withoutTagsFor${type}`)}
                    />
                  )}
                </AccordionDetails>
              </Accordion>);
          })
        }
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid
              container
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Typography>Basic Information</Typography>
              <Typography className={classes.number}>
                {counts.basicInfo}
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            {basicInfoFilters.map((obj: BasicInfoFilterType, i: number) => (
              <BasicInfoDisplayState
                {...obj}
                key={i}
                onRemove={removeBasicInfoFilter(i)}
              />
            ))}
            {Boolean(basicInfoFilters.length) && (
              <Typography variant="overline">Add another filter</Typography>
            )}
            <NewBasicInfoFilter
              allFields={allFields}
              addFilter={addBasicInfoFilter}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid
              container
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Typography>Interaction</Typography>
              <Typography className={classes.number}>
                {counts.interaction}
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column">
              {[
                {
                  val: 1,
                  label: 'last 24 hours'
                },
                {
                  val: 3,
                  label: 'last 3 days'
                },
                {
                  val: 7,
                  label: 'last 7 days'
                },
                {
                  val: 30,
                  label: 'last 30 days'
                },
                {
                  val: 'custom',
                  label: 'custom date range'
                }
              ].map((obj, i) => (
                <FormControlLabel
                  key={i}
                  label={obj.label}
                  control={
                    <Radio
                      color="secondary"
                      checked={dateRadio === obj.val}
                      onChange={handleDateRadio(obj.val)}
                    />
                  }
                />
              ))}
              {dateRadio === 'custom' && (
                <StartDateEndDatePicker
                  startDate={lastInteracted.startDate}
                  endDate={lastInteracted.endDate}
                  setStartDate={handleDateChange('startDate')}
                  setEndDate={handleDateChange('endDate')}
                />
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Paper elevation={2} className={classes.applyBtn}>
          <Typography variant="subtitle2" className={classes.totalLabel}>
            {counts.totalCount} {pluralize('filter', counts.totalCount)} added
          </Typography>
          <div>
            <Button
              disabled={!counts.totalCount}
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleClearFilters}
            >
              Clear All
            </Button>
            &nbsp; &nbsp;
            <Button
              disabled={!counts.totalCount}
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleApplyFilters}
            >
              Apply
            </Button>
          </div>
        </Paper>
      </div>
    </Drawer>
  );
};

export default Filters;
