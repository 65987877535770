
const initialState = {
  open: false,
  title: '',
  docId: '',
  resendToEmails: [],
  partner: {},
  partners: [],
};
// eslint-disable-next-line
export default (state = initialState, { type, payload, args }) => {
  switch (type) {
    case '@contracting_workspace_sendRequest/open':
      return {
        ...state,
        open: true,
        ...payload,
      };
    case '@contracting_workspace_sendRequest/send_SUCCESS':
    case '@contracting_workspace_sendRequest/close':
      return initialState;
    default:
      return state;
  }
};
