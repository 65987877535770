import { omit } from 'ramda';

const walls = wallType => {
  if (wallType) return { url: `//www.tourconnect.com/${wallType}` };
  return { url: '//www.tourconnect.com/upgrade-gen-send-assets/' };
};

const initialState = {
  open: false,
  ...walls(),
  blocker: false,
  skipBack: false
};
export default function wallReducer(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_BULK_SEND_ASSETS_BLOCKER':
      return {
        ...state,
        ...walls('upgrade-gen-send-assets'),
        blocker: true,
        open: true,
        ...action.payload
      };
    case '[wall] openClose': // reducer action maches
      const { payload: open } = action;
      return {
        ...state,
        ...walls(),
        open,
        blocker: open ? state.blocker : false
      };
    case '[wall] openSlug':
      const {
        payload,
        payload: { slug }
      } = action;
      return {
        ...state,
        ...walls(slug),
        ...omit(['slug'], payload),
        open: true
      };
    // case '@send-assets/SEND_ASSETS': // other reducer matches
    //   return {
    //     ...state,
    //     ...walls('upgrade-gen-send-assets'),
    //     open: true,
    //     blocker: false
    //   };
    case '@PM/EXPORT_CSV':
      return {
        ...state,
        ...walls('upgrade-pm-csv'),
        open: true,
        blocker: false
      };
    case '@partner-details/GET_PARTNER_INFO_SUCCESS':
      const {
        payload: {
          partner: { locked }
        }
      } = action;
      if (locked)
        return {
          ...state,
          ...walls('upgrade-page-lkd-partners'),
          open: true,
          blocker: true,
          skipBack: false
        };
      break;
    default:
      return state;
  }
  return state;
}
