import * as R from 'ramda';
const initialState = {
  allRules: [],

  assignPartners: {
    open: false,
    rules: [],
    selectedPartnersToAdd: [],
    selectedPartnersToRemove: [],
    appliedToAllPartners: false,
  },
  editRule: {
    open: false,
  }
};

export default function aiRules(state = initialState, { type, payload, args, additionalPayload }) {
  switch(type) {
    case 'genAi_aiRules_loadAllRules_SUCCESS': {
      return {
        ...state,
        allRules: payload,
      }
    }
    case 'genAi_aiRules_loadAllAppKeys_SUCCESS':
      return {
        ...state,
        allAppKeys: payload.userAppKeys,
      };
    case 'genAi_aiRules_assignPartners_forOneRule_STARTED': {
      return {
        ...state,
        assignPartners: {
          ...state.assignPartners,
          rules: state.assignPartners.rules.map(rule => rule.id === args[0].id ? {
            ...rule,
            loading: true,
          } : rule),
        }
      };
    }
    case 'genAi_aiRules_assignPartners_forOneRule_SUCCESS': {
      return {
        ...state,
        allRules: state.allRules.map(rule => rule.id === args[0].id ? payload : rule),
        assignPartners: {
          ...state.assignPartners,
          rules: state.assignPartners.rules.map(rule => rule.id === args[0].id ? {
            ...payload,
            success: true,
          } : rule),
        }
      };
    }
    case 'genAi_aiRules_assignPartners_forOneRule_ERROR': {
      return {
        ...state,
        assignPartners: {
          ...state.assignPartners,
          rules: state.assignPartners.rules.map(rule => rule.id === args[0].id ? {
            ...rule,
            loading: false,
            error: payload,
            success: false,
          } : rule),
        }
      };
    }
    case 'genAi_aiRules_deleteOneRule_STARTED': {
      return {
        ...state,
        allRules: state.allRules.map(rule => rule.id === args[0] ? {
          ...rule,
          loading: true,
        } : rule),
      };
    }
    case 'genAi_aiRules_deleteOneRule_SUCCESS': {
      return {
        ...state,
        allRules: state.allRules.filter(rule => rule.id !== args[0]),
      }
    }
    case 'genAi_aiRules_deleteOneRule_ERROR': {
      return {
        ...state,
        allRules: state.allRules.map(rule => rule.id === args[0] ? {
          ...rule,
          loading: false,
        } : rule),
      }
    }
    case 'genAi_aiRules_assignPartners_open': {
      return {
        ...state,
        assignPartners: {
          ...state.assignPartners,
          open: true,
          ...payload,
        }
      };
    }
    case 'genAi_aiRules_assignPartners_assign_SUCCESS':
    case 'genAi_aiRules_assignPartners_close':
      return {
        ...state,
        assignPartners: initialState.assignPartners,
      };
    case 'genAi_aiRules_assignPartners_updateProps': {
      return {
        ...state,
        assignPartners: {
          ...state.assignPartners,
          ...payload,
        }
      };
    }
    case 'genAi_aiRules_editRule_open':
      return {
        ...state,
        editRule: {
          open: true,
          ...payload,
        }
      };
    case 'genAi_aiRules_editRule_updateProps':
      return {
        ...state,
        editRule: {
          ...state.editRule,
          ...payload,
        }
      };
    case 'genAi_aiRules_editRule_save_SUCCESS':
      return {
        ...state,
        allRules: state.allRules.map(rule => rule.id === payload.id ? payload : rule),
        editRule: initialState.editRule,
      };
    case 'genAi_aiRules_editRule_close':
      return {
        ...state,
        editRule: initialState.editRule,
      };
    case 'genAi_aiRules_editRule_productRuleEval_SUCCESS':
      return {
        ...state,
        editRule: {
          ...state.editRule,
          body: {
            ...state.editRule.body,
            evalJobs: [
              ...R.pathOr([], ['editRule', 'body', 'evalJobs'], state),
              ...payload.jobs.map(j =>({ ...j, partnerId: args[0].ti2.hint, testMatcher: args[0].testMatcher})),
            ]
          },
        },
      }
    case 'genAi_aiRules_editRule_productRuleEval_JobCheckComplete':
      return {
        ...state,
        editRule: {
          ...state.editRule,
          body: {
            ...state.editRule.body,
            evalJobs: state.editRule.body.evalJobs.map(j => {
              const foundJob = payload.find(p => p.jobId === j.jobId);
              return {
                ...j,
                ...(foundJob ? {
                  ...R.omit(['info', 'result'], foundJob),
                  result: { wining_rule: R.pathOr({}, ['result', 'wining_rule'], foundJob) },
                  lastEvaluated: new Date().toISOString(),
                } : {}),
              };
            }),
          },
        },
      };
    case 'genAi_aiRules_evaluateSelectedProductRules':
      return {
        ...state,
        rulesToEvaluate: payload,
      };
    case 'genAi_aiRules_productRuleEval_singleRow_SUCCESS':
      return {
        ...state,
        allRules: state.allRules.map(rule => rule.id === args[1].id ? {
          ...rule,
          body: {
            ...rule.body,
            evalJobs: [
              ...R.pathOr([], ['body', 'evalJobs'], rule),
              ...payload.jobs.map(j => ({ ...j, partnerId: args[0].ti2.hint, testMatcher: args[0].testMatcher })),
            ],
          },
        } : rule),
      };
    case 'genAi_aiRules_productRuleEval_singleRow_JobCheckComplete': {
      const ruleId = additionalPayload ? additionalPayload.id : null;
      if (!ruleId) return state;
      const allRules = state.allRules.map(rule => ruleId === rule.id ? ({
        ...rule,
        body: {
          ...rule.body,
          evalJobs: rule.body.evalJobs.map(j => {
            const foundJob = payload.find(p => p.jobId === j.jobId);
            return {
              ...j,
              ...(foundJob ? {
                ...R.omit(['info', 'result'], foundJob),
                result: { wining_rule: R.pathOr({}, ['result', 'wining_rule'], foundJob) },
                lastEvaluated: new Date().toISOString()
              } : {}),
            };
          }),
        },
      }) : rule);
      return {
        ...state,
        rulesToEvaluate: state.rulesToEvaluate.filter(id => id !== ruleId),
        allRules,
      };
    }
    default:
      return state;
  }
}
