import { takeLatest, put, select, all, delay } from 'redux-saga/effects';
import { apiSaga } from '../../../root-sagas';
import { travelML as apis } from '../../../api';
import * as R from 'ramda';

export const showSuccessSnackbarAndRefreshSupplierData = function*() {
  yield all([
    put({
      type: 'global_openSnackBar',
      payload: {
        snackbarMessage: 'Success!',
        snackBarSeverity: 'success'
      }
    }),
    put({ type: 'ML_getAllCollection' })
  ]);
};

export const showErrorSnackbar = function*({ payload }) {
  yield put({
    type: 'global_openSnackBar',
    payload: {
      snackbarMessage: `Uhoh! Error! ${payload || ''}`,
      snackBarSeverity: 'error'
    }
  });
};

function* onLocChange() {
  const { pathname } = yield select(state => state.router.location);
  if (pathname.indexOf('/exports/closeouts/simplified') > -1) {
    const { params } = yield select(state => state.ML.exports.closeouts);
    yield put({ type: 'ML_getAllStopSaleExports', payload: {
      ...params,
      version: 'simplified',
    }});
  } else if (pathname.indexOf('/exports/closeouts') > -1) {
    const { params } = yield select(state => state.ML.exports.closeouts);
    yield put({ type: 'ML_getAllStopSaleExports', payload: params });
  } else if (pathname.indexOf('/exports/offers') > -1) {
    const { params } = yield select(state => state.ML.exports.offers);
    yield put({ type: 'ML_getAllOffersExports', payload: params });
  } else if (pathname.indexOf('/collections') > -1) {
    yield put({ type: 'ML_getAllCollection' });
  }
}

function* saveSettings() {
  yield put({ type: '{ML} SaveSettings_SUCCESS' });
}

function* saveEntitySettings({ type }) {
  const { levels } = yield select(
    state => state.ML.settings.entitySettingsInEdit
  );
  yield apiSaga(type, apis.updateEntityLevels, [
    {
      entityLevels: levels
    }
  ]);
}

function* getStopSaleExports({ type, payload }) {
  yield apiSaga(type, apis.getStopSaleExports, [payload]);
}

function* getOfferExports({ type, payload }) {
  yield apiSaga(type, apis.getOfferExports, [payload]);
}

function* getAllCollection({ type }) {
  const { rowsPage, rowsPerPage, rowsEntityName } = yield select(state => state.ML.suppliers);
  yield apiSaga(type, apis.getAllCollection, [{ rowsPage, rowsPerPage, rowsEntityName }]);
}

function* debounceGetAllCollection() {
  yield delay(300);
  yield put({ type: 'ML_getAllCollection' });
}

function* addCollection({ type, payload }) {
  yield apiSaga(type, apis.addCollection, [payload]);
}

function* updateACollectionRow({ type, payload }) {
  yield apiSaga(type, apis.updateACollectionRow, [payload]);
}

function* deleteARowFromCollection({ type, payload }) {
  yield apiSaga(type, apis.deleteARowFromCollection, [payload]);
}

function* retryStopSale({ type, payload }) {
  yield apiSaga(`${type} ${payload.stopSalesId}`, apis.retryStopSale, [payload]);
}

function* lookupMeta({ type, payload }) {
  yield delay(300);
  if (R.pathOr('', ['value'], payload).trim().length < 1) return;
  const results = yield apiSaga(type, apis.lookupMeta, [payload]);
  const emails = R.pathOr([], ['collection', 0, 'elementMeta', 'contacts'], results)
    .map(R.propOr('', ['email']));
  const elementName = R.pathOr('', ['collection', 0, 'elementName'], results)
  yield put({ type: 'ML_lookupMetaMatchResult', payload: { ...payload, elementName, emails } });
}

function* removeCollectionItem({ type, payload }) {
  yield apiSaga(type, apis.removeCollectionItem, [payload]);
}

export const MLSagas = [
  takeLatest('@@router/LOCATION_CHANGE', onLocChange),
  takeLatest('{ML} SaveSettings', saveSettings),
  takeLatest('{ML} SaveEntitySettings', saveEntitySettings),
  takeLatest('ML_getAllStopSaleExports', getStopSaleExports),
  takeLatest('ML_getAllOffersExports', getOfferExports),
  takeLatest('ML_getAllCollection', getAllCollection),
  takeLatest('ML_getAllCollection_rowsEntityName', debounceGetAllCollection),
  takeLatest('ML_addCollection', addCollection),
  takeLatest('ML_updateACollectionRow', updateACollectionRow),
  takeLatest('ML_deleteARowFromCollection', deleteARowFromCollection),
  takeLatest('ML_lookupMeta', lookupMeta),
  takeLatest('ML_collectionRemoveItem', removeCollectionItem),
  takeLatest(
    [
      '{ML} SaveEntitySettings_SUCCESS',
      '{ML} SaveSettings_SUCCESS',
      'ML_addCollection_SUCCESS',
      'ML_updateACollectionRow_SUCCESS',
      'ML_deleteARowFromCollection_SUCCESS',
      'ML_collectionRemoveItem_SUCCESS',
    ],
    showSuccessSnackbarAndRefreshSupplierData
  ),
  takeLatest(
    [
      '{ML} SaveEntitySettings_ERROR',
      '{ML} SaveSettings_ERROR',
      'ML_addCollection_ERROR',
      'ML_updateACollectionRow_ERROR',
      'ML_deleteARowFromCollection_ERROR',
      'ML_collectionRemoveItem_ERROR',
    ],
    showErrorSnackbar
  ),
  takeLatest('{ML} RetryStopSale', retryStopSale),
];
