
const initialState = {
  list: [],
};
// eslint-disable-next-line
export default (state = initialState, { type, payload, args }) => {
  switch (type) {
    case '@partner-management/getAllTemplates_SUCCESS':
      return {
        ...state,
        list: payload.list,
      };
    case '@partner-management/deleteATemplate_SUCCESS':
      return {
        ...state,
        list: state.list.filter(obj => args[0].id !== obj.id),
      }
    default:
      return state;
  }
};
