
import * as R from 'ramda';
import moment from 'moment-timezone';
import { takeLatest, select, put } from 'redux-saga/effects';
import { account as accountApis } from '../../../../api.js';
import { apiSaga } from '../../../../root-sagas';
import docCookie from '../../../../utils/docCookie';

function* checkIfShouldOpenSchedules({ payload }) {
  if (R.pathOr('', ['location', 'search'], payload).indexOf('showMeetingSchedules') > -1) {
    yield put({ type: 'bookingsAISignUp_openMeetingSchedules' });
  }
}

function* doSignUp({ type }) {
  const store = yield select(state => state.BookingsAISignUp);
  const payload = R.pick(['email',
  'password',
  'firstName',
  'lastName',
  'companyName',
  'dateFormat',
  'affiliates',
  'dashboardUrl',
  'bookingSystem',
  'emailClient',
  'companyId',
  'userId',
  'shouldSend3rdPartyEmail'], store);
  let referral;
  if (docCookie.getItem('rewardful.referral')) {
    try {
      const referralObj = JSON.parse(docCookie.getItem('rewardful.referral'));
      referral = referralObj.id;
    } catch (err) {
      console.log('error in parsing cookie', err);
    }
  }
  yield apiSaga(type, accountApis.signUpBookingAiUser, [{
    ...payload,
    referral,
    region: moment.tz.guess(),
  }]);
}

function* doEmailCheck({ type }) {
  const { email } = yield select(state => state.BookingsAISignUp);
  yield apiSaga(type, accountApis.decryptEmail, [{ email }]);
}

// function* signUpSuccess() {
//   const { companyId, email } = yield select(state => state.BookingsAISignUp);
//   if (companyId) {
//     yield delay(4000);
//     window.location.assign(
//       `${process.env.REACT_APP_OLD_APP_URL}/signin?auth=true&email=${email}&redirect_to=${window.location.origin}/bookings-ai`
//     );
//   }
// }

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest('@@router/LOCATION_CHANGE', checkIfShouldOpenSchedules),
  takeLatest('bookingsAISignUp_doSignUp', doSignUp),
  takeLatest('bookingsAISignUp_doEmailCheck', doEmailCheck),
  // takeLatest('bookingsAISignUp_doSignUp_SUCCESS', signUpSuccess),
];
