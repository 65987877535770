import { UserType, BillingSubscriptionType, UserDbType } from 'AllTypes';
import { accountState } from './account-reducer';

export enum cnts {
  INIT = '@app/INIT',
  INIT_SUCCESS = '@app/INIT_SUCCESS',
  GET_ACCOUNT_INFO = '@app/GET_ACCOUNT_INFO',
  GET_ACCOUNT_INFO_SUCCESS = '@app/GET_ACCOUNT_INFO_SUCCESS',
  HYDRATE_SETTINGS = '@app/HYDRATE_SETTINGS',
  HYDRATE_SETTINGS_SUCCESS = '@app/HYDRATE_SETTINGS_SUCCESS',
  GET_BILLING_INFO = '@app/GET_BILLING_INFO',
  GET_BILLING_INFO_SUCCESS = '@app/GET_BILLING_INFO_SUCCESS',
}

export type ThisActionType =
  | {
      type: typeof cnts.GET_ACCOUNT_INFO_SUCCESS;
      payload: accountState;
    }
  | {
      type: typeof cnts.HYDRATE_SETTINGS_SUCCESS;
      payload: {
        allActiveUsers: UserType[];
        usersArray: UserDbType[];
        secondaryEmails: {
          email: string;
          is_confirmed: string;
        }[];
        currentUserRole:
          | 'supplier admin'
          | 'supplier user'
          | 'contractor admin'
          | 'contractor user';
        currentUserEmail: string;
      };
    }
    | {
        type: typeof cnts.GET_BILLING_INFO_SUCCESS;
        payload: {
          billingSubscriptions : BillingSubscriptionType[],
          bookingFreeTrialSub: BillingSubscriptionType,
          emailAssistFreeTrialSub: BillingSubscriptionType,
        };
      };

export const init = () => ({ type: cnts.INIT });

export const logout = () => {
  localStorage.removeItem('jwt');
  window.location.assign(
    `${process.env.REACT_APP_OLD_APP_URL}/signin?logout=true`
  );
};
