import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers-pro';
import moment from 'moment';
import { dateFormatPicker, utcToLocal, localToUtc } from '../../utils/date.js';

const DatePicker = props => {
  const { id, label, color, value, onChange } = props;
  const [date, setDate] = React.useState();
  React.useEffect(() => {
    if (value && moment(value).isValid() && !date) {
      setDate(utcToLocal(value));
    }
  }, [value, date]);
  const localOnChange = updatedValue => {
    setDate(updatedValue);
    if (moment(updatedValue).isValid()) {
      onChange(localToUtc(updatedValue));
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        disableToolbar
        variant="inline"
        format={dateFormatPicker()}
        id={id || 'date-picker'}
        label={label}
        autoOk
        color={color || 'secondary'}
        value={date || null}
        onChange={localOnChange}
        slotProps={{ textField: { size: 'small' } }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
