import { select, takeLatest } from 'redux-saga/effects';
import { matchPath } from 'react-router';
import * as R from 'ramda';
import {
  admin as adminApi,
} from '../../../api';
import { apiSaga } from '../../../root-sagas';

const getCookie = cname => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}


const getToken = state => state.admin.token;

const checkToken = function*({ type }) {
  const token = yield select(getToken);
  yield apiSaga(type, adminApi.checkToken, [{ token: token || getCookie('adminJwt') }]);
}

const triggerSearch = function*({ type, payload }) {
  const searchString = R.path(
    ['params', 'searchString'],
    matchPath(R.path(['location', 'pathname'], payload), {
      path: '/admin/search/:searchString',
      exact: true,
      strict: true
    }));
  if (searchString) {
    const token = yield select(getToken);
    yield apiSaga('{admin} search', adminApi.search, [{
      token: token || getCookie('adminJwt'),
      searchString,
    }]);
  }
}

const triggerGetCompany = function*({ type, payload, }) {
  // /admin/company/:companyId
  const companyId = R.path(
    ['params', 'companyId'],
    matchPath(R.path(['location', 'pathname'], payload), {
      path: '/admin/logs/companyId/:companyId',
      exact: true,
      strict: true
    }));
  if (companyId) {
    const token = (yield select(getToken)) || getCookie('adminJwt');
    yield apiSaga('{admin} getCompany', adminApi.getCompany, [{
      token,
      companyId,
    }]);
  }
}

const getLogQueryParams = state => state.admin.logQueryParams;

const triggerGetLogs = function*({ payload }) {
  // /admin/company/:companyId
  const { id, type } = R.pathOr(
    {},
    ['params'],
    matchPath(R.pathOr(
      window.location.pathname,
      ['location', 'pathname'], payload), {
        path: '/admin/logs/:type/:id',
        exact: true,
        strict: true
      }));
  if (type && id) {
    const token = (yield select(getToken)) || getCookie('adminJwt');
    const logQueryParams = (yield select(getLogQueryParams));
    yield apiSaga('{admin} getLogs', adminApi.getLogs, [{
      token,
      type,
      id,
      queryParams: {
        dateTime: logQueryParams.dateTime.unix(),
        dateTimeStart: logQueryParams.dateTimeStart.unix(),
      },
    }]);
  }
}

const sagas = [
  takeLatest('{admin} tokenCheck', checkToken),
  takeLatest('@@router/LOCATION_CHANGE', triggerSearch),
  takeLatest('@@router/LOCATION_CHANGE', triggerGetCompany),
  takeLatest('@@router/LOCATION_CHANGE', triggerGetLogs),
  takeLatest('{admin} getLogs', triggerGetLogs),
];

export default sagas;
