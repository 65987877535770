import { combineReducers } from 'redux';
import addPartners from './add-partners-reducer';
import partnerList from './partner-list-reducer';
import sendAssets from './send-assets-reducer';
import partnerDetails from '../partner-details/__store__/reducer';
import contactDetails from '../contact-details/__store__/reducer';
import associatePartner from '../contact-details/__store__/associate-partner-reducer';
import bounceList from '../bounce-list/__store__/reducer';
import messageTemplates from '../message-templates-signature/__store__/reducer';
import settings from '../settings/__store__/reducer';
import { pmCnts } from './pm-actions';
import { ThisActionType } from './pm-store-types';
import {
  ContactType,
  ImportExportFieldsMap,
  PMFieldType,
  PMTagType,
  poliPayload
} from 'AllTypes';

type StateType = {
  allTags: PMTagType[];
  allPartnerFields: PMFieldType[];
  allContactFields: PMFieldType[];
  importExportFieldsMap: ImportExportFieldsMap;
  extraMatchesFields: { [key: string]: string };
};

const initialState = {
  allTags: [],
  allPartnerFields: [],
  allContactFields: [],
  importExportFieldsMap: {},
  extraMatchesFields: {}
};

function metaData(state: StateType = initialState, action: ThisActionType) {
  switch (action.type) {
    case pmCnts.GET_TAGS_SUCCESS:
      return {
        ...state,
        allTags: action.payload
      };
    case pmCnts.GET_FIELDS_SUCCESS:
      return {
        ...state,
        allPartnerFields: action.payload.allPartnerFields,
        allContactFields: action.payload.allContactFields,
        importExportFieldsMap: action.payload.importExportFieldsMap,
        extraMatchesFields: action.payload.extraMatchesFields
      };
    default:
      return state;
  }
}
type EditDialogState = {
  openDialog: 'editTags' | 'archivePartners' | string | undefined;
  items: poliPayload[] | [];
  data: {
    existingTags?: PMTagType[] | [];
    addOnly?: boolean;
    existingContacts?: ContactType[] | [];
  };
  payload: any;
  showLoader: boolean;
  error: boolean;
  errorText: string | undefined;
};

const initialEditDialog = {
  openDialog: undefined,
  items: [],
  data: {
    existingTags: [],
    addOnly: false,
    existingContacts: []
  },
  payload: {},
  error: false,
  showLoader: false,
  errorText: ''
};

function editDialog(
  state: EditDialogState = initialEditDialog,
  action: ThisActionType
) {
  switch (action.type) {
    case pmCnts.EXPORT_CSV:
      return {
        ...state,
        items: action.payload.items
      };
    case pmCnts.OPEN_EDIT_DIALOG:
      return {
        ...state,
        openDialog: action.payload.openDialog,
        items: action.payload.items,
        data: {
          ...state.data,
          ...action.payload.data
        }
      };
    case pmCnts.MERGE_PARTNERS_STARTED:
    case pmCnts.ARCHIVE_CONTACTS_STARTED:
    case pmCnts.ARCHIVE_PARTNERS_STARTED:
    case pmCnts.SAVE_CONTACTS_FOR_PARTNER_STARTED:
    case pmCnts.SAVE_TAG_CHANGES_STARTED:
      return {
        ...state,
        showLoader: true
      };
    case pmCnts.MERGE_PARTNERS:
    case pmCnts.ARCHIVE_PARTNERS:
    case pmCnts.SAVE_CONTACTS_FOR_PARTNER:
    case pmCnts.SAVE_TAG_CHANGES:
      return {
        ...state,
        payload: action.payload
      };
    case pmCnts.MERGE_PARTNERS_ERROR:
    case pmCnts.ARCHIVE_CONTACTS_ERROR:
    case pmCnts.ARCHIVE_PARTNERS_ERROR:
    case pmCnts.SAVE_CONTACTS_FOR_PARTNER_ERROR:
    case pmCnts.SAVE_TAG_CHANGES_ERROR:
      return {
        ...state,
        showLoader: false,
        error: true,
        errorText: action.payload
      };
    case pmCnts.MERGE_PARTNERS_SUCCESS:
    case pmCnts.ARCHIVE_PARTNERS_SUCCESS:
    case pmCnts.ARCHIVE_CONTACTS_SUCCESS:
    case pmCnts.SAVE_CONTACTS_FOR_PARTNER_SUCCESS:
    case pmCnts.SAVE_TAG_CHANGES_SUCCESS:
    case pmCnts.CLOSE_EDIT_DIALOG:
      return {
        ...state,
        showLoader: false,
        openDialog: undefined
      };
    default:
      return state;
  }
}

export default combineReducers({
  partnerList,
  metaData,
  addPartners,
  partnerDetails,
  contactDetails,
  associatePartner,
  bounceList,
  editDialog,
  sendAssets,
  messageTemplates,
  settings,
});
