import React from 'react';
import * as R from 'ramda';
import { Grid, Button } from '@mui/material';
import RadioButton from '../../../style-guide/radio-buttons/radio-button';
import TextField from '../../../style-guide/text-field';

const OptionsForText = ({
  constraintType,
  constraintValue,
  onChangeType,
  onChangeValue,
  addFilter,
}) => {
  const constraints = [
    { type: 'is exactly', tf: true },
    { type: 'contains', tf: true },
    { type: 'does not contain', tf: true },
    { type: 'is known' },
    { type: 'is unknown' }
  ];
  const onChangeRadio = t => () => {
    onChangeType(t);
    if (t.indexOf('known') > -1) onChangeValue({ string: undefined });
  };
  const onChangeTxt = e => onChangeValue({ string: e.target.value });
  return <Grid sx={{ marginTop: '8px' }} container direction="column">
    {constraints.map(obj => (
      <Grid item key={obj.type} container alignItems="center" sx={{ marginLeft: '8px' }}>
        <RadioButton
          label={obj.type || ''}
          color="secondary"
          checked={constraintType === obj.type}
          onChange={onChangeRadio(obj.type)}
        />
        {obj.tf && constraintType === obj.type && (
          <TextField
            label="text"
            sx={{ maxWidth: '200px' }}
            value={constraintValue.string}
            onChange={onChangeTxt}
            helperText="Required *"
          />
        )}
      </Grid>
    ))}
    <Grid item sx={{ marginTop: '8px' }}>
      <Button
        disabled={
          !constraintType ||
          ((constraintType === 'is exactly' ||
            constraintType === 'contains' ||
            constraintType === 'does not contain') &&
            R.isEmpty(constraintValue))
        }
        variant="contained"
        color="secondary"
        size="small"
        onClick={addFilter}
      >
        add filter
      </Button>
    </Grid>
  </Grid>;
};

export default OptionsForText;
