
const initialState = {
  open: false,
  showLoader: false,
  error: '',
  workspaces: [],
};
// eslint-disable-next-line
export default (state = initialState, { type, payload, args }) => {
  switch (type) {
    case '@contracting_workspace_merge/open':
      return {
        ...state,
        open: true,
        ...payload,
      };
    case '@contracting_workspace/mergeWorkspaces_SUCCESS':
    case '@contracting_workspace_merge/close':
      return initialState;
    default:
      return state;
  }
};
