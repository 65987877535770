import * as R from 'ramda';
export const initialState = {
  showSnackbar: false,
  snackbarMessage: '',
  snackBarSeverity: 'success', // info, warning, error
  waitingCount: 0,
  backDropOpen: false,
  backDropLoading: false,
  backDropMessages: [],
};

const shouldSkipLoader = action => {
  return ['[sidebarHelp]', '@notifications/'].some(str => action.type.indexOf(str) > -1)
    || R.path(['args', 0, 'skipLoader'], action)
    || R.path(['args', 1, 'skipLoader'], action);
};
export function globalUI(state = initialState, action) {
  if (action.type.indexOf('_STARTED') > -1 && !shouldSkipLoader(action)) {
    return {
      ...state,
      waitingCount: state.waitingCount + 1,
      backDropOpen: true,
      backDropLoading: true,
    };
  } else if (action.type.indexOf('_SUCCESS') > -1 && !shouldSkipLoader(action)) {
    return {
      ...state,
      backDropMessages: state.waitingCount - 1 > 0 || Boolean(state.backDropMessages.length) ? state.backDropMessages : [],
      waitingCount: state.waitingCount - 1,
      backDropOpen: state.waitingCount - 1 > 0 || Boolean(state.backDropMessages.length),
      backDropLoading: state.waitingCount - 1 > 0,
    };
  } else if (action.type.indexOf('_ERROR') > -1 && !shouldSkipLoader(action)) {
    return {
      ...state,
      backDropOpen: true,
      waitingCount: state.waitingCount - 1,
      backDropLoading: state.waitingCount - 1 > 0,
      backDropMessages: [state.backDropMessages, `${action.type}: ${action.payload}`],
    };
  }
  switch (action.type) {
    case 'global_openSnackBar':
      return {
        ...state,
        showSnackbar: true,
        ...action.payload
      };
    case 'global_dismissSnackbar':
    case 'global_dismissLoadingBackDrop':
      return initialState;
    default:
      return state;
  }
}
