
const initialState = {
  list: [],
};
// eslint-disable-next-line
export default (state = initialState, { type, payload, args }) => {
  switch (type) {
    case '@partner-management/getBounceList_SUCCESS':
      return {
        ...state,
        list: payload,
      };
    case '@partner-management/markDoneFromBounceList_SUCCESS':
      return {
        ...state,
        list: state.list.map(obj => ({
          ...obj,
          status: args[0].contactIds.indexOf(obj.contactId) === -1 ? obj.status : args[0].status,
        })),
      }
    default:
      return state;
  }
};
