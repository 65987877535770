import { cyan, pink } from '@mui/material/colors';

export const defaultTheme = {
  palette: {
    primary: {
      main: pink['A200'],
      contrastText: '#fff'
    },
    secondary: {
      main: cyan[500],
      contrastText: '#fff'
    },
    common: {
      white: '#fff'
    },
    error: {
      main: '#ff4081'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.67)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.48)',
      hint: 'rgba(0, 0, 0, 0.48)'
    }
  },
  typography: {
    htmlFontSize: 14,
    fontSize: 12,
    fontFamily: "'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif",
    h5: {
      fontSize: '1.35rem'
    },
    h6: {
      fontSize: '1rem'
    },
    caption: {
      fontSize: '0.65rem'
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: '0.9rem'
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: '0.75rem'
    },
    button: {
      fontSize: '0.8rem'
    }
  }
};

export const partner = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: pink['A200'],
      contrastText: '#fff'
    },
    secondary: {
      light: '#88b9dd',
      main: '#366f98',
      contrastText: '#fff'
    }
  }
};
