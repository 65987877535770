import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore, { history } from './configureStore';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as queryString from 'query-string';
import { ConnectedRouter } from 'connected-react-router';
import ALApp from './components/AssetsLanding/App';
import SignUp from './components/SignUp/App';
import Admin from './components/admin/app';

import { LicenseInfo } from '@mui/x-license-pro';
const REACT_APP_MUI_LICENSE = process.env.REACT_APP_MUI_LICENSE || '';
LicenseInfo.setLicenseKey(REACT_APP_MUI_LICENSE);

(() => {
  // sign up
  if (window.location.href.indexOf('sign-up') > -1) {
    const store = configureStore();
    ReactDOM.render(
      <Provider store={store}>
      <ConnectedRouter history={history}>
      <SignUp />
      </ConnectedRouter>
      </Provider>,
      document.getElementById('root')
    );
    return;
  }
  // asset landing
  if (window.location.href.indexOf('/assets-landing') > -1) {
    const store = configureStore();
    ReactDOM.render(
      <Provider store={store}>
      <ConnectedRouter history={history}>
      <ALApp />
      </ConnectedRouter>
      </Provider>,
      document.getElementById('root')
    );
    return;
  }
  // admin
  const url = new URL(window.location.href)
  if (url.pathname.indexOf('/admin') === 0) {
    const store = configureStore();
    ReactDOM.render(
      <Provider store={store}>
      <ConnectedRouter history={history}>
      <Admin />
      </ConnectedRouter>
      </Provider>,
      document.getElementById('root')
    );
    return;
  }

  // regular app
  const REACT_APP_OLD_APP_URL = process.env.REACT_APP_OLD_APP_URL;
  const jwtInStorage = localStorage.getItem('jwt');
  const params = {
    ...queryString.parse(window.location.search)
  };
  const logout = window.location.search && params.logout;
  const jwtInUrl = window.location.search && params.jwt;
  if (logout) {
    localStorage.removeItem('jwt');
    window.location.assign(`${REACT_APP_OLD_APP_URL}/signin`);
  } else if (!jwtInStorage && !jwtInUrl) {
    window.location.assign(
      `${REACT_APP_OLD_APP_URL}/signin?auth=true&redirect_to=${window.location.href}`
    );
  } else {
    if (jwtInUrl) {
      localStorage.setItem('jwt', jwtInUrl + '');
      delete params.jwt;
      window.history.replaceState(
        {},
        '',
        `${window.location.protocol}//${window.location.host}${
          window.location.pathname
        }${params ? '?' : ''}${queryString.stringify(params)}`
      );
    }
    const store = configureStore();
    ReactDOM.render(
      <Provider store={store}>
      <ConnectedRouter history={history}>
      <App />
      </ConnectedRouter>
      </Provider>,
      document.getElementById('root')
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  }
})();
