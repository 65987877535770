import * as R from 'ramda';


const initialState = {
  systemParsers: [],
  userParsers: [],
  userParsersBeforeSave: [],
  // forwardHistory: [],
  // backHistory: [],
};

export default function extractionSettings(state = initialState, { type, payload, args }) {
  switch(type) {
    case 'genAI_extractionSettings_getLLMParserInJson':
      if (payload) {
        return {
          ...state,
          ...payload,
        };
      }
      return state;
    case 'genAI_extractionSettings_getLLMParserInJson_SUCCESS':
      return {
        ...state,
        ...payload,
        userParsersBeforeSave: payload.userParsers,
      };
    case 'genAI_extractionSettings_updateParserDescr': {
      const joinedUpdaterPath = payload.updater.path.join('.');
      return {
        ...state,
        userParsers: R.assocPath([payload.parserIndex, 'parserInJson'], payload.parserInJson, state.userParsers),
        forwardHistory: [],
        updaters: R.assocPath(
          [payload.parserIndex, joinedUpdaterPath],
          {
            backHistory: [
              ...R.pathOr([], [payload.parserIndex, joinedUpdaterPath, 'backHistory'], state.updaters),
              R.path([payload.parserIndex, 'parserInJson', ...payload.updater.path, 'description'], state.userParsers)
            ],
            forwardHistory: [],
          },
          state.updaters),
      };
    }
    case 'genAI_extractionSettings_updateRuleContent':
      return {
        ...state,
        userParsers: R.assocPath([payload.parserIndex, 'rule'], payload.rule, state.userParsers),
      };
    case 'genAI_extractionSettings_saveParsersAndRule_SUCCESS':
      return {
        ...state,
        userParsersBeforeSave: state.userParsers,
        // backHistory: [...state.backHistory, state.userParsers],
      };
    // case 'genAI_extractionSettings_undoParser':
    //   return {
    //     ...state,
    //     userParsers: R.last(state.backHistory),
    //     backHistory: R.init(state.backHistory),
    //     forwardHistory: [...state.forwardHistory, state.userParsers],
    //   };
    // case 'genAI_extractionSettings_redoParser':
    //   return {
    //     ...state,
    //     userParsers: R.last(state.forwardHistory),
    //     forwardHistory: R.init(state.forwardHistory),
    //     backHistory: [...state.backHistory, state.userParsers],
    //   };
    case 'genAI_extractionSettings_copyToPartner_SUCCESS':
      return {
        ...state,
        severity: 'success',
        text: "Copied to partner successfully",
      }
    case 'genAI_extractionSettings_updateProps':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
