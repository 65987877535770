import {
  takeLatest
} from 'redux-saga/effects';
import { apiSaga } from '../../../../root-sagas';
import { partnerManagement as pmApis } from '../../../../api';

function* doCreateNewProperty({ type, payload }) {
  yield* apiSaga(type, pmApis.createNewProperty, [payload]);
}

function* refreshPropertyList() {
  yield apiSaga('@PM/GET_FIELDS', pmApis.getFields, []);
}

function* doUpdateProperty({ type, payload }) {
  yield apiSaga(type, pmApis.updateProperty, [payload]);
}

function* doArchiveProperty({ type, payload }) {
  yield apiSaga(type, pmApis.archiveProperty, [payload]);
}
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest('@PMsettings-createNewProperty/onClickCreate', doCreateNewProperty),
  takeLatest('@PMsettings-createNewProperty/onClickCreate_SUCCESS', refreshPropertyList),
  takeLatest('@PMsettings/updateProperty_SUCCESS', refreshPropertyList),
  takeLatest('@PMsettings/archiveProperty_SUCCESS', refreshPropertyList),
  takeLatest('@PMsettings/updateProperty', doUpdateProperty),
  takeLatest('@PMsettings/archiveProperty', doArchiveProperty),
];
