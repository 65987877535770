import { addPartnersCnts } from './pm-actions';
import { ThisActionType } from './pm-store-types';
import { CreatePartnerPayload } from 'AllTypes';
import { combineReducers } from 'redux';

type ManualStateType = {
  drawerOpen: boolean;
  creatingOnePartner: boolean;
  createAnother: boolean;
  onePartner: CreatePartnerPayload | undefined;
  clearState: boolean;
};

const initialManualState = {
  drawerOpen: false,
  creatingOnePartner: false,
  createAnother: false,
  clearState: false,
  onePartner: undefined
};

function manually(
  state: ManualStateType = initialManualState,
  action: ThisActionType
) {
  switch (action.type) {
    case addPartnersCnts.OPEN_ADD_ONE_DRAWER:
      return {
        ...state,
        drawerOpen: true
      };
    case addPartnersCnts.CLOSE_ADD_ONE_DRAWER:
      return initialManualState;
    case addPartnersCnts.CREATE_ONE_PARTNER:
      return {
        ...state,
        onePartner: action.payload,
        createAnother: action.another
      };
    case addPartnersCnts.CREATE_ONE_PARTNER_STARTED:
      return {
        ...state,
        creatingOnePartner: true,
        // have to reset this field at START
        // because React.useState is using this field
        // to listen to the success
        clearState: false
      };
    case addPartnersCnts.CREATE_ONE_PARTNER_SUCCESS:
      return {
        ...state,
        drawerOpen: state.createAnother,
        creatingOnePartner: false,
        clearState: state.createAnother,
        onePartner: undefined
      };
    case addPartnersCnts.CREATE_ONE_PARTNER_ERROR:
      return {
        ...state,
        creatingOnePartner: false,
        error: action.payload
      };
    default:
      return state;
  }
}

type ImportStateType = {
  error: string | undefined;
  importing: boolean;
  erroredRows:
    | {
        rawData: String[];
        rowIndex: number;
      }[]
    | [];
  successRowsCount?: number;
  success: boolean;
};

const initialImportState = {
  error: undefined,
  importing: false,
  erroredRows: [],
  success: false
};

function importReducer(
  state: ImportStateType = initialImportState,
  action: ThisActionType
) {
  switch (action.type) {
    case addPartnersCnts.IMPORT_FILE:
      return {
        ...state,
        importing: true
      };
    case addPartnersCnts.IMPORT_FILE_SUCCESS:
      return {
        ...state,
        erroredRows: action.payload.erroredRows,
        successRowsCount: action.payload.successRowsCount,
        success: true,
        importing: false
      };
    case addPartnersCnts.IMPORT_FILE_ERROR:
      return {
        ...state,
        error: action.payload,
        importing: false
      };
    case addPartnersCnts.GO_BACK_FROM_IMPORT:
      return initialImportState;
    default:
      return state;
  }
}

export default combineReducers({
  manually,
  import: importReducer
});
