import * as R from 'ramda';
import moment from 'moment';

import { logout } from './../../../root-actions';

const initialState = {
  token: null,
  tokenLastUpdate: 0,
  tokenCheckLoading: false,
  tokenValid: false,
  searching: false,
  searchResults: {},
  currentCompany: {},
  currentCompanyLoading: false,
  logsLoading: false,
  logs: {},
  logQueryParams: {
    dateTime: moment(),
    dateTimeStart: moment().subtract(8, 'hours'),
  },
};

function setCrossSubdomainCookie(name, value, days) {
  const assign = name + '=' + escape(value) + ';';
  const d = new Date();

  d.setTime(d.getTime() + (days*24*60*60*1000));
  const expires = 'expires='+ d.toUTCString() + ';';
  const path = 'path=/;';
  const domain = 'domain=' + (document.domain.match(/[^.]*\.[^.]*$/)[0]) + ';';
  document.cookie = assign + expires + path + domain;
}


const reducer = (state = initialState, { type, payload }) => {
  return {
    ...state,
    ...(() => {
      switch (type) {
        case '{admin} tokenCheck_STARTED':
          return { tokenCheckLoading: true };
        case '{admin} tokenCheck_ERROR':
          logout();
          return { tokenValid: false, tokenChecking: false };
        case '{admin} tokenCheck_SUCCESS':
          setCrossSubdomainCookie('adminJwt', payload.adminJwt, 1);
          return {
            tokenValid: true,
            tokenChecking: false,
            tokenLastUpdate: ~~((new Date()).getTime() / 1e3),
            token: payload.adminJwt,
          };
        case '{admin} search_STARTED':
          return { searching: true };
        case '{admin} search_SUCCESS':
          return { searching: false, searchResults: R.clone(payload) };
        case '{admin} search_ERROR':
          return { searching: false, searchResults: {} };
        case '{admin} getCompany_STARTED':
          return { currentCompanyLoading: true };
        case '{admin} getCompany_SUCCESS':
          return { currentCompanyLoading: false, currentCompany: R.clone(payload) };
        case '{admin} getCompany_ERROR':
          return { currentCompanyLoading: false, currentCompany: {} };
        case '{admin} getLogs_STARTED':
          return { logsLoading: true };
        case '{admin} getLogs_SUCCESS':
          return { logsLoading: false, logs: R.clone(payload) };
        case '{admin} getLogs_ERROR':
          return { logsLoading: false, logs: initialState.logs };
        case '{admin} updateLogQueryParams':
          return { logQueryParams: R.mergeRight(state.logQueryParams, payload) };
        default:
          return {};
      }
    })(),
  };
};

export default reducer;
