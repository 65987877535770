/* eslint-disable import/no-anonymous-default-export */

import moment from 'moment';
import * as R from 'ramda';
import { takeEvery, takeLatest } from 'redux-saga/effects';

// const sendIntercom = (...params) => {
//   if (window.Intercom) {
//     window.Intercom(...params); // eslint-disable-line new-cap
//   }
// };
const sendIntercom = (...params) => {
  if (window.Intercom) {
    window.Intercom(...params); // eslint-disable-line new-cap
  }
};
function sendUserDataToTag({ payload }) {
  const {
    id: userId,
    user: { name, email, createdAt },
    isMarketingReady,
    company: {
      companyId,
      companyName,
      companyType,
      plan,
      productType,
      websiteUrl,
      phone,
      acctCreationReason,
      createdAt: companyCreatedAt
    },
    bookingSystem,
    emailClient,
    affiliateURL,
  } = payload;
  const pushVal = {
    event: 'GET_USER',
    user: {
      id: userId,
      name: name || '',
      email,
      createdAt: new Date(createdAt).getTime() / 1000
    },
    company: {
      id: companyId,
      createdAt: new Date(companyCreatedAt).getTime() / 1000,
      accountAge: moment().diff(companyCreatedAt, 'days'),
      creationReason: acctCreationReason,
      type: companyType,
      plan,
      name: companyName,
      website: websiteUrl,
      phone,
      isMarketingReady,
      productType,
      bookingSystem,
      emailClient,
      affiliateURL,
    }
  };
  window.dataLayer.push(pushVal);
}
function trackEvent({ payload }) {
  sendIntercom('trackEvent', payload.name, {
    // eslint-disable-line new-cap
    ...payload.values
  });
  if (payload.ga) {
    const {
      ga: { eventCategory, eventValue, eventAction, eventLabel }
    } = payload;
    window.dataLayer.push({
      event: 'TRACK_EVENT',
      ev: {
        category: eventCategory,
        action: eventAction,
        label: eventLabel,
        value: eventValue
      }
    });
  }
}
const sendUserDataToIntercom = ({ payload }) => {
  const {
    id: userId,
    user: { name, email },
    company: {
      companyId,
      companyName,
      companyType,
      plan,
      websiteUrl,
      phone,
      acctCreationReason
    },
    bookingSystem,
    emailClient,
    affiliateURL,
  } = payload;
  setTimeout(() => {
    sendIntercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP,
      user_id: userId,
      name: name || '',
      email,
      company: {
        id: companyId,
        creation_reason: acctCreationReason,
        plan,
        company_type: companyType,
        name: companyName,
        website: websiteUrl,
        phone,
        booking_system: bookingSystem,
        email_platform: emailClient,
        affiliateURL,
      }
    });
  }, 3e3);
};


const trackEventByType = ({ type }) => {
  if (window.Intercom) {
    window.Intercom('trackEvent', type)
  }
};

const sendAffliateLinkToIntercom = ({ payload }) => {
  sendIntercom('update', {
    affiliateURL: R.pathOr('', ['links', 0, 'url'], payload),
  });
  if (window.Intercom) {
    window.Intercom('trackEvent', 'joinAffiliateProgram_SUCCESS', {
      affiliateURL: R.pathOr('', ['links', 0, 'url'], payload),
    });
  }
};

const afterSignUpIntercom = ({ payload, args, type }) => {
  const signUpBody = args[0] || {};
  console.log({
    app_id: process.env.REACT_APP_INTERCOM_APP,
    user_id: payload.userId,
    name: `${signUpBody.firstName ||''} ${signUpBody.lastName || ''}`.trim(),
    email: signUpBody.email,
    company: {
      id: payload.companyId,
      name: signUpBody.companyName,
      booking_system: signUpBody.bookingSystem,
      email_platform: signUpBody.emailClient,
    }
  });
  sendIntercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_APP,
    user_id: payload.userId,
    name: `${signUpBody.firstName ||''} ${signUpBody.lastName || ''}`.trim(),
    email: signUpBody.email,
    company: {
      id: payload.companyId,
      name: signUpBody.companyName,
      booking_system: signUpBody.bookingSystem,
      email_platform: signUpBody.emailClient,
    }
  });
  setTimeout(() => {
    sendIntercom('trackEvent', type);
  }, 1000);
};
export default [
  takeLatest('@app/GET_ACCOUNT_INFO_SUCCESS', sendUserDataToTag),
  takeLatest('@app/GET_ACCOUNT_INFO_SUCCESS', sendUserDataToIntercom),
  takeEvery('TRACK_EVENT', trackEvent),
  takeLatest('joinAffiliateProgram_SUCCESS', sendAffliateLinkToIntercom),
  takeLatest([
    'bookingsAISignUp_doSignUp_SUCCESS',
    'generativeAiSignUp_doSignUp_SUCCESS',
  ], afterSignUpIntercom),
  takeLatest([
    'bookingsAI_Upgrade_SUCCESS',
    'bookingsAISignUp_openMeetingSchedules',
    'generativeAiSignUp_openMeetingSchedules',
  ], trackEventByType),
];
