import React from 'react';
import { Grid, Button, ToggleButtonGroup, ToggleButton } from '@mui/material';
import RadioButton from '../../../style-guide/radio-buttons/radio-button';

const OptionsForYesNo = ({
  constraintType,
  constraintValue,
  onChangeType,
  onChangeValue,
  addFilter,
}) => {
  const constraints = [
    { type: 'is equal to' },
    { type: 'is known' },
    { type: 'is unknown' }
  ];
  const onChangeRadio = t => () => {
    onChangeType(t);
    if (t.indexOf('known') > -1) onChangeValue({ boolean: undefined });
  };
  const onChangeBtn = (_, val) => onChangeValue({ boolean: val });
  return <Grid sx={{ marginTop: '8px' }} container direction="column">
    {constraints.map(obj => (
      <Grid item key={obj.type} container alignItems="center" sx={{ marginLeft: '8px' }}>
        <RadioButton
          label={obj.type || ''}
          color="secondary"
          checked={constraintType === obj.type}
          onChange={onChangeRadio(obj.type)}
        />
        {constraintType === obj.type && obj.type === 'is equal to' && (
          <ToggleButtonGroup exclusive value={Boolean(constraintValue.boolean)} color="secondary" onChange={onChangeBtn} size="small">
            <ToggleButton value={true}>Yes</ToggleButton>
            <ToggleButton value={false}>No</ToggleButton>
          </ToggleButtonGroup>
        )}
      </Grid>
    ))}
    <Grid item sx={{ marginTop: '8px' }}>
      <Button
        disabled={!constraintType}
        variant="contained"
        color="secondary"
        size="small"
        onClick={addFilter}
      >
        add filter
      </Button>
    </Grid>
  </Grid>;
};

export default OptionsForYesNo;
