import { applyMiddleware, createStore, compose } from 'redux';
import createReducer from './root-reducers';
import createSagaMiddleware from '@redux-saga/core';
import { rootSaga } from './root-sagas';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import AccessCheck from './components/walls/access-check-middleware';

export const history = createBrowserHistory();
export default function configureStore(populateState = {}) {
  const composeEnhancers =
    process.env.NODE_ENV === 'production'
      ? compose
      : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    createReducer(history),
    populateState,
    composeEnhancers(
      applyMiddleware(routerMiddleware(history), sagaMiddleware, AccessCheck)
    )
  );
  sagaMiddleware.run(rootSaga);
  return store;
}
