const prefix = '@contact-details/';

export const CHANGE_PARTNER = `${prefix}CHANGE_PARTNER`;

// activities
export const GET_ACTIVITY = `${prefix}GET_ACTIVITY`;

// files
export const GET_FILES = `${prefix}GET_FILES`;

// PartnerInfo
export const GET_CONTACT_INFO = `${prefix}GET_CONTACT_INFO`;

export const CLEAR_STORE = `${prefix}CLEAR_STORE`;

export const CONVERSATIONS_READ = `${prefix}CONVERSATIONS_READ`;

export const UPDATE_PROPS = `${prefix}UPDATE_PROPS`;
export const RESET_TEMPLATE_SELECTION = `${prefix}RESET_TEMPLATE_SELECTION`;
export const RESET_LOCATION_SELECTION = `${prefix}RESET_LOCATION_SELECTION`;
export const POPULATE_REDUCER = `${prefix}POPULATE_REDUCER`;

export const UPDATE_CONTACT_DETAILS = `${prefix}UPDATE_CONTACT_DETAILS`;

export const UNASSOC_A_MESSAGE = `${prefix}UNASSOC_A_MESSAGE`;
