import * as R from 'ramda';
import {
  takeLatest,
  select,
  put,
  spawn,
} from 'redux-saga/effects';
import {
  ti2 as ti2Apis,
  aiRules as aiRulesApis,
  generativeAi as genaiApis,
} from '../../../api.js';
import { apiSaga } from '../../../root-sagas.js';
import { _getAccountInfo } from './partner-settings/edit-app-key-configuration-sagas.js';
import { fetchAsyncJob as fetchAsyncJobImport } from '../../../utils/fetchAsyncJob.js';

function* onLocationChange({ payload }) {
  const pathname = R.pathOr('', ['location', 'pathname'], payload);
  if (pathname.includes('generative-ai/integration-settings/rules')) {
    yield put({ type: '@partner-list/GET_PARTNERS' })
    yield apiSaga('genAi_aiRules_loadAllRules', aiRulesApis.getAIRules, [{}]);
    const { companyId, ti2token } = yield _getAccountInfo();
    yield apiSaga('genAi_aiRules_loadAllAppKeys', ti2Apis.getAllAppKeys, [{ companyId, ti2token }])
  }
}

function* deleteRules({ payload }) {
  for (const ruleId of payload.ruleIds) {
    yield apiSaga('genAi_aiRules_deleteOneRule', aiRulesApis.deleteAIRule, [ruleId, { skipLoader: true }])
  }
}

function* doAssignPartners() {
  const { selectedPartnersToAdd, selectedPartnersToRemove, rules, appliedToAllPartners } = yield select(state => state.generativeAi.aiRules.assignPartners);
  for (const rule of rules) {
    yield apiSaga('genAi_aiRules_assignPartners_forOneRule', aiRulesApis.updateRule, [{
      id: rule.id,
      scope: appliedToAllPartners ? 'any' : null,
      partnerIds: appliedToAllPartners ? [] : R.uniq([
        ...selectedPartnersToAdd.map(p => p.id),
        // HAVE to include the existing partners
        ...rule.Partners
          .filter(p => selectedPartnersToRemove.every(spr => spr.id !== p.id))
          .map(p => p.id),
      ]),
    }, {
      skipLoader: true,
    }])
  }
  const { rules: updatedRules } = yield select(state => state.generativeAi.aiRules.assignPartners);
  if (updatedRules.every(o => o.success)) {
    yield put({ type: 'genAi_aiRules_assignPartners_assign_SUCCESS' })
  }
}

function* _doSaveRule() {
  const { id, matcher, body, content, ruleType } = yield select(state => state.generativeAi.aiRules.editRule);
  yield apiSaga('genAi_aiRules_editRule_save', aiRulesApis.updateRule, [{
    id,
    content: ruleType === 'product' && body.options
      ? `If the service name contains the text: ${matcher}, please add ${
      body.options.length === 1
      ? ''
      : `ALL ${['', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX'][body.options.length]} of `
    }the following service line${body.options.length === 1 ? '' : 's'} to the result: ${JSON.stringify(body.options)}` : content,
    matcher,
    body,
    ruleType,
  }]);
}

function* shouldSaveRuleOrNot({ payload }) {
  const { id } = yield select(state => state.generativeAi.aiRules.editRule);
  const hasConflict = payload.some(job => R.path(['result', 'wining_rule', 'ruleId'], job) !== id);
  if (hasConflict) return;
  yield _doSaveRule();
}


function* doProductRuleEvalInEditRule() {
  const { matcher, ruleType, rulePartners } = yield select(state => state.generativeAi.aiRules.editRule);
  if (ruleType !== 'product') {
    yield _doSaveRule();
    return;
  }
  const allJobs = yield _getMatcherEvalJobs({
    actionType: 'genAi_aiRules_editRule_productRuleEval',
    Partners: rulePartners,
    matcher,
  });
  yield spawn(fetchAsyncJobImport('genAi_aiRules_editRule_productRuleEval', genaiApis.checkVectorJobStatus), { jobs: allJobs });
}

function* _getMatcherEvalJobs({actionType, Partners, matcher, additionalPayload }) {
  const allAppKeys = yield select(state => state.generativeAi.aiRules.allAppKeys);
  let allJobs = [];
  for (const p of Partners) {
    const foundIntegration = allAppKeys.find(k => k.hint === p.id);
    if (!foundIntegration) continue;
    const { jobs} = yield apiSaga(actionType, aiRulesApis.productRuleEval, [{
      testMatcher: matcher,
      ti2: {
        url: process.env.REACT_APP_TI2_URL,
        app: 'tourplan',
        hint: p.id,
        token: yield select(R.path(['account', 'travelMLSettings', 'ti2', 'token'])),
      },
      scopes: [p.id],
      companyId: yield select(R.path(['account', 'company', 'companyId'])),
    }, additionalPayload]);
    allJobs = [...allJobs, ...jobs];
  }
  return allJobs;
}

function* evalFirstOneFromList() {
  const { allRules, rulesToEvaluate } = yield select(state => state.generativeAi.aiRules);
  const firstRule = allRules.find(o => o.id === rulesToEvaluate[0]);
  if (!firstRule) return;
  const allJobs = yield _getMatcherEvalJobs({
    actionType: 'genAi_aiRules_productRuleEval_singleRow',
    Partners: firstRule.Partners,
    matcher: firstRule.matcher,
    additionalPayload: { id: firstRule.id, skipLoader: true },
  });
  yield spawn(fetchAsyncJobImport('genAi_aiRules_productRuleEval_singleRow', genaiApis.checkVectorJobStatus), {
    jobs: allJobs,
    additionalPayload: { id: firstRule.id },
  });
}

function* saveEvalJobsForRow({ additionalPayload }) {
  const { id } = additionalPayload;
  const { allRules } = yield select(state => state.generativeAi.aiRules);
  const foundRule = allRules.find(o => o.id === id);
  yield apiSaga('genAi_aiRules_saveEvalJobsForRow', aiRulesApis.updateRule, [{ id, body: foundRule.body }, { skipLoader: true }]);
}

// eslint-disable-next-line
export default [
  takeLatest('@@router/LOCATION_CHANGE', onLocationChange),
  takeLatest('genAi_aiRules_deleteRules', deleteRules),
  takeLatest('genAi_aiRules_assignPartners_assign', doAssignPartners),
  takeLatest('genAi_aiRules_editRule_onClickSave', doProductRuleEvalInEditRule),
  // takeLatest('genAi_aiRules_evaluation_evaluateText', doTextEvalInOneRule),
  takeLatest([
    'genAi_aiRules_evaluateSelectedProductRules',
    'genAi_aiRules_productRuleEval_singleRow_JobCheckComplete',
  ], evalFirstOneFromList),
  takeLatest('genAi_aiRules_editRule_productRuleEval_JobCheckComplete', shouldSaveRuleOrNot),
  takeLatest('genAi_aiRules_productRuleEval_singleRow_JobCheckComplete', saveEvalJobsForRow),
];