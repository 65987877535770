import workspaceList from '../workspace/__store__/sagas';
import contractList from '../contract-list/__store__/sagas';
import ratesDocIframe from './rates-doc-iframe.sagas';
import downloadDocs from './download-docs.sagas';
import { push, replace } from 'connected-react-router';
import * as R from 'ramda';
import { put, all, select, takeLatest } from 'redux-saga/effects';
import { apiSaga } from '../../../root-sagas';
import queryString from 'query-string';
import {
  contracting as cApi,
  account as acctApi,
  partnerManagement as pmApis,
} from '../../../api';
function* initContracting() {
  const { inited } = yield select(state => state.contracting.metaData);
  const location = yield select(
    state => state.router.location
  );
  if (location.pathname.indexOf('/contracting/') > -1 && !inited) {
    yield all([
      put({ type: '@contracting/init' }),
      put({ type: '@contracting/getAllFolders' }),
      apiSaga('@PM/GET_TAGS', pmApis.getTags, [{ skipLoader: true }]),
    ]);
  }
  if (location.pathname.indexOf('/search-results') > -1) {
    const query = queryString.parse(location.search || '');
    if (R.values(query).some(val => val && !R.isEmpty(val))) {
      yield put({
        type: '@contracting/updateSearchParams',
        payload: {
          ...query,
          defaultSearchInput: query.searchInput || '',
        },
      });
      yield searchAll({ type: '@contracting/search' });
    }
  }
}

function* getAllFolders() {
  yield apiSaga('@contracting/getAllFolders', cApi.getAllFolders, [{ skipLoader: true }])
}

function* createFolder({ type, payload }) {
  yield apiSaga(type, cApi.createFolder, [payload])
}

function* deleteFolder({ type, payload }) {
  yield apiSaga(type, cApi.deleteFolder, [payload])
}

function* updateFolders({ type, payload }) {
  yield apiSaga(type, cApi.updateFolders, [payload]);
}

function* searchAll({ type }) {
  const { searchInput } = yield select(state => state.contracting.search);
  if (searchInput && searchInput.trim()) {
    yield apiSaga(type, acctApi.omniSearch, [{ searchInput }]);
  }
}

function* onClickSearch() {
  const { searchInput } = yield select(state => state.contracting.search);
  const location = yield select(
    state => state.router.location
  );
  const routeObj = {
    pathname: '/contracting/search-results',
    search: queryString.stringify({
      searchInput,
    }),
  };
  if (location.pathname.indexOf('search-results') > -1) {
    yield put(replace(routeObj));
  }  else {
    yield put(push(routeObj));
  }
}

function* onSearchSuccess() {
  if (window.location.pathname.indexOf('contracting/search-results') === -1) {
    yield put(push('/contracting/search-results'));
  }
}

function* assignFolders({ type, payload }) {
  yield apiSaga(type, cApi.assignFolders, [payload])
}

function* updateWorkspaceContract({ type, payload }) {
  yield apiSaga(type, cApi.updateWorkspaceContract, [payload])
}

function* deleteOrUndeleteDoc({ type, payload }) {
  yield apiSaga(type, cApi.deleteOrUndelete, [payload])
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  ...workspaceList,
  ...contractList,
  ...ratesDocIframe,
  ...downloadDocs,
  takeLatest('@@router/LOCATION_CHANGE', initContracting),
  takeLatest([
    '@contracting/getAllFolders',
    '@contracting/createFolder_SUCCESS',
    '@contracting/deleteFolder_SUCCESS',
    '@contracting/updateFolders_SUCCESS',
  ], getAllFolders),
  takeLatest('@contracting/createFolder', createFolder),
  takeLatest('@contracting/deleteFolder', deleteFolder),
  takeLatest('@contracting/updateFolders', updateFolders),
  takeLatest('@contracting/search', onClickSearch),
  takeLatest('@contracting/search_SUCCESS', onSearchSuccess),
  takeLatest('@contracting/assignFolders', assignFolders),
  takeLatest('@contracting/updateWorkspaceContract', updateWorkspaceContract),
  takeLatest('@contracting/deleteOrUndelete', deleteOrUndeleteDoc),
];
