
const requestSigsIntial = {
  open: false,
  invitees: [],
  rateDocId: '',
  signatures: [],
  message: '',
  subject: '',
};

export function requestSigs(state = requestSigsIntial, { type, payload }) {
  switch (type) {
    case '{dummy-account}OPEN_REQUEST_SIGNATURE':
      return {
        ...state,
        open: true,
        ...payload,
      };
    case '{dummy-account} REQUEST_SIGNATURE_SUCCESS':
    case '{dummy-account}CANCEL_REQUEST_SIGNATURE':
      return requestSigsIntial;
    case '{dummy-account}UPDATE_PROPS':
      return {
        ...state,
        ...payload,
      };
    case '{dummy-account} REQUEST_SIGNATURE':
      return {
        ...state,
        sending: true,
      };
    case '{dummy-account} REQUEST_SIGNATURE_ERROR':
      return {
        ...state,
        sending: false,
      };
    default:
      return state;
  }
}

const initialIframe = {
  url: '',
  rateDocId: '',
  collection: '',
  open: false,
  acceptingDummyTemplate: false,
  isInSigning: false,
  buyerLogo: '',
  buyerName: '',
  loading: false,
  deletingDraft: false,
  rawDiffData: [],
  ratesSummary: {},
};

export default function rateDocIframe(state = initialIframe, { type, payload }) {
  switch (type) {
    case '{dummy-account} RateDocIframe_open':
      // this could be an iframe within iframe (buyer loading rates in their dummy account and previewing rates from another account)
      if (window.location.href.indexOf(process.env.SECONDARY_SYSTEM_URL) > -1) {
        localStorage.setItem('prevJwt', localStorage.getItem('jwt'));
      }
      return {
        ...state,
        open: true,
        ...payload,
      };
    case '{dummy-account} loadDataForIframe':
      return {
        ...state,
        loading: true,
      };
    case '{dummy-account} RateDocIframe_checkIfRateComplete':
      return {
        ...state,
        loading: true,
      };
    case '{dummy-account} RateDocIframe_checkIfRateComplete_SUCCESS':
    case '{dummy-account} loadDataForIframe_SUCCESS':
    case '{dummy-account} loadDraftDiff_SUCCESS':
      return {
        ...state,
        ...payload,
        url: (() => {
          if (!state.url && payload.magicLink) return payload.magicLink;
          return state.url;
        })(),
        loading: false,
      };
    case '{dummy-account} DISCARD_DUMMY_TEMPLATE_DRAFT':
      return {
        ...state,
        deletingDraft: true,
      };
    case '{dummy-account} DISCARD_DUMMY_TEMPLATE_DRAFT_SUCCESS':
    case '{dummy-account} ACCEPT_DUMMY_TEMPLATE_SUCCESS':
    case 'RateDocIframe_selectImportTemplate':
    case '{dummy-account} RateDocIframe_close':
      // this could be an iframe within iframe (buyer loading rates in their dummy account and previewing rates from another account)
      if (window.location.href.indexOf(process.env.SECONDARY_SYSTEM_URL) > -1 && localStorage.getItem('prevJwt')) {
        localStorage.setItem('jwt', localStorage.getItem('prevJwt'));
        localStorage.removeItem('prevJwt');
      }
      return initialIframe;
    case '{dummy-account} ACCEPT_DUMMY_TEMPLATE':
      return {
        ...state,
        acceptingDummyTemplate: true,
      };
    default:
      return state;
  }
}