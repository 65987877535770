import { put, takeEvery, select, takeLatest, delay, call } from 'redux-saga/effects';
import * as R from 'ramda';
import { apiSaga } from '../../../root-sagas';
import {
  contracting as cApi,
  fileUrls as fileApi,
  downloads as downloadApis,
} from '../../../api';

const jsonParseFn = str => {
  if (typeof str === 'object') return str;
  let result = {};
  try {
    // if plain js
    result = JSON.parse(str);
  } catch (e) {
    // forget about it :)
  }
  return result;
};

function* downloadContractingDocs() {
  const payload = yield select(R.path(['contracting', 'downloadDocs']));
  if (!payload) return;
  const payload2 = R.pick([
    'collection',
    'locale',
    'ratesOnly',
    'downloadRateFormat',
    'forAL',
  ], payload);
  const docIds = (() => {
    if (!R.isEmpty(payload.contractIds || [])) {
      return payload.contractIds;
    }
    if (!R.isEmpty(payload.templates || [])) {
      return payload.templates.map(({ _id }) => _id);
    }
    if (payload.docId) {
      return [payload.docId];
    }
    return []; // ?? nothing to do
  })();
  if (docIds.length === 1 && R.last(payload2.downloadRateFormat.split('_')) === 'singlefile') {
    // should not be single file if it is a single doc
    payload2.downloadRateFormat = payload2.downloadRateFormat.split('_').splice(0, 2).join('_');
  }
    // return R.isEmpty(payload.contractIds) ? [payload.docId] : payload.contractIds;
  // });
  yield apiSaga(
    '[contracting-download] downloadGroup',
    fileApi.downloadGroup,
    [{
      ...payload2,
      docIds,
      skipLoader: true,
    }],
  );
}

function* downloadContractingDocsSuccess({ type, payload }) {
  const { forAL } = yield select(R.path(['contracting', 'downloadDocs']));
  if (payload.status === 'processed' && payload.returnValue) {
    const url = R.path(['url'], jsonParseFn(payload.returnValue));
    // check if w ehave a return value URL or product an error
    if (url) {
      // success
      return yield put({
        type: `${type}_SUCCESS`,
        payload: { url },
      });
    }
  }
  if (payload.status === 'pending' && payload.jobUrl) { // still pending
    yield call(delay, 3e3);
    return yield apiSaga(
      '[contracting-download] downloadGroup',
      fileApi.checkJobUrl,
      [{
        ...payload,
        forAL,
        skipLoader: true,
      }],
    );
  }
  // error
  return yield put({
    type: `${type}_ERROR`,
  });
}

function downloadContractingUrl({ payload }) {
  const a = document.createElement('a');
  a.download = true;
  a.target = '_blank';
  a.setAttribute('download', true);
  a.href = payload.url;
  a.dispatchEvent(new MouseEvent('click'));
  // window.open(payload.url, '_blank');
}

function* shouldAskToAccept({ type }) {
  /*
  if in assets landing page
    check if user click a sinle contract or multiple contracts
    check if those contracts are in pending status, if so, prompt "would you like to accept?"
  if in single contract page
    check if that contract is in pending status
  else close dialog for single doc download, or how success for multi doc download
   */
  const { contractIds, docId } = yield select(state => state.contracting.downloadDocs);
  if (window.location.pathname.indexOf('assets-landing') > -1) {
    const { rates, viewAs } = yield select(state => state.assetsLandingReducer);
    const singleContractIsPending = docId && rates.find(o => o._id === docId && o.state === 'pending');
    const someBulkDownloadedContractsArePending = contractIds
      && contractIds.length
      && contractIds.some(id => rates.find(o => o._id === id && o.state === 'pending'));
    if (
      viewAs === 'Contractor'
      && (singleContractIsPending || someBulkDownloadedContractsArePending)
    ) {
      yield put({ type: '[contracting-download]ASK_ACCEPT_AFTER_DOWNLOAD' });
    } else if (type === '[contracting-download]DOWNLOAD_SINGLE_CONTRACT_DOC_SUCCESS') {
      yield put({ type: '[contracting-download]CLOSE_DOWNLOAD_POPOVER' });
    }
  } else if (window.location.pathname.indexOf('/contracting/contract/') > -1) {
    const currentState = yield select(state => state.contracting.contractActivitiesProducts.getIn(['leftNav', 'state']));
    const viewAs = yield select(state => state.user.getIn(['company', 'companyType']));
    if (currentState === 'pending' && viewAs === 'Contractor') {
      yield put({ type: '[contracting-download]ASK_ACCEPT_AFTER_DOWNLOAD' });
    } else if (type === '[contracting-download]DOWNLOAD_SINGLE_CONTRACT_DOC_SUCCESS') {
      yield put({ type: '[contracting-download]CLOSE_DOWNLOAD_POPOVER' });
    }
  } else if (type === '[contracting-download]DOWNLOAD_SINGLE_CONTRACT_DOC_SUCCESS') {
    yield put({ type: '[contracting-download]CLOSE_DOWNLOAD_POPOVER' });
  }
}

function* acceptRatesAfterDownload({ type }) {
  const { contractIds, docId } = yield select(state => state.contracting.downloadDocs);
  yield apiSaga(type, cApi.updateWorkspaceContract, [{
    docIds: contractIds && contractIds.length ? contractIds : [docId],
    updater: { state: 'accepted' }
  }]);
}


function* exportExcel({ type, payload: { exportType } }) {
  const { docId, collection } = yield select(R.path(['contracting', 'downloadDocs']));
  if (docId) {
    yield apiSaga(type, downloadApis.getXls, [collection, docId, exportType]);
  }
}

function* handleExportExcel({ payload: { location } }) {
  yield put({ type: '[contracting-download]CLOSE_DOWNLOAD_POPOVER' });
  window.open(location);
}

function* handleExportExcelError() {
  yield put({ type: 'SHOW_CONTRACTING_ERROR_STRING', payload: 'Unable to generate quickview file' });
  yield* put({ type: '[contracting-download]CLOSE_DOWNLOAD_POPOVER' });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest('[contracting-download]DOWLOAD_CONTRACTING_DOCS', downloadContractingDocs),
  takeEvery('[contracting-download] downloadGroup_SUCCESS', downloadContractingDocsSuccess),
  takeEvery('[contracting-download] downloadGroup_SUCCESS_SUCCESS', downloadContractingUrl),
  takeLatest('[contracting-download]ACCEPT_RATES', acceptRatesAfterDownload),
  takeLatest('[contracting-download]DOWNLOAD_SINGLE_CONTRACT_DOC_SUCCESS', shouldAskToAccept),
  takeLatest('[contracting-download]BULK_DOWNLOAD_SUCCESS', shouldAskToAccept),
  takeLatest('[contracting-download]downloadGroup_SUCCESS_SUCCESS', shouldAskToAccept),
  takeLatest('[contracting-download]EXPORT_EXCEL', exportExcel),
  takeEvery('[contracting-download]EXPORT_EXCEL_SUCCESS', handleExportExcel),
  takeEvery('[contracting-download]EXPORT_EXCEL_ERROR', handleExportExcelError),
];
