export const getContractRequestQForAL = `
  query getContract ($contractId: ID!, $viewerId: ID, $viewBy: String!, $email: String) {
    Contract (id: $contractId, viewerId: $viewerId, viewBy: $viewBy, email: $email) {
      _id
      startDate
      endDate
      createdAt
      state
      versions
      buyerInfo {
        termsAndConditions
        submissionCriteria
        corporateIdentificationNumber
      }
      buyerChecked
      files {
        _id
        name
        path
        createdAt
        source
      }
      deadlineDate
      rateConversionSetting
      rateConversionStatus
      from {
        avatar
        name
        email
      }
      to {
        avatar
        name
        partnerId
        contactIds
      }
      toEmails
    }
  }
`;

export const getBuyerDummyDataForAL = `
  query getContract ($contractId: ID!, $viewerId: ID, $viewBy: String!, $email: String, $realActorEmail: String) {
    Contract (id: $contractId, viewerId: $viewerId, viewBy: $viewBy, email: $email) {
      dummySupplierForCR {
        templatesForAL (realActorEmail: $realActorEmail) {
          _id
          threadId
          locationId
          originalContractId
          templateName
          status
          magicLink
          startDate
          endDate
          locationName
          originFrom
          createdAt
          updatedAt
          completionPercent
          acceptedBy
        }
      }
    }
  }
`;
