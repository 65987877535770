
import { combineReducers } from 'redux';
import editAppKey from './edit-app-key-configuration-reducer';
import productMappings from './product-mappings-reducer';
import extractionSettings from './extraction-settings-reducer';



const initialState = {
  pageTitle: '',
  toolId: 'itineraryAssist',
  integrationApp: '',
  hint: '', // hint could be "Direct Booking" (text) or the same as partnerId
  partnerId: '',
  partner: {},
  partners: [],
};

function metadata(state = initialState, { type, payload, args }) {
  switch(type) {
    case 'genAi_partnerSettings_updateProps':
      return {
        ...state,
        ...payload,
      };
    case 'genAi_partnerSettings_loadPartner_STARTED':
      return {
        ...state,
        partnerId: args[0].partnerId,
      };
    case 'genAi_partnerSettings_loadPartner_SUCCESS':
      return {
        ...state,
        partner: payload,
      };
    case 'genAi_partnerSettings_loadPartners_SUCCESS':
      return {
        ...state,
        partners: payload.partners,
      };
    default:
      return state;
  }
}



export default combineReducers({
  metadata,
  editAppKey,
  productMappings,
  extractionSettings,
})