import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider
} from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import moment from 'moment';
import { defaultTheme } from '../../utils/mui-themes';
import { navLang } from '../../utils/date';

const ALForContractRequest = React.lazy(() =>
  import('./for-contract-request/router-container')
);

const theme = createTheme(defaultTheme);
class ALApp extends Component {
  componentDidMount() {
    if (navLang) {
      moment.locale(navLang);
    }
  }
  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <React.Fragment>
            <CssBaseline />
            <div
              style={{
                color: 'rgba(0, 0, 0, 0.74)',
                width: '100%',
                margin: 'auto',
                padding: '0 64px 64px',
                fontFamily:
                  "'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif"
              }}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route
                    path="/assets-landing-request"
                    component={ALForContractRequest}
                  />
                </Switch>
              </Suspense>
            </div>
          </React.Fragment>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

const mapActions = {};

const mapState = ({ account: { accountLoaded } }) => ({
  accountLoaded
});

export default hot(module)(connect(mapState, mapActions)(ALApp));
