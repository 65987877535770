import { takeLatest } from 'redux-saga/effects';
import { apiSaga } from '../../../../root-sagas';
import {
  partnerManagement as pmApi,
  account as accountApis,
} from '../../../../api';

function* createNewTemplate({ type, payload }) {
  yield apiSaga(type, pmApi.createNewTemplate, [payload])
}

function* getAllTemplates({ type }) {
  yield apiSaga('@partner-management/getAllTemplates', pmApi.getAllTemplates, [])
}

function* updateATemplate({ type, payload }) {
  yield apiSaga(type, pmApi.updateATemplate, [payload])
}

function* deleteATemplate({ type, payload }) {
  yield apiSaga(type, pmApi.deleteATemplate, [payload])
}

function* updateSignature({ type, payload }) {
  yield apiSaga(type, pmApi.updateSignature, [payload]);
}

function* refreshAccountInfo() {
  yield apiSaga('@app/GET_ACCOUNT_INFO', accountApis.getAccountInfo, [])
}

// eslint-disable-next-line
export default [
  takeLatest('@partner-management/createNewTemplate', createNewTemplate),
  takeLatest([
    '@partner-management/getAllTemplates',
    '@partner-management/updateATemplate_SUCCESS',
    '@partner-management/createNewTemplate_SUCCESS',
  ], getAllTemplates),
  takeLatest('@partner-management/updateATemplate', updateATemplate),
  takeLatest('@partner-management/deleteATemplate', deleteATemplate),
  takeLatest('@partner-management/updateSignature', updateSignature),
  takeLatest('@partner-management/updateSignature_SUCCESS', refreshAccountInfo),
];
