
import * as R from 'ramda';
import {
  takeLatest,
  select,
  spawn,
  delay,
  put,
  all,
  fork,
} from 'redux-saga/effects';
import {
  generativeAi as generativeAiApis,
  account as accountApis,
} from '../../../api.js';
import { apiSaga } from '../../../root-sagas.js';
import itinerarySagas from './itinerary-sagas.js';
import revenueInsightsSagas from './revenueInsights-sagas.js';
import aiRulesSagas from './ai-rules-sagas.js';
import partnerSettingsSagas from './partner-settings/sagas.js';

import { fetchAsyncJob as fetchAsyncJobImport } from '../../../utils/fetchAsyncJob.js';

function* onLocationChange({ payload }) {
  if (R.path(['location', 'pathname'], payload).includes('generative-ai/configure-ai')
  && !R.path(['location', 'pathname'], payload).includes('generative-ai-sign-up')
  ) {
    yield all([
      bgRefreshAssets(),
      getAllCategories(),
    ]);
  }
}

function* getAllAssets() {
  yield apiSaga('generativeAI_getAllAssets', generativeAiApis.getAllAssets, [{ contentType: 'vectorstore' }]);
}
function* pollingAssetsTask () {
  let shouldStop = false;
  while (!shouldStop) {
    yield apiSaga('generativeAI_bgRefreshAssets', generativeAiApis.getAllAssets, [{ contentType: 'vectorstore', skipLoader: true }]);
    const assets = yield select(state => state.generativeAi.configuration.assets);
    if (assets.some(a => a.status !== 'vectorized' || (a.status || '').includes('error'))) {
      yield delay(2000);
    } else shouldStop = true;
  }
  return true;
}
function* bgRefreshAssets() {
  // const bgTask = yield fork(pollingAssetsTask);
  // yield delay(300e3);
  // yield cancel(bgTask);

  yield fork(pollingAssetsTask);
}

function* onCreateProcessFileJobSuccess({ payload }) {
  if (payload.jobs && payload.jobs.length) {
    yield all([
      bgRefreshAssets(),
      spawn(fetchAsyncJobImport('generativeAI_fileProcessJob', generativeAiApis.checkVectorJobStatus), { jobs: payload.jobs }),
    ]);
  }
}

function* getAllCategories() {
  yield apiSaga('generativeAI_getAllCategories', generativeAiApis.getAllCategories, []);
}

function* sendPrompt({ type }) {
  const {
    playgroundPrompt,
    scopes,
    responseStyles,
    emailBody,
  } = yield select(state => state.generativeAi.configuration);
  const apiPayload = {
    emailId: 'playground',
    prompt: playgroundPrompt,
    scopes,
    responseStyles: emailBody ? responseStyles : {},
    emailBody,
    skipLoader: true,
  }
  const retVal = yield apiSaga(type, generativeAiApis.sendPrompt, [apiPayload])
  if (retVal) {
    const { jobs } = retVal;
    if (jobs && jobs.length > 0) {
      yield spawn(fetchAsyncJobImport(type, generativeAiApis.checkVectorJobStatus), { jobs, mailId: 'playground' });
    }
  }
}

function* saveResponseStyles({ type, payload }) {
  yield apiSaga(type, accountApis.updateUser, [{ responseStyles: payload }])
}

function* uploadAsset({ type }) {
  const { addEditAssetDialog } = yield select(state => state.generativeAi.configuration);
  yield apiSaga(
    type,
    addEditAssetDialog.path ? generativeAiApis.updateAsset : generativeAiApis.uploadAsset,
    [addEditAssetDialog]
  )
}

function* callFileProcess({ payload }) {
  if (payload.path) {
    yield apiSaga('generativeAi_processFile', generativeAiApis.fileProcess, [{
      attachments: [payload.path],
      origin: 'clevercompose',
    }]);
  }
}

function* triggerFileProcess({ payload }) {
  // need to call update file to update the meta-status
  yield apiSaga('generativeAi_resetMetaStatus', generativeAiApis.updateAsset, [payload]);
  yield callFileProcess({ payload });
}

function* deleteAsset({ type, payload }) {
  yield apiSaga(type, generativeAiApis.deleteAsset, [payload])
}

function* doConfigureAI({ type }) {
  const retVal = yield apiSaga(
    type,
    generativeAiApis.vectorProcess,
    [{ skipLoader: true }],
  );
  let jobs = [];
  if (retVal && retVal.jobs) {
    jobs = retVal.jobs;
  }
  if (jobs && jobs.length) {
    yield spawn(fetchAsyncJobImport(type, generativeAiApis.checkVectorJobStatus), { jobs });
  }
}

function* markAiReady() {
  yield apiSaga('generativeAI_doConfigureAI_markAiReady', generativeAiApis.saveAISettings, [{
    generativeAIReady: true,
    generativeAIReadyAt: new Date().toISOString(),
  }]);
  yield delay(8000);
  yield put({ type: 'generativeAI_dismissAiReadyMessage' })
}

function* onConfigureAISuccess({ payload }) {
  if (payload.every(o => o.result && o.result.result === 'success')) {
    yield markAiReady();
  }
}

function* onFileProcessJobSuccess({ payload }) {
  if (payload.every(o => o.status === 'success')) {
    yield markAiReady();
  }
}

/*
Here is the flow
  upload/delete asset -> create job to process file -> polling files & checking job status
  -> stop polling when all files are processed and vectorized
  -> if a single file processing job is succes, mark ai ready
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest('@@router/LOCATION_CHANGE', onLocationChange),
  takeLatest('generativeAI_getAllAssets', getAllAssets),
  takeLatest([
    'generativeAI_uploadAsset_SUCCESS',
    'generativeAI_deleteAsset_SUCCESS',
  ], callFileProcess),
  takeLatest('generativeAi_triggerFileProcess', triggerFileProcess),
  takeLatest('generativeAi_processFile_SUCCESS', onCreateProcessFileJobSuccess),
  takeLatest('generativeAI_sendPrompt', sendPrompt),
  takeLatest('generativeAI_playgroundResponseStyles', saveResponseStyles),
  takeLatest('generativeAI_uploadAsset', uploadAsset),
  takeLatest('generativeAI_deleteAsset', deleteAsset),
  takeLatest('generativeAI_doConfigureAI', doConfigureAI),
  takeLatest('generativeAI_doConfigureAI_JobCheckComplete', onConfigureAISuccess),
  takeLatest('generativeAI_fileProcessJob_JobCheckComplete', onFileProcessJobSuccess),
  ...itinerarySagas,
  ...revenueInsightsSagas,
  ...aiRulesSagas,
  ...partnerSettingsSagas,
];
