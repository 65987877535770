const initialState = {
  isKnowledgeBaseActive: true,
  isLiveChatActive: false,
  isSupportDeskActive: false,
  isNewFeaturesActive: true,
  newFeaturesCount: 0,
  isTourActive: false,
  isOpen: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  return {
    ...state,
    ...(() => {
      switch (type) {
        case 'LIVE_CHAT_ACTIVE':
          return { isLiveChatActive: true };
        case 'LIVE_CHAT_INACTIVE':
          return { isLiveChatActive: true };
        case 'SUPPORT_DESK_ACTIVE':
          return { isSupportDeskActive: true };
        case 'NEW_FEATURES_ACTIVE':
          return { isNewFeaturesActive: true };
        case 'RECEIVE_NEW_FEATURES':
          return { newFeaturesCount: payload.count };
        case 'HELPWIDGET_OPEN':
          return { isOpen: true };
        case 'HELPWIDGET_CLOSE':
          return { isOpen: false };
        default:
          return {};
      }
    })()
  };
};
