import { select, takeLatest } from 'redux-saga/effects';
import {
  account as accountApis,
} from '../../../api';
import { apiSaga } from '../../../root-sagas';


function* doUpdateUserSettings({ type, payload }) {
  yield apiSaga(type, accountApis.updateUserSettings, [payload]);
}

function* doResetPassword({ type, payload }) {
  yield apiSaga(type, accountApis.resetPassword, [payload]);
}

function* refreshUsers() {
  yield apiSaga('@app/HYDRATE_SETTINGS', accountApis.hydrateSettings, [{ skipLoader: true }]);
}

function* onUpdateUserSuccess({ payload }) {
  if (!payload.emailExists) {
    yield refreshUsers();
  }
}

function* doAddAUser({ type }) {
  const {
    account: {
      allLocations,
      company: { companyName },
    },
    settings: {
      newUserForm,
    },
  } = yield select(state => state);
  // we are defaulting these user access because they are not necessarily needed to be exposed
  // to booking ai users
  const apiPayload = {
    companyName,
    corporateProfile: ['createLocation', 'read', 'write'],
    selectedLocs: allLocations.map(l => ({
      locId: l._id,
      admin: ['read', 'write'],
      contracting: ['read', 'write'],
      profile: ['read', 'write'],
      teams: ['admin', 'contracting'],
    })),
    userInfo: newUserForm, // firstName lastName email
  };
  yield apiSaga(type, accountApis.addUser, [apiPayload])
}

function* cancelSubscription({ type }) {
  const subscriptions = yield select(state => state.account.billing.billingSubscriptions);
  const {
    cancelReason,
    cancellationDetail,
  } = yield select(state => state.settings);
  yield apiSaga(type, accountApis.cancelSubscriptions, [{
    subscriptionIds: subscriptions.map(o => o.subscriptionId),
    cancelReason,
    cancellationDetail,
  }]);
}

function* updatePaymentSource({ type, payload }) {
  yield apiSaga(type, accountApis.updatePaymentMethod, [{ token: payload.id, paymentMethod: payload }]);
}


function* refreshBilling() {
  yield apiSaga('@app/GET_BILLING_INFO', accountApis.getBillingInfo, []);
}
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest('{settings} updateUser', doUpdateUserSettings),
  takeLatest([
    '{settings} updateUser_SUCCESS',
  ], onUpdateUserSuccess),
  takeLatest('{settings} resetPassword', doResetPassword),
  takeLatest('{settings} addAUser', doAddAUser),
  takeLatest('{settings} addAUser_SUCCESS', refreshUsers),
  takeLatest([
    '{settings} cancelSubscription_SUCCESS',
    '{settings} updatePaymentSource_SUCCESS',
  ], refreshBilling),
  takeLatest('{settings} cancelSubscription', cancelSubscription),
  takeLatest('{settings} updatePaymentSource', updatePaymentSource),
];
