const initialFieldValues = {
  editingField: '',
  oldValue: '',
  newValue: '',
  firstName: '',
  lastName: '',
  oldPw: '',
  newPw: '',
  confirmPw: '',
};

const initialState = {
  editUserMessage: {},
  passResetMessage: {},
  ...initialFieldValues,
  createUserDialogOpen: false,
  newUserForm: {
    firstName: '',
    lastName: '',
    email: '',
  },
  creditCardDialogOpen: false,
  cancelDialogOpen: false,
  invoicesDialogOpen: false,
  cancelReason: null,
  cancellationDetail: '',
  singleInvoice: null,
  invoiceHistory: [],
};

export default function account(state = initialState, { type, payload }) {
  switch (type) {
    case '{settings} updateProps':
      return {
        ...state,
        ...payload,
      };
    case '{settings} resetValues':
      return {
        ...state,
        ...initialFieldValues,
      };
    case '{settings} updateUser_SUCCESS':
      if (payload.emailExists) {
        return {
          ...state,
          editUserMessage: payload,
        };
      }
      return {
        ...state,
        editUserMessage: {},
        ...initialFieldValues,
      };
    case '{settings} resetPassword_SUCCESS':
      if (payload.error) {
        return {
          ...state,
          passResetMessage: payload,
        };
      }
      return {
        ...state,
        passResetMessage: {},
        ...initialFieldValues,
      };
    case '{settings} openCreateNewUserDialog':
      return {
        ...state,
        createUserDialogOpen: true,
      };
    case '{settings} addAUser_SUCCESS':
    case '{settings} closeCreateNewUserDialog':
      return {
        ...state,
        createUserDialogOpen: false,
        newUserForm: initialState.newUserForm,
      };
    case '{settings} updateNewUserForm':
      return {
        ...state,
        newUserForm: {
          ...state.newUserForm,
          ...payload,
        },
      };
    case '{settings} getInvoices_SUCCESS':
      return {
        ...state,
        invoiceHistory: payload.data,
      };
    case '{settings} cancelSubscription_SUCCESS':
      return {
        ...state,
        cancelDialogOpen: false,
      };
    case '{settings} updatePaymentSource':
      return {
        ...state,
        creditCardDialogOpen: false,
      };
    default:
      return state;
  }
}
