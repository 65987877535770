import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';

const LogsHeader = ({
  currentCompany,
  currentCompanyLoading,
}) => {
  if (currentCompanyLoading) return <>...</>;
  return (
    <>
      <strong>{currentCompany.company_name}</strong> is a {currentCompany.plan} {currentCompany._type} from {R.pathOr('?', ['address', 'country'], currentCompany)}
    </>
  )
};


const mapState = ({ admin: {
  currentCompanyLoading,
  currentCompany,
} }) => ({
  currentCompany,
  currentCompanyLoading,
});

export default hot(module)(connect(mapState, {})(LogsHeader));
