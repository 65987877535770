import React, { Component, Suspense } from 'react';
import './app.css';
import { Route, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider
} from '@mui/material/styles';
import { useScrollTrigger, CssBaseline } from '@mui/material';
import moment from 'moment';
// import HelloWorld from './components/Hello/Hello';
import { init } from './root-actions';
import { defaultTheme } from './utils/mui-themes';
import { navLang } from './utils/date';

const HelpWidget = React.lazy(() =>
  import('./components/helpWidget/help-widget')
);
const Wall = React.lazy(() => import('./components/walls/index'));
const GlobalSnackbar = React.lazy(() => import('./components/GlobalSnackbar'));
const GlobalLoadingBackdrop = React.lazy(() => import('./components/GlobalLoadingBackdrop'));
const AppBar = React.lazy(() => import('./components/AppBar/AppBar-core'));
const PM = React.lazy(() =>
  import('./components/partner-management/pm-container')
);
const TrialGo = React.lazy(() =>
  import('./components/TravelML/trial-go/index')
);
const TravelML = React.lazy(() => import('./components/TravelML/ml-container'));
const BookingsAI = React.lazy(() => import('./components/BookingsAI/container'));
const GenerativeAI = React.lazy(() => import('./components/GenerativeAI/ai-container'));
const BookingsAIUpgradeContainer = React.lazy(() => import('./components/Upgrade/bookingsAI'));
const EmailAssistUpgradeContainer = React.lazy(() => import('./components/Upgrade/emailAssist'));
const ItineraryAssistUpgradeContainer = React.lazy(() => import('./components/Upgrade/itineraryAssist'));
const Contracting = React.lazy(() => import('./components/contracting/routes.jsx'));
const SettingsContainer = React.lazy(() => import('./components/Settings/container.jsx'));

const REACT_APP_OLD_APP_URL = process.env.REACT_APP_OLD_APP_URL;
const theme = createTheme(defaultTheme);


function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

class App extends Component {
  componentDidMount() {
    this.props.init();
    if (navLang) {
      moment.locale(navLang);
    }
  }

  render() {
    const {
      props: { accountLoaded }
    } = this;
    // we don't want to show any content before we load our intial data
    if (!accountLoaded) {
      return <div>Loading...</div>;
    }
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <React.Fragment>
            <CssBaseline />
            <ElevationScroll {...this.props}>
              <Suspense fallback={<div />}>
                <AppBar />
              </Suspense>
            </ElevationScroll>
            <div
              style={{
                color: 'rgba(0, 0, 0, 0.74)',
                width: '100%',
                margin: '64px auto auto auto',
                paddingBottom: '75px',
                fontFamily:
                  "'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif"
              }}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  {/*<Redirect*/}
                  {/*  to="partner-management/partner-list/partner"*/}
                  {/*  from="/"*/}
                  {/*  exact*/}
                  {/*/>*/}
                  <Route
                    path="/upgrade"
                    exact
                    render={() => {
                      window.location.href = `${REACT_APP_OLD_APP_URL}/upgrade`;
                      return undefined;
                    }}
                  />
                  <Route path="/partner-management" component={PM} />
                  <Route path={`/travel-ml-setup-guide`} component={TrialGo} />
                  <Route path="/ai" component={TravelML} />
                  <Route path="/bookings-ai" component={BookingsAI}/>
                  <Route path="/generative-ai" component={GenerativeAI}/>
                  <Route path="/contracting" component={Contracting} />
                  <Route path="/upgrade-bookings-ai" component={BookingsAIUpgradeContainer}/>
                  <Route path="/upgrade-email-assist" component={EmailAssistUpgradeContainer}/>
                  <Route path="/upgrade-itinerary-assist" component={ItineraryAssistUpgradeContainer}/>
                  <Route path="/settings" component={SettingsContainer}/>
                </Switch>
              </Suspense>
            </div>
            <Suspense fallback={<div />}>
              <HelpWidget />
            </Suspense>
            <Suspense fallback={<div />}>
              <Wall />
            </Suspense>
            <Suspense fallback={<div />}>
              <GlobalSnackbar />
            </Suspense>
            <Suspense fallback={<div/>}>
              <GlobalLoadingBackdrop/>
            </Suspense>
          </React.Fragment>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

const mapActions = {
  init
};

const mapState = ({ account: { accountLoaded } }) => ({
  accountLoaded
});

export default hot(module)(connect(mapState, mapActions)(App));
