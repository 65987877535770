import moment from 'moment';
import { takeLatest, select } from 'redux-saga/effects';
import { account as accountApis } from '../../api.js';
import { apiSaga } from '../../root-sagas';


function* doUpgradeBookingsAI({ type, payload: { paymentMethod } }) {
  const { coupon } = yield select(state => state.upgrade);
  const { billing: { bookingPaidPrices, bookingFreeTrialSub } } = yield select(state => state.account);
  if (bookingFreeTrialSub && bookingFreeTrialSub.status === 'active') {
    yield apiSaga('Cancel_Subscription', accountApis.cancelSubscriptions, [{
      subscriptionIds: [bookingFreeTrialSub.subscriptionId],
      skipLoader: true,
    }]);
  }
  yield apiSaga(type, accountApis.createSubscriptions, [{
    coupon,
    items: bookingPaidPrices,
    paymentMethod,
    trialDays: bookingFreeTrialSub && bookingFreeTrialSub.status === 'active'
      ? moment(bookingFreeTrialSub.cancel_at).diff(new Date(), 'day')
      : 0,
  }]);
}


function* doUpgradeEmailAssistAI({ type, payload: { paymentMethod } }) {
  const { coupon } = yield select(state => state.upgrade);
  const { billing: { emailAssistPaidPrices, emailAssistFreeTrialSub } } = yield select(state => state.account);
  if (emailAssistFreeTrialSub && emailAssistFreeTrialSub.status === 'active') {
    yield apiSaga('Cancel_Subscription', accountApis.cancelSubscriptions, [{
      subscriptionIds: [emailAssistFreeTrialSub.subscriptionId],
      skipLoader: true,
    }]);
  }
  yield apiSaga(type, accountApis.createSubscriptions, [{
    coupon,
    items: emailAssistPaidPrices,
    paymentMethod,
    trialDays: emailAssistFreeTrialSub && emailAssistFreeTrialSub.status === 'active'
      ? moment(emailAssistFreeTrialSub.cancel_at).diff(new Date(), 'day')
      : 0,
  }]);
}


function* doUpgradeItineraryAssistAI({ type, payload: { paymentMethod } }) {
  const { coupon } = yield select(state => state.upgrade);
  const { billing: { itineraryAssistPaidPrices, itineraryAssistFreeTrialSub } } = yield select(state => state.account);
  if (itineraryAssistFreeTrialSub && itineraryAssistFreeTrialSub.status === 'active') {
    yield apiSaga('Cancel_Subscription', accountApis.cancelSubscriptions, [{
      subscriptionIds: [itineraryAssistFreeTrialSub.subscriptionId],
      skipLoader: true,
    }]);
  }
  yield apiSaga(type, accountApis.createSubscriptions, [{
    coupon,
    items: itineraryAssistPaidPrices,
    paymentMethod,
    trialDays: itineraryAssistFreeTrialSub && itineraryAssistFreeTrialSub.status === 'active'
      ? moment(itineraryAssistFreeTrialSub.cancel_at).diff(new Date(), 'day')
      : 0,
  }]);
}

function* getCouponInfo({ type }) {
  const { couponCode } = yield select(state => state.upgrade);
  yield apiSaga(type, accountApis.getCouponInfo, [{ couponCode, skipLoader: true }]);
}

function* onUpgradeSuccess(){
  if (window.rewardful) {
    const { user: { email } } = yield select(state => state.account);
    window.rewardful('convert', { email });
  }
}

function* refreshBilling() {
  yield apiSaga('@app/GET_BILLING_INFO', accountApis.getBillingInfo, [{ skipLoader: true }]);
}

function* joinAffiliateProgram() {
  // create affiliate if doesn't exist
  const { billing: { affiliate } } = yield select(state => state.account);
  if (!(affiliate && affiliate.id)) {
    yield apiSaga('joinAffiliateProgram', accountApis.joinAffiliate, []);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest('bookingsAI_Upgrade', doUpgradeBookingsAI),
  takeLatest('emailAssistAI_Upgrade', doUpgradeEmailAssistAI),
  takeLatest('itineraryAssistAI_Upgrade', doUpgradeItineraryAssistAI),
  takeLatest('upgrade_getCouponInfo', getCouponInfo),
  takeLatest([
    'bookingsAI_Upgrade_SUCCESS',
    'emailAssistAI_Upgrade_SUCCESS',
    'itineraryAssistAI_Upgrade_SUCCESS',
    'joinAffiliateProgram_SUCCESS',
  ], refreshBilling),
  takeLatest([
    'bookingsAI_Upgrade_SUCCESS',
    'emailAssistAI_Upgrade_SUCCESS',
    'itineraryAssistAI_Upgrade_SUCCESS',
  ], onUpgradeSuccess),
  takeLatest([
    'joinAffiliateProgram',
    'bookingsAI_Upgrade_SUCCESS',
    'emailAssistAI_Upgrade_SUCCESS',
    'itineraryAssistAI_Upgrade_SUCCESS',
  ], joinAffiliateProgram),
];
