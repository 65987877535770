import React from 'react';
import TextField from '../text-field';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { SxProps } from '@mui/system';
export interface OptionType {
  label: string;
  value: string;
  inputValue?: string;
  original?: any;
}

const filter = createFilterOptions<OptionType>();

export type SelectValueType = (OptionType & { __isNew__?: boolean })[];
interface PropsType {
  label?: string;
  placeholder?: string;
  value: SelectValueType;
  options: OptionType[];
  freeSolo: boolean;
  onChange: (newValue: SelectValueType) => void;
  sx?: SxProps;
  tfSx?: SxProps;
}

const CreatableMultiSelect = (props: PropsType) => {
  return (
    <Autocomplete
      multiple
      size="small"
      fullWidth
      disableCloseOnSelect
      sx={props.sx}
      value={props.value === undefined ? undefined : props.value.map(v => ({
        label: v.label,
        value: v.value,
        original: v.original
      }))}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          props.onChange([
            ...props.value,
            { __isNew__: true, label: newValue, value: newValue }
          ]);
        } else {
          props.onChange(
            newValue.map(v => {
              if (typeof v === 'string') {
                return {
                  __isNew__: true,
                  label: v,
                  value: v
                };
              } else if (v && v.inputValue) {
                // Create a new value from the user input
                return {
                  __isNew__: true,
                  label: v.inputValue,
                  value: v.inputValue
                };
              } else {
                return v;
              }
            })
          );
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (props.freeSolo && params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            value: params.inputValue,
            label: `Create "${params.inputValue}"`
          });
        }

        return filtered;
      }}
      filterSelectedOptions
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      forcePopupIcon
      id="creatable-multi-select"
      options={props.options}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option: string | OptionType) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      freeSolo={props.freeSolo}
      renderInput={params => (
        <TextField
          {...params}
          label={props.label}
          fullWidth
          variant="outlined"
          placeholder={props.placeholder}
        />
      )}
    />
  );
};
export default CreatableMultiSelect;
