import { select, takeLatest, debounce } from 'redux-saga/effects';
import { apiSaga } from '../../../../root-sagas';
import {
  contracting as cApi,
} from '../../../../api';


function* loadList() {
  const { tagsSelected, list } = yield select(state => state.contracting.workspaceList);
  yield apiSaga('@contracting_workspace_list/getList', cApi.getWorkspaceList, [{ tagsSelected, skipLoader: Boolean(list.length) }]);
}

function* getWorkspaceList() {
  const pathname = window.location.pathname;
  if (pathname.indexOf('workspace-list') > -1) {
    yield loadList();
  }
}

function* loadListAgainInBackground({ args }) {
  const pathname = window.location.pathname;
  if (pathname.indexOf('workspace-list') > -1) {
    if (args[0].secondTime) return;
    const { tagsSelected, list } = yield select(state => state.contracting.workspaceList);
    yield apiSaga('@contracting_workspace_list/getList', cApi.getWorkspaceList, [{
      tagsSelected,
      skipLoader: Boolean(list.length),
      secondTime: true,
      skipCache: true,
    }]);
  }
}

function* doSendRequest({ type, payload }) {
  if (payload.partners && payload.partners.length) {
    yield apiSaga(type, cApi.bulkResendContracts, [payload]);
  } else {
    yield apiSaga(type, cApi.sendRequest, [payload]);
  }
}

function* mergeWorkspaces({ type, payload }) {
  yield apiSaga(type, cApi.mergeWorkspaces, [payload]);
}

// eslint-disable-next-line
export default [
  takeLatest([
    '@@router/LOCATION_CHANGE',
  ], getWorkspaceList),
  takeLatest([
    '@contracting_workspace_list/loadList',
  ], loadList),
  debounce(500, [
    '@contracting_workspace_list/getList_SUCCESS',
    '@contracting/deleteOrUndelete_SUCCESS',
    '@contracting/assignFolders_SUCCESS',
    '@contracting/updateWorkspaceContract_SUCCESS',
    '@contracting_workspace/mergeWorkspaces_SUCCESS',
  ], loadListAgainInBackground),
  debounce(500, '@contracting_workspace_list/updateTagsSelected', loadList),
  takeLatest('@contracting_workspace_sendRequest/send', doSendRequest),
  takeLatest('@contracting_workspace/mergeWorkspaces', mergeWorkspaces),
];
