/* eslint-disable import/no-anonymous-default-export */
// Original source: https://github.com/flyingsparx/s3upload-coffee-javascript/blob/master/s3upload.js
// Modified to match our needs
import * as uuid from 'uuid';
export default {
  s3_sign_put_url: `${process.env.REACT_APP_API_URL}/s3/sign`,

  s3uploadPromise(file, ACL, keyPath) {
    return new Promise((resolve, reject) => {
      this.s3upload({
        file_to_upload: file,
        file_name: file.name,
        ACL: ACL || 'private',
        keyPath,
        onFinishS3Put(path) {
          resolve({
            path,
            downloadUrl: path, // for insurance file
            name: file.name,
            fileName: file.name, // for insurance file
            type: file.type,
            size: file.size
          });
        },
        onProgress() {},
        onError(status) {
          reject(status);
        }
      });
    });
  },

  s3upload(options) {
    const file = options.file_to_upload;
    const fileName = options.file_name;
    return this.executeOnSignedUrl(
      file,
      fileName,
      options,
      (signedURL, publicURL) => {
        return this.uploadToS3(file, signedURL, publicURL, fileName, options);
      }
    );
  },

  executeOnSignedUrl(file, fileName, options, callback) {
    const onError = options.onError;
    const xhr = new XMLHttpRequest();
    xhr.open(
      'GET',
      `${this.s3_sign_put_url}?s3_object_type=${
        file.type
      }&s3_file_name=${uuid.v1()}${
        options.ACL ? `&ACL=${options.ACL}` : ''
      }${
        options.keyPath ? `&keyPath=${options.keyPath}` : ''
      }`,
      true
    );
    xhr.overrideMimeType('text/plain; charset=x-user-defined');
    xhr.onreadystatechange = function() {
      let result;
      if (this.readyState === 4 && this.status === 200) {
        try {
          result = JSON.parse(this.responseText);
        } catch (error) {
          console.log(error);
          return false;
        }
        return callback(result.signedUrl, result.url);
      } else if (this.readyState === 4 && this.status !== 200) {
        return onError(
          `Could not contact request signing server. Status = ${this.status}`
        );
      }
    };
    return xhr.send();
  },

  uploadToS3(file, url, publicUrl, fileName, options) {
    const onFinishS3Put = options.onFinishS3Put;
    const onProgress = options.onProgress;
    const onError = options.onError;

    const xhr = this.createCORSRequest('PUT', url);
    if (!xhr) {
      onError('CORS not supported');
    } else {
      xhr.onload = function() {
        if (xhr.status === 200) return onFinishS3Put(publicUrl, fileName);
        return onError(`Upload error: ${xhr.status}`);
      };
      xhr.onerror = function() {
        return onError('XHR error.');
      };
      xhr.upload.onprogress = function(e) {
        let percentLoaded;
        if (e.lengthComputable) {
          percentLoaded = Math.round((e.loaded / e.total) * 100);
          return onProgress(percentLoaded);
        }
      };
    }
    xhr.setRequestHeader('Content-Type', file.type);
    // xhr.setRequestHeader('x-amz-acl', 'public-read');
    return xhr.send(file);
  },

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if (xhr.withCredentials !== null) {
      xhr.open(method, url, true);
    } else if (typeof XDomainRequest !== 'undefined') {
      // eslint-disable-next-line no-undef
      xhr = new XDomainRequest();
      xhr.open(method, url);
    } else {
      xhr = null;
    }
    return xhr;
  },

  resizeCoverImage(image, dimensions, context, type) {
    const maxWidth = 1440;
    const maxHeight = 350;
    let imageWidth = image.width;
    let imageHeight = image.height;
    if (imageWidth > imageHeight) {
      if (imageWidth > maxWidth) {
        imageHeight *= maxWidth / imageWidth;
        imageWidth = maxWidth;
      }
    } else {
      if (imageHeight > maxHeight) {
        imageWidth *= maxHeight / imageHeight;
        imageHeight = maxHeight;
      }
    }

    const canvasWidth = imageWidth;
    let canvasHeight = maxHeight;
    if (imageHeight < maxHeight) canvasHeight = imageHeight;

    const canvas = document.createElement('canvas');
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(context, 0, 0, imageWidth, imageHeight);
    return canvas.toDataURL(type);
  },

  resizeImage(image, dimensions, context, type) {
    const maxWidth = dimensions;
    const maxHeight = dimensions;
    let imageWidth = image.width;
    let imageHeight = image.height;

    if (imageWidth > imageHeight) {
      if (imageWidth > maxWidth) {
        imageHeight *= maxWidth / imageWidth;
        imageWidth = maxWidth;
      }
    } else {
      if (imageHeight > maxHeight) {
        imageWidth *= maxHeight / imageHeight;
        imageHeight = maxHeight;
      }
    }

    const canvas = document.createElement('canvas');
    canvas.width = imageWidth;
    canvas.height = imageHeight;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(context, 0, 0, imageWidth, imageHeight);
    return canvas.toDataURL(type);
  },

  dataURItoBlob(dataURI, imageType) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: imageType
    });
  }
};
