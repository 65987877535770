import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import * as R from 'ramda';
import s3Upload from './utils/s3Upload';
import { account as accountApis, notifications as apis } from './api';
import { cnts } from './root-actions';
import { cnts as notficationCnts } from './components/AppBar/notification/__storeFiles__/actions';

import { helloSagas } from './components/Hello/__store__/hello-sagas';
import { PM } from './components/partner-management/__store__/pm-sagas';
import partnerDetailsSagas from './components/partner-management/partner-details/__store__/sagas';
import contactDetailsSagas from './components/partner-management/contact-details/__store__/sagas';
import BounceListSagas from './components/partner-management/bounce-list/__store__/sagas';
import PMSettingsSagas from './components/partner-management/settings/__store__/sagas';
import { notifications } from './components/AppBar/notification/__storeFiles__/sagas';
import sidebarHelp from './components/helpWidget/__store__/sagas';
import { MLSagas } from './components/TravelML/__store__/ml-sagas';
import ALSagas from './components/AssetsLanding/__store__/main-sagas';
import ContractingSagas from './components/contracting/__store__/sagas';
import bookingsAiSagas from './components/SignUp/bookings-ai/__store__/sagas';
import generativeAiSignUpSagas from './components/SignUp/generative-ai/__store__/sagas';
import generativeAi from './components/GenerativeAI/__store__/sagas';
import upgradeSagas from './components/Upgrade/sagas';
import settingsSagas from './components/Settings/__storeFiles__/sagas';
import adminSagas from './components/admin/__store__/sagas';
import thirdPartyTracking from './thirdPartyTracking';
import { saveAs } from 'file-saver';

function* getAcctInfo() {
  yield apiSaga(cnts.GET_ACCOUNT_INFO, accountApis.getAccountInfo, []);
}

function* initApp() {
  yield all([
    getAcctInfo(),
    apiSaga(cnts.HYDRATE_SETTINGS, accountApis.hydrateSettings, []),
    apiSaga(cnts.GET_BILLING_INFO, accountApis.getBillingInfo, [{ skipLoader: true }]),
    apiSaga(notficationCnts.GET_NF_NOTIFICATIONS, apis.getNFNotifications, []),
    apiSaga(notficationCnts.GET_SM_NOTIFICATIONS, apis.getStatusMessage, []),
    apiSaga('{settings} getInvoices', accountApis.getInvoices, [{ skipLoader: true }]),
  ]);
}

function* updateUIpreferences({ type, payload }) {
  yield apiSaga(type, accountApis.updateUIpreferences, [payload]);
}
export function* rootSaga() {
  yield all([
    takeLatest(cnts.INIT, initApp),
    takeLatest('updateUIpreferences', updateUIpreferences),
    takeLatest('updateUIpreferences_SUCCESS', getAcctInfo),
    ...helloSagas,
    ...PM,
    ...partnerDetailsSagas,
    ...contactDetailsSagas,
    ...BounceListSagas,
    ...PMSettingsSagas,
    ...notifications,
    ...sidebarHelp,
    ...thirdPartyTracking,
    ...MLSagas,
    ...ALSagas,
    ...ContractingSagas,
    ...bookingsAiSagas,
    ...upgradeSagas,
    ...settingsSagas,
    ...adminSagas,
    ...generativeAiSignUpSagas,
    ...generativeAi,
  ]);
}

/* Saga Utils */

// checks if a value is an uploadable File class
export function* checkUpload(file, type) {
  const companyId = yield select(R.path(['account', 'company', 'companyId']));
  const keyPath = (() => {
    if (file.keyPath) return file.keyPath;
    if (companyId) return `${companyId}/${encodeURIComponent(file.name)}`;
    return null;
  })();
  // upload the File to s3
  yield put({ type: `${type}_S3UPLOAD_STARTED` });
  const retval = yield s3Upload.s3uploadPromise(file, 'private', keyPath);
  yield put({ type: `${type}_S3UPLOAD_SUCCESS`, payload: retval });
  return retval;
}

function* processObj(obj, type) {
  const newObj = {};
  for (const key in obj) {
    const oldValue = obj[key];
    if (Array.isArray(oldValue)) {
      // NOTE after wasting hours on this stupid thing:
      // The issue was, user tried to download 1088 partners, and these upload checks stopped at around 905th - 945th item and beyond
      // The for loop just stuck there and never move on.
      // Annnnd...
      // I didn't solve it.
      // This is just a workaround. I assume we wouldn't have an array with more than 100 items that needs to check for file uploads
      // If this becomes not the case anymore, feel free to revert this if else block and please please let me know how you solve the issue.
      if (oldValue.length < 100) {
        newObj[key] = yield processArray(oldValue, type);
      } else {
        newObj[key] = oldValue;
      }
    } else if (oldValue instanceof File) {
      newObj[key] = yield checkUpload(oldValue, type);
    } else if (
      typeof oldValue === 'object' &&
      oldValue !== null &&
      !Array.isArray(oldValue)
    ) {
      newObj[key] = yield processObj(oldValue, type);
    } else {
      newObj[key] = oldValue;
    }
  }
  return newObj;
}
function* processArray(arr, type) {
  let newArr = [];
  for (const ele of arr) {
    if (ele instanceof File) {
      const file = yield checkUpload(ele, type);
      newArr.push(file);
    } else if (typeof ele === 'object' && ele !== null && !Array.isArray(ele)) {
      const newEle = yield processObj(ele, type);
      newArr.push(newEle);
    } else if (Array.isArray(ele)) {
      const newEle = yield processArray(ele, type);
      newArr.push(newEle);
    } else {
      newArr.push(ele);
    }
  }
  return newArr;
}

// generic API call wrapper
export function* apiSaga(type, fn, args) {
  yield put({ type: `${type}_STARTED`, args });
  const nuArgs = yield processArray(args, type);
  const { response, error } = yield call(fn, ...nuArgs);
  if (response && response.isFile) {
    const blob = new Blob([response.csv], { type: response.fileType });
    saveAs(blob, response.fileName);
  }
  if (error) {
    if (R.pathOr(0, ['response', 'status'], error) === 403) {
      yield put({
        type: 'GLOBAL_ERROR_TRIGGERED',
        payload: { noAccessDialog: true }
      });
    }
    yield put({ type: `${type}_ERROR`, payload: error.message || error, error, args });
    const url = R.pathOr('<??>', ['request', 'url'], error);
    const errorStr = (() => {
      const method = R.pathOr('access', ['request', 'method'], error);
      return `[API] unable to ${method} ${url}`
    })();
    if (window.Rollbar) {
      window.Rollbar.warning(errorStr, {
        error,
        args: R.omit(['headers'], args),
      });
    } else {
      console.warn(errorStr);
    }
  } else {
    yield put({ type: `${type}_SUCCESS`, payload: response, args });
  }
  return response;
}
