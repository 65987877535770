
import * as R from 'ramda';
import {
  takeLatest,
  put,
} from 'redux-saga/effects';
import {
  partner as partnerApis,
  partnerManagement as pmApis
} from '../../../../api.js';
import { apiSaga } from '../../../../root-sagas.js';
import editAppKeySagas from './edit-app-key-configuration-sagas.js';
import productMappingsSagas from './product-mappings-sagas.js';
import extractionSettingsSagas from './extraction-settings-sagas.js';


function* onLocationChange({ payload }) {
  const pathname = R.pathOr('', ['location', 'pathname'], payload);
  if (pathname.includes('generative-ai/partner-settings') ) {
    const [hint] = pathname.replace('/generative-ai/partner-settings/', '').split('/');
    yield put({ type: 'genAi_partnerSettings_updateProps', payload: { hint } })
    const partnerId = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test(hint) ? hint : null;
    if (partnerId) {
      yield apiSaga('genAi_partnerSettings_loadPartner', partnerApis.getPartner, [{ partnerId }]);
    }
    yield apiSaga('genAi_partnerSettings_loadPartners', pmApis.getPartners, [])
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest('@@router/LOCATION_CHANGE', onLocationChange),
  ...editAppKeySagas,
  ...productMappingsSagas,
  ...extractionSettingsSagas,
];
