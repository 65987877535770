import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DatePicker from '../../style-guide/date-picker';
import { ParsableDate } from 'AllTypes';

type Props = {
  setStartDate: (date: Date | undefined) => void;
  setEndDate: (date: Date | undefined) => void;
  startDate: ParsableDate;
  endDate: ParsableDate;
};

const StartDateEndDatePicker = (props: Props) => {
  const { startDate, endDate, setStartDate, setEndDate } = props;
  return (
    <Box>
      <Grid container>
        <DatePicker
          id="date-picker-start"
          label="Start Date"
          color="secondary"
          value={startDate}
          onChange={setStartDate}
        />
        &nbsp;&nbsp;
        <DatePicker
          id="date-picker-end"
          label="End Date"
          color="secondary"
          InputAdornmentProps={{ position: 'start' }}
          value={endDate}
          onChange={setEndDate}
        />
      </Grid>
      {startDate && endDate && startDate >= endDate ? (
        <Box color="#ff4081">Start Date has to be before End Date</Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default StartDateEndDatePicker;
