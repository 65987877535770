import { combineReducers } from 'redux';
import notifications from './components/AppBar/notification/__storeFiles__/reducer';
import hello from './components/Hello/__store__/hello-reducer';
import PM from './components/partner-management/__store__/pm-reducer';
import ML from './components/TravelML/__store__/ml-reducer';
import helpWidget from './components/helpWidget/__store__/helpwidget-reducer';
import sidebarHelp from './components/helpWidget/__store__/reducer';
import walls from './components/walls/__store__/reducer';
import account from './account-reducer';
import { globalUI } from './global-reducer';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import AL from './components/AssetsLanding/__store__/reducer';
import BookingsAISignUp from './components/SignUp/bookings-ai/__store__/reducer';
import generativeAiSignUp from './components/SignUp/generative-ai/__store__/reducer';
import generativeAi from './components/GenerativeAI/__store__/reducer';
import upgrade from './components/Upgrade/reducer';
import contracting from './components/contracting/__store__/reducer';
import settings from './components/Settings/__storeFiles__/reducer';
import admin from './components/admin/__store__/reducer';

const createReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    notifications,
    hello,
    account,
    PM,
    helpWidget,
    sidebarHelp,
    walls,
    ML,
    globalUI,
    AL,
    contracting,
    BookingsAISignUp,
    generativeAiSignUp,
    generativeAi,
    upgrade,
    settings,
    admin,
  });

export default createReducer;
