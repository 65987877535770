import * as R from 'ramda';

const initialState = {
  categories: [{
    category: 'Brochures',
    promptCategory: 'Brochures',
    slur: 'brochures',
    defaultAssetType: 'file',
    allowedAssetTypes: ['URL', 'text', 'file'],
  }],
  aiReadyPercentage: 0,
  assets: [],
  loadingAssets: false,
  buildingAI: false,
  aiBuiltError: '',
  aiBuiltMessage: '',
  generativeAIReady: false,
  generativeAIReadyAt: null,
  addEditAssetDialog: {},
  savingAsset: false,
  savingAssetError: '',
  addEditAssetDialogOpen: false,
  playgroundPrompt: '',
  scopes: ['any', 'internal'],
  playgroundResponse: '',
  responseStyles: {
    tone: 'Match the inquiry',
    responseLength: 'Match the inquiry',
    language: 'Match the inquiry',
  },
  emailBody: '',
  loadingResponseError: '',
  loadingResponse: false,
};


export default function configuration(state = initialState, { type, payload, args }) {
  switch(type) {
    case '@app/GET_ACCOUNT_INFO_SUCCESS': {
      return {
        ...state,
        generativeAIReady: payload.travelMLSettings.generativeAIReady,
        generativeAIReadyAt: payload.travelMLSettings.generativeAIReadyAt,
        responseStyles: payload.user.responseStyles || state.responseStyles,
      };
    }
    case 'generativeAi_updateProps': {
      return {
        ...state,
        ...payload,
      }
    }
    case 'generativeAI_getAllCategories_SUCCESS': {
      return {
        ...state,
        categories: payload || state.categories,
      };
    }
    case 'generativeAI_bgRefreshAssets_SUCCESS':
    case 'generativeAI_getAllAssets_SUCCESS': {
      return {
        ...state,
        loadingAssets: false,
        assets: payload || state.assets,
        generativeAIReady: payload ? payload.find(o => o.status === 'vectorized') : state.generativeAIReady,
      };
    }
    case 'generativeAI_addEditAssetDialog_updateProps': {
      return {
        ...state,
        addEditAssetDialog: {
          ...state.addEditAssetDialog,
          ...payload,
        },
      };
    }
    case 'generativeAI_uploadAsset': {
      return {
        ...state,
        savingAsset: true,
      };
    }
    case 'generativeAI_uploadAsset_ERROR': {
      return {
        ...state,
        savingAsset: false,
        savingAssetError: payload,
      };
    }
    case 'generativeAI_uploadAsset_SUCCESS': {
      return {
        ...state,
        savingAsset: false,
        addEditAssetDialogOpen: false,
        addEditAssetDialog: {},
      };
    }
    case 'generativeAI_playgroundSetText': {
      return {
        ...state,
        [payload.key]: payload.value,
      };
    }
    case 'generativeAI_playgroundResponseStyles': {
      return {
        ...state,
        responseStyles: payload,
      };
    }
    case 'generativeAI_sendPrompt': {
      return {
        ...state,
        loadingResponse: true,
        playgroundResponse: '',
      }
    }
    case 'generativeAI_sendPrompt_ERROR': {
      return {
        ...state,
        loadingResponse: false,
      }
    }
    case 'generativeAI_sendPrompt_JobCheckComplete': {
      if (R.path([0, 'status'], payload) === 'success') {
        return {
          ...state,
          playgroundResponse: R.path([0, 'result'], payload),
          loadingResponse: false,
        };
      }
      if (R.path([0, 'status'], payload) === 'failure') {
        return {
          ...state,
          loadingResponse: false,
          loadingResponseError: 'Oops, something went wrong.',
        };
      }
      return state;
    }
    case 'generativeAI_enterPrompt': {
      return {
        ...state,
        playgroundPrompt: payload,
      }
    }
    case 'generativeAI_setScopes': {
      return {
        ...state,
        scopes: payload,
      };
    }
    case 'generativeAI_doConfigureAI': {
      return {
        ...state,
        buildingAI: true,
        aiBuiltError: '',
      };
    }
    case 'generativeAI_doConfigureAI_ERROR': {
      return {
        ...state,
        buildingAI: false,
        aiBuiltError: 'Oops, something went wrong.',
      };
    }
    case 'generativeAI_doConfigureAI_JobCheckComplete': {
      if (payload.every(o => o.result && o.result.result === 'success')) {
        return {
          ...state,
          buildingAI: false,
          aiBuiltMessage: 'Congratulations! Your AI is ready to use.',
          generativeAIReady: true,
          generativeAIReadyAt: new Date().toISOString(),
        };
      }
      return {
        ...state,
        buildingAI: false,
        aiBuiltError: 'Something went wrong configuring your AI.  Your files have been saved; however, please click "Configure AI" again later.',
        generativeAIReady: false,
      };
    }
    case 'generativeAI_dismissAiReadyMessage': {
      return {
        ...state,
        aiBuiltMessage: '',
      };
    }
    case 'generativeAI_clearInputPlayground': {
      return {
        ...state,
        playgroundPrompt: '',
        playgroundResponse: '',
        emailBody: '',
      }
    }
    default:
      return state;
  }
}