
export enum cnts {
  RESET_BTN_CLICK = '@Hello/WORLD_RESET_BTN_CLICK',
  RESET = '@Hello/WORLD_RESET',
  REVERT = '@Hello/REVERT',
  TF_CHANGE = '@Hello/TF_CHANGE'
}

export type ThisActionType = {
  type: typeof cnts.RESET_BTN_CLICK,
} | {
  type: typeof cnts.RESET,
} | {
  type: typeof cnts.REVERT,
} | {
  type: typeof cnts.TF_CHANGE,
  payload: string,
}

export const resetBtnClick = () => ({ type: cnts.RESET_BTN_CLICK });
export const revertBtnClick = () => ({ type: cnts.REVERT });
export const doReset = () => ({ type: cnts.RESET });
export const onChange = (value: string) => ({ type: cnts.TF_CHANGE, payload: value });
