import * as R from 'ramda';
import {
  takeLatest,
  select,
  delay,
  put,
} from 'redux-saga/effects';
import {
  generativeAi as genAiApis,
} from '../../../../api.js';
import { apiSaga } from '../../../../root-sagas.js';
import {
  _getAccountInfo
} from './edit-app-key-configuration-sagas.js'


function* getStagingAIRules(payload = {}) {
  delay(2000);
  const { companyId, ti2token } = yield _getAccountInfo();
  yield apiSaga('genAi_productMappings_loadStagingAIRules', genAiApis.getStagingAIRules, [{
    ...payload,
    companyId,
    ti2token,
  }])
}


function* onLocationChange({ payload }) {
  const pathname = R.pathOr('', ['location', 'pathname'], payload);
  if (pathname.includes('/generative-ai/partner-settings') && pathname.includes('/product-mappings')) {
    // eslint-disable-next-line no-unused-vars
    const [hint, tool, app] = pathname
      .replace('/generative-ai/partner-settings/', '')
      .replace('/partner-mappings', '').split('/');
    yield getStagingAIRules({ app, hint });
  }
}

function* createRules() {
  const {
    modifiedStagingAIRules,
    stagingAIRules,
    partner,
  } = yield select(state => state.generativeAi.productMappings);
  const updatedStagingAIRules = stagingAIRules.filter(r => modifiedStagingAIRules.includes(r.id));
  for (const rule of updatedStagingAIRules) {
    const cleanOptions = (rule.body.options || []).map(o => R.pick(['productId',
      'optionId',
      'productName',
      'optionName'], o)).filter(o => o.optionId && o.productId);
    if (!cleanOptions.length) continue;
    yield apiSaga('genAi_productMappings_saveARule', genAiApis.createRule, [{
      matcher: rule.extractedServiceName,
      content: `If the service name contains the text: ${rule.extractedServiceName}, please add ${
        cleanOptions.length === 1
        ? ''
        : `ALL ${['', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX'][cleanOptions.length]} of `
      }the following service line to the json: ${JSON.stringify(cleanOptions)}`,
      ruleType: 'product',
      scope: rule.scope || '',
      partnerIds: partner && partner.partner && rule.scope !== 'all' ? [partner.partner.id] : [],
      tools: ['itineraryAssist'],
      body: {
        options: cleanOptions
      },
      skipLoader: true,
    }, { stagingRuleId: rule.id }])
  }
  yield put({ type: 'genAi_productMappings_createRulesInBatch_DONE' })
  // save the updated stagingAIRules just for record
  const {
    // stagingAIRules are updated with the aiRuleId after AI rules are created
    // modifiedStagingAIRules should be cleared out by now, hence still using the old one
    stagingAIRules: stagingAIRules2,
  } = yield select(state => state.generativeAi.productMappings);
  const updatedStagingAIRules2 = stagingAIRules2.filter(r => modifiedStagingAIRules.includes(r.id));
  yield apiSaga('genAi_productMappings_saveStagingAIRules', genAiApis.updateStagingAIRules, [{
    rules: updatedStagingAIRules2,
    skipLoader: true,
  }]);
}

// eslint-disable-next-line
export default [
  takeLatest('@@router/LOCATION_CHANGE', onLocationChange),
  takeLatest('genAi_productMappings_createRulesInBatch', createRules),
];