import { combineReducers } from 'redux';
import * as R from 'ramda';

const initialSettingState = {
  basicSettings: {
    enabled: false,
    modules: []
  },
  entitySettings: {
    levelType: '3',
    levels: [{}, {}, {}]
  },
  saving: false,
  basicSettingsInEdit: {},
  entitySettingsInEdit: {},
  settingsOpen: false,
  entitySettingsOpen: false,
};

function settings(state = initialSettingState, { type, payload }) {
  switch (type) {
    case '@app/GET_ACCOUNT_INFO_SUCCESS':
      const entitySettings = {
        ...state.entitySettings,
        levelType:
          R.pathOr(
            state.entitySettings.levelType,
            ['travelMLSettings', 'entityLevels', 'length'],
            payload
          ) + '',
        levels: R.pathOr(
          state.entitySettings.levels,
          ['travelMLSettings', 'entityLevels'],
          payload
        )
      };
      return {
        ...state,
        entitySettings,
        entitySettingsInEdit: entitySettings
      };
    case '{ML} UpdateBasicSettings':
      return {
        ...state,
        basicSettingsInEdit: {
          ...state.basicSettingsInEdit,
          ...payload
        }
      };
    case '{ML} OpenSettingDialog':
      return {
        ...state,
        basicSettingsInEdit: state.basicSettings,
        settingsOpen: true
      };
    case '{ML} CloseSettingDialog':
      return {
        ...state,
        basicSettingsInEdit: {},
        settingsOpen: false
      };
    case '{ML} SaveSettings':
      return {
        ...state,
        saving: true
      };
    case '{ML} SaveSettings_SUCCESS':
      return {
        ...state,
        saving: false,
        basicSettings: state.basicSettingsInEdit,
        basicSettingsInEdit: {},
        settingsOpen: false
      };
    case '{ML} OpenEntitySettingDialog':
      return {
        ...state,
        entitySettingsInEdit: state.entitySettings,
        entitySettingsOpen: true
      };
    case '{ML} UpdateEntitySettingsLevelType':
      return {
        ...state,
        entitySettingsInEdit: {
          ...state.entitySettingsInEdit,
          levelType: payload,
          levels: Array(Number(payload)).fill({ label: '' })
        }
      };
    case '{ML} UpdateEntitySettingsLevelLabel':
      return {
        ...state,
        entitySettingsInEdit: {
          ...state.entitySettingsInEdit,
          levels: payload.levels
        }
      };
    case '{ML} SaveEntitySettings':
      return {
        ...state,
        saving: true
      };
    case '{ML} CloseEntitySettingDialog':
      return {
        ...state,
        entitySettingsOpen: false
      };
    case '{ML} SaveEntitySettings_SUCCESS':
      return {
        ...state,
        saving: false,
        entitySettings: state.entitySettingsInEdit,
        entitySettingsOpen: false
      };
    case '{ML} SaveSettings_ERROR':
    case '{ML} SaveEntitySettings_ERROR':
      return {
        ...state,
        saving: false
      };
    default:
      return state;
  }
}

const initialStateSuppliers = {
  upload: {},
  rows: [],
  rowsPage: 0,
  rowsPerPage: 10,
  rowsLoading: false,
  rowsEntityName: '',
  totalRows: 0,
  slideOpen: false,
  showIdFields: false,
  loading: false,
  removingItem: false,
  editDialog: {
    editDialogOpen: false,
    editRow: {},
    editColumn: {},
    editValue: ''
  },
  lookupMetaResult: {},
};

function suppliers(state = initialStateSuppliers, { type, payload }) {
  switch (type) {
    case 'ML_collectionRemoveItem_STARTED':
      return {
        ...state,
        removingItem: true,
      };
    case 'ML_collectionRemoveItem_SUCCESS':
    case 'ML_collectionRemoveItem_ERROR':
      return {
        ...state,
        removingItem: false,
      };
    case 'ML_openAddPartnerSlide':
      return {
        ...state,
        slideOpen: true,
        lookupMetaResult: {},
        showIdFields: payload.showIdFields
      };
    case 'ML_closeAddPartnerSlide':
      return {
        ...state,
        slideOpen: false,
        lookupMetaResult: {},
      };
    case 'ML_suppliers_setEditDialog':
      return {
        ...state,
        editDialog: payload
      };
    case 'ML_suppliers_onCloseEdit':
      return {
        ...state,
        editDialog: initialStateSuppliers.editDialog
      };
    case 'ML_getAllCollection_SUCCESS':
      return {
        ...state,
        rowsLoading: false,
        rows: payload.collection,
        totalRows: payload.total,
      };
    case 'ML_getAllCollection_STARTED':
      return {
        ...state,
        rowsLoading: true,
      };
    case 'ML_getAllCollection_ERROR':
      return {
        ...state,
        rowsLoading: true,
      };
    case 'ML_getAllCollection_rowsPage':
      return {
        ...state,
        rowsPage: payload,
      };
    case 'ML_getAllCollection_rowsPerPage':
      return {
        ...state,
        rowsPerPage: payload,
      };
    case 'ML_getAllCollection_rowsEntityName':
      return {
        ...state,
        rowsEntityName: payload,
      };
    case 'ML_addCollection':
    case 'ML_updateACollectionRow':
      return {
        ...state,
        loading: true
      };
    case 'ML_addCollection_ERROR':
    case 'ML_addCollection_SUCCESS':
    case 'ML_updateACollectionRow_ERROR':
      return {
        ...state,
        loading: false
      };
    case 'ML_updateACollectionRow_SUCCESS':
      return {
        ...state,
        loading: false,
        editDialog: initialStateSuppliers.editDialog
      };
    case 'ML_lookupMetaMatchResult':
      return {
        ...state,
        lookupMetaResult: payload,
      };
    default:
      return state;
  }
}

const initialExports = {
  loading: false,
  closeouts: {
    rows: [],
    params: {}
  },
  offers: {
    rows: [],
    params: {}
  },
  retryStopSale: {},
};

function exports(state = initialExports, { type, payload }) {
  switch (type) {
    case 'ML_getAllOffersExports':
    case 'ML_getAllStopSaleExports':
      return {
        ...state,
        loading: true
      };
    case 'ML_getAllStopSaleExports_SUCCESS':
      return {
        ...state,
        closeouts: {
          ...state.closeouts,
          rows: payload.export
        },
        loading: false
      };
    case 'ML_getAllOffersExports_SUCCESS':
      return {
        ...state,
        offers: {
          ...state.closeouts,
          rows: payload.export
        },
        loading: false
      };
    case 'ML_getAllOffersExports_ERROR':
    case 'ML_getAllStopSaleExports_ERROR':
      return {
        ...state,
        loading: false
      };
    default:
      const startedRetry = R.path([1], type.match(/^\{ML\} RetryStopSale (.+)_STARTED$/));
      if (startedRetry) {
        return {
          ...state,
          retryStopSale: {
            ...state.retryStopSale,
            [startedRetry]: {
              loading: true,
            },
          },
        }
      }
      const stoppedRetry = R.path([1], type.match(/^\{ML\} RetryStopSale (.+)_(ERROR|SUCCESS)$/));
      if (stoppedRetry) {
        return {
          ...state,
          retryStopSale: {
            ...state.retryStopSale,
            [stoppedRetry]: {
              loading: false,
            },
          },
        }
      }
      return state;
  }
}
export default combineReducers({
  settings,
  suppliers,
  exports,
});
