import * as R from 'ramda';


const initialState = {
  list: [],
  currentId: '',
  fileDialog: {
    open: false,
  },
};

export default function configuration(state = initialState, { type, payload, args }) {
  switch(type) {
    case 'genAi_getItineraries_SUCCESS': {
      return {
        ...state,
        list: payload,
      }
    }
    case 'genAI_setCurrentItineraryId':
      return {
        ...state,
        currentId: payload.id,
      };
    case 'genAi_getItinerary_SUCCESS': {
      if (!(payload && payload.id)) return state;
      return {
        ...state,
        [payload.id]: payload,
      };
    }
    case 'genAI_openFileDialog': {
      return {
        ...state,
        fileDialog: {
          open: true,
          itineraryId: state.currentId,
          files: R.pathOr([], [state.currentId, 'files'], state),
        },
      };
    }
    case 'genAi_closeFileDialog':
    case 'genAi_saveItinerary_SUCCESS':
      return {
        ...state,
        fileDialog: initialState.fileDialog,
      };
    case 'genAI_updatePropsForFileDialog': {
      return {
        ...state,
        fileDialog: {
          ...state.fileDialog,
          ...payload,
        },
      };
    }
    default:
      return state;
  }
}
