import { sendAssetsCnts, partnerListCnts } from './pm-actions';
import { ThisActionType } from './pm-store-types';
import {
  Assets,
  MM,
  RateTemplate,
  RateTemplateGroup,
  RecipientType
} from 'AllTypes';
import { EditorState } from 'draft-js';
import * as R from 'ramda';

type AssetType = keyof Assets;

type StateType = {
  sending: boolean;
  testEmailSent: boolean;
  assetsSent: boolean;
  errorString: string | undefined;
  rateTemplateData: {
    templates: RateTemplate[];
    templateGroups: RateTemplateGroup[];
    loadingTemplates: boolean;
    templatesLoaded: boolean;
    // error: string,
  };
  mmData: {
    media: MM[];
    loadingMedia: boolean;
    mediaLoaded: boolean;
  };
  fileName?: string;
  recipients: RecipientType[];
  recipientsMap: {
    [key: string]: {
      [key: string]: boolean | string;
    };
  };
  assets: Assets;
  folders: String[];
  doNotNotify: boolean;
  rateConversionParty?: string;
  defaultMessage?: string;
  defaultSignature?: string;
  defaultOpenAssetType?: AssetType;
  messageHtml?: string;
  messageForDL?: string;
  subject?: string;
  personalizeGreeting: boolean;
  greeting: string;
  goBackTo: string;
  editorState: EditorState;
};

const initialState = {
  sending: false,
  testEmailSent: false,
  assetsSent: false,
  errorString: '',
  rateTemplateData: {
    templates: [],
    templateGroups: [],
    loadingTemplates: false,
    templatesLoaded: false
    // error: '',
  },
  mmData: {
    media: [],
    loadingMedia: false,
    mediaLoaded: false
  },
  fileName: '',
  recipients: [],
  /*
  recipientsMap {
    <partnerId>: {
      <contactId>: true,
    },
  }
   */
  recipientsMap: {},
  assets: {
    rates: { templates: [] },
    requests: [],
    uploads: [],
    mms: []
  },
  folders: [],
  doNotNotify: false,
  rateConversionParty: '',
  defaultMessage: '',
  defaultSignature: '',
  messageHtml: '',
  messageForDL: '',
  subject: '',
  personalizeGreeting: false,
  greeting: 'Hi',
  goBackTo: '/partner-management/partner-list/partner',
  editorState: EditorState.createEmpty()
} as StateType;

const convertRecipientsToMap = (recipients: RecipientType[]) => {
  return recipients.reduce((result, obj) => {
    result[obj.partnerId] = R.zipObj(obj.contactIds, obj.contactIds);
    return result;
  }, {} as StateType['recipientsMap']);
};

function sendAssets(state: StateType = initialState, action: ThisActionType) {
  switch (action.type) {
    case sendAssetsCnts.CLEAR_STORE:
      return initialState;
    case sendAssetsCnts.SET_DEFAULTS:
      return {
        ...state,
        defaultOpenAssetType: action.payload.defaultOpenAssetType,
        goBackTo: action.payload.go_back_to || state.goBackTo,
        recipients:
          action.payload.recipients && action.payload.recipients.length
            ? action.payload.recipients
            : state.recipients,
        recipientsMap:
          action.payload.recipients && action.payload.recipients.length
            ? convertRecipientsToMap(action.payload.recipients)
            : state.recipientsMap,
        assets: {
          ...state.assets,
          rates: action.payload.rates || state.assets.rates
        } as StateType['assets'],
        defaultMessage: action.payload.defaultMessage,
        defaultSignature: action.payload.defaultSignature,
        subject: state.subject || action.payload.subject,
        messageHtml: state.messageHtml || action.payload.messageHtml,
        ...(action.payload.rateConversionParty
          ? {
              doNotNotify: true,
              rateConversionParty: action.payload.rateConversionParty
            }
          : {
            doNotNotify: Boolean(action.payload.doNotNotify),
          })
      };
    case sendAssetsCnts.GET_TEMPLATES:
    case sendAssetsCnts.GET_TEMPLATES_STARTED:
      return {
        ...state,
        rateTemplateData: {
          ...state.rateTemplateData,
          loadingTemplates: true
        }
      };
    case sendAssetsCnts.GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        rateTemplateData: {
          ...state.rateTemplateData,
          loadingTemplates: false,
          templatesLoaded: true,
          ...action.payload
        }
      };
    case sendAssetsCnts.GET_TEMPLATES_ERROR:
      return {
        ...state,
        rateTemplateData: {
          ...state.rateTemplateData,
          loadingTemplates: false
          // error: action.payload,
        }
      };
    case sendAssetsCnts.GET_MM:
      return {
        ...state,
        mmData: {
          ...state.mmData,
          loadingMedia: true
        }
      };
    case sendAssetsCnts.GET_MM_SUCCESS:
      const allProdsNLocs = action.payload.locations.reduce(
        (result: { [key: string]: string }, loc) => {
          result[loc.id] = loc.name;
          loc.products.forEach(prod => {
            result[prod.id] =
              action.payload.locations.length > 1
                ? `${loc.name} > ${prod.name}`
                : prod.name;
          });
          return result;
        },
        {}
      );
      return {
        ...state,
        mmData: {
          ...state.mmData,
          loadingMedia: false,
          mediaLoaded: true,
          media: action.payload.media.map(o => ({
            ...o,
            tags: o.tags.map(tagId => allProdsNLocs[tagId]).filter(x => x)
          }))
        }
      };
    case sendAssetsCnts.GET_MM_ERROR:
      return {
        ...state,
        mmData: {
          ...state.mmData,
          loadingMedia: false
          // error: action.payload,
        }
      };
    case sendAssetsCnts.SET_ASSETS:
      return {
        ...state,
        assets: action.payload.assets
      };
    case sendAssetsCnts.SET_ASSETS_SETTINGS:
      return {
        ...state,
        ...action.payload
      };
    case sendAssetsCnts.SET_SUBJECT:
      return {
        ...state,
        subject: action.payload
      };
    case sendAssetsCnts.TOGGLE_PERSONALIZE_GREETING:
      return {
        ...state,
        personalizeGreeting: !state.personalizeGreeting
      };
    case sendAssetsCnts.SET_GREETING:
      return {
        ...state,
        greeting: action.payload
      };
    case sendAssetsCnts.SET_MSG_BODY:
      return {
        ...state,
        messageHtml: action.payload.messageHtml,
        editorState: action.payload.editorState
      };
    case sendAssetsCnts.SELECT_ALL_PARTNERS_CONTACTS:
      const partnerIdsInPayload = action.payload.partners.map(obj => obj.id);
      const updatedRecipients = action.payload.checked
        ? [
            ...state.recipients,
            ...action.payload.partners.map(partner => ({
              partnerId: partner.id,
              contactIds: partner.contacts.map(R.prop('id'))
            }))
          ].filter(obj => obj.contactIds.length)
        : state.recipients.filter(
            obj => partnerIdsInPayload.indexOf(obj.partnerId) === -1
          );
      return {
        ...state,
        recipients: updatedRecipients,
        recipientsMap: convertRecipientsToMap(updatedRecipients)
      };
    case sendAssetsCnts.SELECT_PARTNER:
      const partnerId = action.payload.partner.id;
      const contactIds = action.payload.partner.contacts.map(R.prop('id'));
      if (action.payload.checked) {
        return {
          ...state,
          recipients: [...state.recipients, { partnerId, contactIds }].filter(
            obj => obj.contactIds.length
          ),
          recipientsMap: {
            ...state.recipientsMap,
            [partnerId]: R.zipObj(contactIds, contactIds)
          }
        };
      }
      return {
        ...state,
        recipients: state.recipients.filter(obj => obj.partnerId !== partnerId),
        recipientsMap: {
          ...state.recipientsMap,
          [partnerId]: {}
        }
      };
    case sendAssetsCnts.SELECT_CONTACT:
      const pId = action.payload.partner.id;
      const contactId = action.payload.contact.id;
      const recipientsMap = R.set(
        R.lensPath([pId, contactId]),
        action.payload.checked,
        state.recipientsMap
      ) as StateType['recipientsMap'];
      const recipients = R.keys(recipientsMap)
        .map(id => {
          const recipient = {
            partnerId: id,
            contactIds: R.keys(recipientsMap[id]).filter(cId =>
              R.pathOr(false, [id, cId], recipientsMap)
            )
          };
          if (recipient.contactIds.length) return recipient;
          return undefined;
        })
        .filter(x => x) as RecipientType[];
      return {
        ...state,
        recipients,
        recipientsMap
      };
    case partnerListCnts.GET_PARTNERS_SUCCESS: {
      const recipients = state.recipients.filter(({ partnerId }) => {
        return action.payload.partners.some(({ id, contacts }) => id === partnerId && contacts.length);
      });
      const recipientsMap = convertRecipientsToMap(recipients);
      return {
        ...state,
        recipients,
        recipientsMap,
      };
    }
    case sendAssetsCnts.SEND_TEST_EMAIL:
    case sendAssetsCnts.SEND_ASSETS:
      return {
        ...state,
        sending: true
      };
    case sendAssetsCnts.SEND_ASSETS_ERROR:
    case sendAssetsCnts.SEND_TEST_EMAIL_ERROR:
      return {
        ...state,
        sending: false,
        errorString: action.payload
      };
    case sendAssetsCnts.SEND_TEST_EMAIL_SUCCESS:
      return {
        ...state,
        sending: false,
        testEmailSent: true
      };
    case sendAssetsCnts.SEND_ASSETS_SUCCESS:
      return {
        ...state,
        sending: false,
        assetsSent: true
      };
    case sendAssetsCnts.SEND_TEST_EMAIL_S3UPLOAD_SUCCESS:
    case sendAssetsCnts.SEND_ASSETS_S3UPLOAD_SUCCESS:
      return {
        ...state,
        assets: {
          ...state.assets,
          uploads: state.assets.uploads.map(upload =>
            upload.name === action.payload.name ? action.payload : upload
          )
        }
      };
    case sendAssetsCnts.TOGGLE_TEST_EMAIL_SENT:
      return {
        ...state,
        testEmailSent: false
      };
    case sendAssetsCnts.SELECT_RECIPIENTS_AFTER_IMPORT:
      return {
        ...state,
        ...action.payload
      };
    case sendAssetsCnts.CLEAR_IMPORTED_FILE:
      return {
        ...state,
        recipients: [],
        recipientsMap: {},
        fileName: ''
      };
    case sendAssetsCnts.DISMISS_ERROR:
      return {
        ...state,
        errorString: ''
      };
    case '@@router/LOCATION_CHANGE':
      if (
        action.payload.location.pathname === '/partner-management/create-workspace'
        && action.payload.location.query
        && action.payload.location.query.goBackTo
      ) {
        return {
          ...state,
          goBackTo: action.payload.location.query.goBackTo,
        };
      };
      return state;
    default:
      return state;
  }
}

export default sendAssets;
