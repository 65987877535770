
const initialState = {
  hint: '',
  app: '',
  configuration: null,
  saved: false,
  allAppKeys: [],
  notInternetEnabledOptions: [],
};

export default function editAppKey(state = initialState, { type, payload, args }) {
  switch(type) {
    case 'genAi_editAppKey_loadAllAppKeys_SUCCESS': {
      return {
        ...state,
        allAppKeys: payload.userAppKeys,
      }
    }
    case 'genAi_editAppKey_getNotInternetEnabledOptions_SUCCESS': {
      return {
        ...state,
        notInternetEnabledOptions: payload.notInternetEnabledOptions,
      }
    }
    case 'genAi_editAppKey_loadInitialData_SUCCESS': {
      return {
        ...initialState,
        ...payload.token,
        allAppKeys: state.allAppKeys,
        hint: args[0].hint,
        app: args[0].app,
      }
    }
    case 'genAi_editAppKey_updateProps': {
      return {
        ...state,
        ...payload,
      }
    }
    case 'genAi_editAppKey_saveConfiguration': {
      return {
        ...state,
        saving: true,
      };
    }
    case 'genAi_editAppKey_saveConfiguration_SUCCESS': {
      return {
        ...state,
        saving: false,
        saved: true,
        needToBeSaved: false,
        newFilters: null,
      };
    }
    case 'genAi_editAppKey_saveConfiguration_ERROR': {
      return {
        ...state,
        saving: false,
        error: payload,
      };
    }
    default:
      return state;
  }
}
