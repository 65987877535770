// import * as R from 'ramda';
import {
  takeLatest,
  select,
  // spawn,
  put,
  debounce,
} from 'redux-saga/effects';
import {
  generativeAi as genAiApis,
  aiRules as aiRulesApis
} from '../../../../api.js';
import { apiSaga } from '../../../../root-sagas.js';
import * as R from 'ramda';


function* getLLMParserInJson({ type }) {
  const { toolId, partnerId } = yield select(state => state.generativeAi.partnerSettings.metadata);
  yield apiSaga(type, genAiApis.getLLMParser, [{ toolId, partnerId }]);
}
function* onLocationChange({ payload }) {
  const pathname = R.pathOr('', ['location', 'pathname'], payload);
  if (pathname.includes('generative-ai/partner-settings') && pathname.includes('extraction')) {
    const [hint, toolId] = pathname
      .replace('/generative-ai/partner-settings/', '')
      .replace('/extraction', '').split('/');
    yield put({ type: 'genAi_partnerSettings_updateProps', payload: { hint, pageTitle: 'Extraction Settings' } });
    const partnerId = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test(hint) ? hint : null;
    if (partnerId) {
      yield put({ type: 'genAI_extractionSettings_getLLMParserInJson', payload: { toolId, partnerId } });
    }
  }
}

function* _saveParsersAndRulesForPartner({ partnerId }) {
  const { userParsers } = yield select(state => state.generativeAi.partnerSettings.extractionSettings);
  const { toolId } = yield select(state => state.generativeAi.partnerSettings.metadata);
  const removedRules = userParsers.map(p => R.omit(['rule'], p));
  yield apiSaga('genAI_extractionSettings_saveParsers', genAiApis.saveLLMParser, [{
    toolId,
    partnerId,
    userParsers: removedRules,
  }]);
  for (const parser of userParsers) {
    const { rule } = parser;
    if (!(rule && rule.content)) continue;
    yield apiSaga('genAI_extractionSettings_saveRule',
      rule.id
      ? aiRulesApis.updateRule
      : aiRulesApis.createRule,
      [{
        ...rule,
        tools: [toolId],
        partnerIds: [partnerId],
      }]);
  }
}

function* doSaveParsersAndRules() {
  const { partnerId } = yield select(state => state.generativeAi.partnerSettings.metadata);
  yield _saveParsersAndRulesForPartner({ partnerId });
  yield put({ type: 'genAI_extractionSettings_getLLMParserInJson' })
}

function* doCopyToPartner({ payload }) {
  const copyToPartnerId = payload.partnerId;
  yield _saveParsersAndRulesForPartner({ partnerId: copyToPartnerId });
  yield put({ type: 'genAI_extractionSettings_copyToPartner_SUCCESS' })
  debounce(4000, 'genAI_extractionSettings_updateProps', { text: '', severity: '' });
}

// eslint-disable-next-line
export default [
  takeLatest('@@router/LOCATION_CHANGE', onLocationChange),
  takeLatest('genAI_extractionSettings_getLLMParserInJson', getLLMParserInJson),
  takeLatest('genAI_extractionSettings_saveParsersAndRule', doSaveParsersAndRules),
  takeLatest('genAI_extractionSettings_copyToPartner', doCopyToPartner),
];