import { all, put, select, takeLatest } from 'redux-saga/effects';
import { apiSaga } from '../../../../root-sagas';
import {
  contracting as conApi,
  workspace as wpApi,
  partner as partnerApi
} from '../../../../api';
import * as cnt from './constants.js';

const getRoute = state => state.router.location.pathname;

export function* refreshPartnerInfo() {
  const partnerId = yield select(
    state => state.PM.partnerDetails.partnerInfo.id
  );
  yield apiSaga(cnt.GET_PARTNER_INFO, partnerApi.getPartner, [{ partnerId }]);
}

function* getPartnerDetails({
  payload: {
    location: { pathname }
  }
}) {
  if (pathname.split('/')[2] === 'partner-details') {
    const partnerId = pathname.split('/')[3];
    yield put({ type: cnt.CLEAR_STORE });

    yield all([
      apiSaga(cnt.GET_PARTNER_INFO, partnerApi.getPartner, [{ partnerId }]),
      _getFiles(partnerId),
      _getActivities(partnerId)
    ]);
  }
}

function* doAddANote({ payload }) {
  yield* apiSaga('@partner_contact_details/ADD_A_NOTE', wpApi.addANote, [payload]);
}

export function* refreshActivities() {
  const pathname = yield select(getRoute);
  if (pathname.split('/')[2] === 'partner-details') {
    const companyId = pathname.split('/')[3];
    yield* apiSaga(cnt.GET_ACTIVITY, partnerApi.getActivities, [
      { assetId: companyId }
    ]);
  }
}

function* _getActivities(partnerId) {
  yield* apiSaga(cnt.GET_ACTIVITY, partnerApi.getActivities, [
    { assetId: partnerId }
  ]);
}

function* _getFiles(partnerId) {
  yield* apiSaga(cnt.GET_FILES, conApi.getContracts, [
    {
      query: { partnerIds: partnerId },
      includeWorkspacesIfBuyer: true
    }
  ]);
}

function* markAsRead() {
  // markgin all conversation as read (general an contracting)
  const pathname = yield select(getRoute);
  if (pathname.split('/')[2] === 'partner-details') {
    const partnerId = pathname.split('/')[3];
    yield apiSaga(cnt.CONVERSATIONS_READ, wpApi.conversationsRead, [
      { assetId: partnerId, assetType: 'partner' }
    ]);
  }
}

function* doUpdatePartnerDetails({ type, payload }) {
  const partnerId = yield select(
    state => state.PM.partnerDetails.partnerInfo.id
  );
  yield* apiSaga(type, partnerApi.updatePartnerDetails, [
    {
      ...payload,
      partnerId
    }
  ]);
}

function* doUnassocMsg({ payload }) {
  const confirm = window.confirm(
    'Are you sure you want to remove this activity from this partner?'
  );
  if (confirm) {
    const assetId = yield select(
      state => state.PM.partnerDetails.partnerInfo.id
    );
    yield* apiSaga(cnt.UNASSOC_A_MESSAGE, partnerApi.unassocAMessage, [
      { messageId: payload.messageId, assetId }
    ]);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest('@@router/LOCATION_CHANGE', getPartnerDetails),
  takeLatest('@partner_contact_details/ADD_A_NOTE', doAddANote),
  takeLatest(`@partner_contact_details/ADD_A_NOTE_SUCCESS`, refreshActivities),
  takeLatest(`${cnt.GET_ACTIVITY}_SUCCESS`, markAsRead),
  takeLatest(cnt.UPDATE_PARTNER_DETAILS, doUpdatePartnerDetails),
  takeLatest(`${cnt.UPDATE_PARTNER_DETAILS}_SUCCESS`, refreshPartnerInfo),
  takeLatest(cnt.UNASSOC_A_MESSAGE, doUnassocMsg),
  takeLatest([
    `${cnt.UNASSOC_A_MESSAGE}_SUCCESS`,
    `${cnt.UPDATE_PARTNER_DETAILS}_SUCCESS`,
  ], refreshActivities)
];
