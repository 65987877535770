import {
  PMTagType,
  PartnerListSearchParams,
  CreatePartnerPayload,
  Assets,
  PartnerType,
  ContactType,
  RecipientType,
  RateTemplateAsset,
  poliPayload
} from 'AllTypes';
import { EditorState } from 'draft-js';
export enum pmCnts {
  INIT = '@PM/INIT',
  GET_TAGS = '@PM/GET_TAGS',
  GET_TAGS_SUCCESS = '@PM/GET_TAGS_SUCCESS',
  GET_FIELDS = '@PM/GET_FIELDS',
  GET_FIELDS_SUCCESS = '@PM/GET_FIELDS_SUCCESS',
  EXPORT_CSV = '@PM/EXPORT_CSV',
  EXPORT_CSV_SUCCESS = '@PM/EXPORT_CSV_SUCCESS',
  OPEN_EDIT_DIALOG = '@PM/OPEN_EDIT_DIALOG',
  CLOSE_EDIT_DIALOG = '@PM/CLOSE_EDIT_DIALOG',
  SAVE_TAG_CHANGES = '@PM/SAVE_TAG_CHANGES',
  SAVE_TAG_CHANGES_STARTED = '@PM/SAVE_TAG_CHANGES_STARTED',
  SAVE_TAG_CHANGES_SUCCESS = '@PM/SAVE_TAG_CHANGES_SUCCESS',
  SAVE_TAG_CHANGES_ERROR = '@PM/SAVE_TAG_CHANGES_ERROR',
  ARCHIVE_PARTNERS = '@PM/ARCHIVE_PARTNERS',
  ARCHIVE_PARTNERS_STARTED = '@PM/ARCHIVE_PARTNERS_STARTED',
  ARCHIVE_PARTNERS_SUCCESS = '@PM/ARCHIVE_PARTNERS_SUCCESS',
  ARCHIVE_PARTNERS_ERROR = '@PM/ARCHIVE_PARTNERS_ERROR',
  ARCHIVE_CONTACTS = '@PM/ARCHIVE_CONTACTS',
  ARCHIVE_CONTACTS_STARTED = '@PM/ARCHIVE_CONTACTS_STARTED',
  ARCHIVE_CONTACTS_SUCCESS = '@PM/ARCHIVE_CONTACTS_SUCCESS',
  ARCHIVE_CONTACTS_ERROR = '@PM/ARCHIVE_CONTACTS_ERROR',
  MERGE_PARTNERS = '@PM/MERGE_PARTNERS',
  MERGE_PARTNERS_STARTED = '@PM/MERGE_PARTNERS_STARTED',
  MERGE_PARTNERS_SUCCESS = '@PM/MERGE_PARTNERS_SUCCESS',
  MERGE_PARTNERS_ERROR = '@PM/MERGE_PARTNERS_ERROR',
  UNARCHIVE_PARTNER = '@PM/UNARCHIVE_PARTNER',
  UNARCHIVE_PARTNER_STARTED = '@PM/UNARCHIVE_PARTNER_STARTED',
  UNARCHIVE_PARTNER_SUCCESS = '@PM/UNARCHIVE_PARTNER_SUCCESS',
  UNARCHIVE_PARTNER_ERROR = '@PM/UNARCHIVE_PARTNER_ERROR',
  UNARCHIVE_CONTACT = '@PM/UNARCHIVE_CONTACT',
  UNARCHIVE_CONTACT_STARTED = '@PM/UNARCHIVE_CONTACT_STARTED',
  UNARCHIVE_CONTACT_SUCCESS = '@PM/UNARCHIVE_CONTACT_SUCCESS',
  UNARCHIVE_CONTACT_ERROR = '@PM/UNARCHIVE_CONTACT_ERROR',
  SAVE_CONTACTS_FOR_PARTNER = '@PM/SAVE_CONTACTS_FOR_PARTNER',
  SAVE_CONTACTS_FOR_PARTNER_STARTED = '@PM/SAVE_CONTACTS_FOR_PARTNER_STARTED',
  SAVE_CONTACTS_FOR_PARTNER_SUCCESS = '@PM/SAVE_CONTACTS_FOR_PARTNER_SUCCESS',
  SAVE_CONTACTS_FOR_PARTNER_ERROR = '@PM/SAVE_CONTACTS_FOR_PARTNER_ERROR'
}

export enum partnerListCnts {
  INIT = '@partner-list/INIT',
  GET_PARTNERS_STARTED = '@partner-list/GET_PARTNERS_STARTED',
  GET_CONTACTS_STARTED = '@partner-list/GET_CONTACTS_STARTED',
  GET_PARTNERS = '@partner-list/GET_PARTNERS',
  GET_CONTACTS = '@partner-list/GET_CONTACTS',
  GET_PARTNERS_SUCCESS = '@partner-list/GET_PARTNERS_SUCCESS',
  GET_CONTACTS_SUCCESS = '@partner-list/GET_CONTACTS_SUCCESS',
  REFRESH_PARTNERS_STARTED = '@partner-list/REFRESH_PARTNERS_STARTED',
  REFRESH_CONTACTS_STARTED = '@partner-list/REFRESH_CONTACTS_STARTED',
  REFRESH_PARTNERS = '@partner-list/REFRESH_PARTNERS',
  REFRESH_CONTACTS = '@partner-list/REFRESH_CONTACTS',
  REFRESH_PARTNERS_SUCCESS = '@partner-list/REFRESH_PARTNERS_SUCCESS',
  REFRESH_CONTACTS_SUCCESS = '@partner-list/REFRESH_CONTACTS_SUCCESS',
  SELECT_AN_ITEM = '@partner-list/SELECT_AN_ITEM',
  SELECT_ALL_ITEMS = '@partner-list/SELECT_ALL_ITEMS',
  DO_SEARCH_WITH_PARAMS = '@partner-list/DO_SEARCH_WITH_PARAMS',
  SORT_LIST = '@partner-list/SORT_LIST'
}

export enum pdCnts {
  GET_PARTNER_INFO = '@partner-details/GET_PARTNER_INFO',
  GET_ACTIVITY = '@partner-details/GET_ACTIVITY',
  GET_FILES = '@partner-details/GET_FILES'
}

export const initPMContainer = () => ({ type: pmCnts.INIT });

export const getPartners = () => ({ type: partnerListCnts.GET_PARTNERS });

export const selectAnItem = (payload: {
  id: string;
  type: string;
  checked: boolean;
}) => ({
  type: partnerListCnts.SELECT_AN_ITEM,
  payload
});

export const selectAllItems = (payload: {
  type?: string;
  checked: boolean;
}) => ({
  type: partnerListCnts.SELECT_ALL_ITEMS,
  payload
});

export const doSearchWithParams = (searchParams: PartnerListSearchParams) => ({
  type: partnerListCnts.DO_SEARCH_WITH_PARAMS,
  payload: searchParams
});

export const sortList = ({
  arrow,
  column
}: {
  arrow: string;
  column: string;
}) => ({
  type: partnerListCnts.SORT_LIST,
  payload: { arrow, column }
});

export const openEditDialog = (payload: {
  openDialog: string;
  items: Array<poliPayload>;
  data?: {
    existingTags?: PMTagType[] | [];
    addOnly?: boolean;
  };
}) => ({
  type: pmCnts.OPEN_EDIT_DIALOG,
  payload
});

export const closeEditDialog = () => ({
  type: pmCnts.CLOSE_EDIT_DIALOG
});

export const saveTagChanges = (payload: {
  tagsToAdd: PMTagType[] | [];
  tagsToRemove: PMTagType[] | [];
  shouldRemoveExistingTags: boolean;
}) => ({
  type: pmCnts.SAVE_TAG_CHANGES,
  payload
});

export const saveContacts = (payload: {
  contacts: CreatePartnerPayload['contacts'];
  deletedContacts: CreatePartnerPayload['contacts'];
}) => ({
  type: pmCnts.SAVE_CONTACTS_FOR_PARTNER,
  payload
});

export const mergePartners = (payload: {
  partnerIdToKeep: string;
  partnerIdsToMerge: string[];
}) => ({
  type: pmCnts.MERGE_PARTNERS,
  payload
});

export const archivePartners = (payload: { archiveContactsBool: boolean }) => ({
  type: pmCnts.ARCHIVE_PARTNERS,
  payload
});
export const archiveContacts = () => ({
  type: pmCnts.ARCHIVE_CONTACTS
});

export const unarchivePartner = (payload: { partnerId: string }) => ({
  type: pmCnts.UNARCHIVE_PARTNER,
  payload
});
export const unarchiveContact = (payload: { contactId: string }) => ({
  type: pmCnts.UNARCHIVE_CONTACT,
  payload
});

export const exportCSV = (
  items: { id: string; type: 'partner' | 'contact'; name?: string }[]
) => ({
  type: pmCnts.EXPORT_CSV,
  payload: { items }
});

export enum addPartnersCnts {
  OPEN_ADD_ONE_DRAWER = '@add-partners/OPEN_ADD_ONE_DRAWER',
  CLOSE_ADD_ONE_DRAWER = '@add-partners/CLOSE_ADD_ONE_DRAWER',
  CREATE_ONE_PARTNER_STARTED = '@add-partners/CREATE_ONE_PARTNER_STARTED',
  CREATE_ONE_PARTNER = '@add-partners/CREATE_ONE_PARTNER',
  CREATE_ONE_PARTNER_SUCCESS = '@add-partners/CREATE_ONE_PARTNER_SUCCESS',
  CREATE_ONE_PARTNER_ERROR = '@add-partners/CREATE_ONE_PARTNER_ERROR',
  IMPORT_FILE = '@add-partners/IMPORT_FILE',
  IMPORT_FILE_SUCCESS = '@add-partners/IMPORT_FILE_SUCCESS',
  IMPORT_FILE_ERROR = '@add-partners/IMPORT_FILE_ERROR',
  GO_BACK_FROM_IMPORT = '@add-partners/GOBACK_FROM_IMPORT'
}

export const onCreateOnePartner = (
  payload: CreatePartnerPayload,
  another: boolean
) => ({
  type: addPartnersCnts.CREATE_ONE_PARTNER,
  payload,
  another
});

export const importFile = (payload: {
  rawData: string[][];
  tags: PMTagType[];
  ifDuplicateDo: { [key: string]: string };
  matches: { [key: string]: string };
  fileName: string;
}) => ({
  type: addPartnersCnts.IMPORT_FILE,
  payload
});

export const goBackFromImport = () => ({
  type: addPartnersCnts.GO_BACK_FROM_IMPORT
});

export enum sendAssetsCnts {
  SET_DEFAULTS = '@send-assets/SET_DEFAULTS',
  GET_TEMPLATES = '@send-assets/GET_TEMPLATES',
  GET_TEMPLATES_STARTED = '@send-assets/GET_TEMPLATES_STARTED',
  GET_TEMPLATES_ERROR = '@send-assets/GET_TEMPLATES_ERROR',
  GET_TEMPLATES_SUCCESS = '@send-assets/GET_TEMPLATES_SUCCESS',
  GET_MM = '@send-assets/GET_MM',
  GET_MM_ERROR = '@send-assets/GET_MM_ERROR',
  GET_MM_SUCCESS = '@send-assets/GET_MM_SUCCESS',
  SET_ASSETS = '@send-assets/SET_ASSETS',
  SET_ASSETS_SETTINGS = '@send-assets/SET_ASSETS_SETTINGS',
  SET_MSG_BODY = '@send-assets/SET_MSG_BODY',
  SET_MSG_IMAGE = '@send-assets/SET_MSG_IMAGE',
  SET_SUBJECT = '@send-assets/SET_SUBJECT',
  TOGGLE_PERSONALIZE_GREETING = '@send-assets/TOGGLE_PERSONALIZE_GREETING',
  SET_GREETING = '@send-assets/SET_GREETING',
  SELECT_ALL_PARTNERS_CONTACTS = '@send-assets/SELECT_ALL_PARTNERS_CONTACTS',
  SELECT_PARTNER = '@send-assets/SELECT_PARTNER',
  SELECT_CONTACT = '@send-assets/SELECT_CONTACT',
  SEND_ASSETS = '@send-assets/SEND_ASSETS',
  SEND_ASSETS_S3UPLOAD_SUCCESS = '@send-assets/SEND_ASSETS_S3UPLOAD_SUCCESS',
  SEND_ASSETS_SUCCESS = '@send-assets/SEND_ASSETS_SUCCESS',
  SEND_ASSETS_ERROR = '@send-assets/SEND_ASSETS_ERROR',
  SEND_TEST_EMAIL = '@send-assets/SEND_TEST_EMAIL',
  SEND_TEST_EMAIL_S3UPLOAD_SUCCESS = '@send-assets/SEND_TEST_EMAIL_S3UPLOAD_SUCCESS',
  SEND_TEST_EMAIL_SUCCESS = '@send-assets/SEND_TEST_EMAIL_SUCCESS',
  SEND_TEST_EMAIL_ERROR = '@send-assets/SEND_TEST_EMAIL_ERROR',
  CLEAR_STORE = '@send-assets/CLEAR_STORE',
  TOGGLE_TEST_EMAIL_SENT = '@send-assets/TOGGLE_TEST_EMAIL_SENT',
  DISMISS_ERROR = '@send-assets/DISMISS_ERROR',
  SELECT_RECIPIENTS_AFTER_IMPORT = '@send-assets/SELECT_RECIPIENTS_AFTER_IMPORT',
  CLEAR_IMPORTED_FILE = '@send-assets/CLEAR_IMPORTED_FILE'
}

export const getTemplates = () => ({
  type: sendAssetsCnts.GET_TEMPLATES
});

export const getMM = () => ({
  type: sendAssetsCnts.GET_MM
});

export const setAssets = (assets: Assets) => ({
  type: sendAssetsCnts.SET_ASSETS as sendAssetsCnts.SET_ASSETS,
  payload: { assets }
});

export const setAssetsSettings = (settings: {
  folders?: String[];
  doNotNotify?: boolean;
  messageForDL?: string;
  rateConversionParty?: string;
}) => ({
  type: sendAssetsCnts.SET_ASSETS_SETTINGS as sendAssetsCnts.SET_ASSETS_SETTINGS,
  payload: settings
});

export const setSubject = (subject: string) => ({
  type: sendAssetsCnts.SET_SUBJECT as sendAssetsCnts.SET_SUBJECT,
  payload: subject
});

export const togglePersonalizeGreeting = () => ({
  type: sendAssetsCnts.TOGGLE_PERSONALIZE_GREETING as sendAssetsCnts.TOGGLE_PERSONALIZE_GREETING
});

export const setGreeting = (greeting: string) => ({
  type: sendAssetsCnts.SET_GREETING as sendAssetsCnts.SET_GREETING,
  payload: greeting
});

export const setMsgBody = (str: string, editorState: EditorState) => ({
  type: sendAssetsCnts.SET_MSG_BODY as sendAssetsCnts.SET_MSG_BODY,
  payload: {
    messageHtml: str,
    editorState
  }
});

export const setMsgImage = (file: File) => ({
  type: sendAssetsCnts.SET_MSG_IMAGE as sendAssetsCnts.SET_MSG_IMAGE,
  payload: {
    file
  }
});
// for send-assets
export const selectAllPartnersContacts = ({
  partners,
  checked
}: {
  partners: PartnerType[];
  checked: boolean;
}) => ({
  type: sendAssetsCnts.SELECT_ALL_PARTNERS_CONTACTS as sendAssetsCnts.SELECT_ALL_PARTNERS_CONTACTS,
  payload: { partners, checked }
});

export const selectPartner = (partner: PartnerType, checked: boolean) => ({
  type: sendAssetsCnts.SELECT_PARTNER as sendAssetsCnts.SELECT_PARTNER,
  payload: {
    partner,
    checked
  }
});

export const selectContact = (
  partner: PartnerType,
  contact: ContactType,
  checked: boolean
) => ({
  type: sendAssetsCnts.SELECT_CONTACT as sendAssetsCnts.SELECT_CONTACT,
  payload: {
    partner,
    contact,
    checked
  }
});

export type setDefaultsPayload = {
  go_back_to?: string;
  recipients?: RecipientType[];
  openpanel?: string;
  rates?: RateTemplateAsset;
  subject?: string;
  messageHtml?: string;
  defaultMessage?: string;
  defaultSignature?: string;
  defaultOpenAssetType?: keyof Assets;
  doNotNotify?: boolean;
  rateConversionParty?: string;
};

export const setDefaults = (query: setDefaultsPayload) => ({
  type: sendAssetsCnts.SET_DEFAULTS as sendAssetsCnts.SET_DEFAULTS,
  payload: query
});

export const sendAssets = () => ({
  type: sendAssetsCnts.SEND_ASSETS as sendAssetsCnts.SEND_ASSETS
});
export const sendTestEmail = () => ({
  type: sendAssetsCnts.SEND_TEST_EMAIL as sendAssetsCnts.SEND_TEST_EMAIL
});
export const clearStore = () => ({
  type: sendAssetsCnts.CLEAR_STORE as sendAssetsCnts.CLEAR_STORE
});
export const toggleTestEmailSent = () => ({
  type: sendAssetsCnts.TOGGLE_TEST_EMAIL_SENT as sendAssetsCnts.TOGGLE_TEST_EMAIL_SENT
});
export const dismissError = () => ({
  type: sendAssetsCnts.DISMISS_ERROR as sendAssetsCnts.DISMISS_ERROR
});

export const clearImportedFile = () => ({
  type: sendAssetsCnts.CLEAR_IMPORTED_FILE as sendAssetsCnts.CLEAR_IMPORTED_FILE
});
