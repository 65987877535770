import { cnts, ThisActionType } from './root-actions';
import { UserType, UserDbType, UserReducerType, LocationType, LocAccess, BillingSubscriptionType } from 'AllTypes';

export interface accountState {
  accountLoaded: boolean;
  settingsLoaded: boolean;
  user: UserReducerType;
  company: {
    allUsers?: Array<UserType> | [];
    usersArray?: Array<UserDbType> | [];
    companyType?: string;
    tourplan?: {
      password?: string;
      user_name?: string;
      url?: string;
    };
    companyName?: string;
    companyId?: string;
    plan?: string;
    oneOrMultiProperties?: string;
    profileImageUrl?: string;
    billingEnabled?: boolean;
  };
  allApps?: string[];
  locAccess: LocAccess[];
  corporateAccess: string[];
  corporateContractingAccess: string[];
  corporateAdminAccess: string[];
  mrLocations: LocationType[];
  allLocations: LocationType[];
  isAnAdmin?: boolean;
  rateTemplatesCount?: number;
  billing: {
    billingSubscriptions: BillingSubscriptionType[];
    bookingFreeTrialSub?: BillingSubscriptionType;
    emailAssistFreeTrialSub?: BillingSubscriptionType;
    providerCustomerId?: string;
  }
}

const initialState = {
  accountLoaded: false,
  settingsLoaded: false,
  user: {
    firstName: '',
    userId: '',
    id: '',
    type: '',
    emailSignature: '',
    UIpreferences: {},
  },
  company: {},
  locAccess: [],
  corporateAccess: [],
  corporateContractingAccess: [],
  corporateAdminAccess: [],
  mrLocations: [],
  allLocations: [],
  billing: {
    billingSubscriptions: [],
  },
};

export default function account(
  state: accountState = initialState,
  action: ThisActionType
) {
  switch (action.type) {
    case cnts.GET_ACCOUNT_INFO_SUCCESS: {
      let emailSignature = action.payload.user.emailSignature;
      const {
        user: { firstName },
        company: { companyName, profileImageUrl }
      } = action.payload;
      if (!emailSignature) {
        emailSignature = `<p><br><br>Thanks,<br><br>${
          firstName ? `${firstName}<br>` : ''
        }${companyName || ''}<div style="text-align:left;"><img src="${profileImageUrl}" style="height:100px;width:100px;"/></div>
        </p>
        `
      }
      return {
        ...state,
        ...action.payload,
        company: {
          ...state.company,
          ...action.payload.company
        },
        user: {
          ...state.user,
          ...action.payload.user,
          userId: action.payload.user.id,
          type: action.payload.user.type,
          emailSignature,
        },
        accountLoaded: true
      };
    }
    case cnts.HYDRATE_SETTINGS_SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          allUsers: action.payload.allActiveUsers,
          usersArray: action.payload.usersArray,
        },
        settingsLoaded: true,
      };
    case cnts.GET_BILLING_INFO_SUCCESS:
      return {
        ...state,
        billing: action.payload,
      };
    default:
      return state;
  }
}
