import React from 'react';
import * as R from 'ramda';
import TextField from '../text-field';
import Autocomplete from '@mui/material/Autocomplete';

export interface OptionType {
  label: string;
  value: string;
  original?: any;
  isDisabled?: boolean;
}

export type SelectValueType = OptionType | null | undefined;
interface PropsType {
  id?: string;
  label?: string;
  placeholder?: string;
  value?: SelectValueType;
  defaultValue?: SelectValueType,
  options: OptionType[];
  onChange: (newValue: SelectValueType) => void;
  groupBy?: () => string;
  disableClearable?: boolean;
  required?: boolean;
  disabled?: boolean;
  getOptionLabel?: (option: OptionType) => string;
}

const SingleSelect = (props: PropsType) => {
  return (
    <Autocomplete
      id={props.id || 'single-select'}
      disabled={props.disabled}
      disableClearable={props.disableClearable}
      defaultValue={props.defaultValue}
      options={props.options}
      size="small"
      color="secondary"
      value={props.value}
      getOptionLabel={props.getOptionLabel || (option => {
        if (typeof option === 'string') return option;
        return option.label || option.value || '';
      })}
      isOptionEqualToValue={(option, selected) => {
        if (typeof selected === 'string') return option.value === selected;
        return R.eqProps('value', option, selected || {});
      }}
      getOptionDisabled={option => Boolean(option.isDisabled)}
      groupBy={props.groupBy}
      style={{ flexGrow: 1 }}
      onChange={(event: any, newValue: SelectValueType) => {
        props.onChange(newValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          required={props.required}
          label={props.label}
          variant="outlined"
          placeholder={props.placeholder}
        />
      )}
    />
  );
};

export default SingleSelect;
