import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider
} from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import moment from 'moment';
import { defaultTheme } from '../../utils/mui-themes';
import { navLang } from '../../utils/date';

const BookingsAISignUp = React.lazy(() =>
  import('./bookings-ai/index')
);
const GenerativeAISignUp = React.lazy(() =>
  import('./generative-ai/index')
);

const theme = createTheme(defaultTheme);
class SignUpApp extends Component {
  componentDidMount() {
    if (navLang) {
      moment.locale(navLang);
    }
  }
  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <React.Fragment>
            <CssBaseline />
            <div
              style={{
                backgroundColor: '#efefef',
                width: '100%',
                margin: 'auto',
                padding: '5em 0',
                height: '100vh',
                fontFamily:
                  "'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif"
              }}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route
                    path="/bookings-ai-sign-up"
                    component={BookingsAISignUp}
                  />
                  <Route
                    path="/generative-ai-sign-up/:genAiProduct"
                    component={GenerativeAISignUp}
                  />
                </Switch>
              </Suspense>
            </div>
          </React.Fragment>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

const mapActions = {};

const mapState = ({ account: { accountLoaded } }) => ({
  accountLoaded
});

export default hot(module)(connect(mapState, mapActions)(SignUpApp));
