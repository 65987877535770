import React from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

type Props = {
  value?: any;
  label: string | number | React.ReactElement;
  color?: 'primary' | 'secondary' | 'default' | undefined;
  labelPlacement?: 'start' | 'top' | 'end' | 'bottom';
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<{}>, checked?: boolean) => void;
  disabled?: boolean;
};

export default function RadioButton(props: Props) {
  const {
    value,
    label,
    labelPlacement,
    onChange,
    checked,
    color,
    disabled
  } = props;

  return (
    <FormControlLabel
      checked={checked}
      value={value}
      control={<Radio color={color || 'primary'} sx={{ padding: '2px' }} />}
      sx={{
        marginLeft: '-7px',
        '& .MuiFormControlLabel-label': {
          fontSize: '12px',
        },
      }}
      label={label}
      labelPlacement={labelPlacement || 'end'}
      onChange={onChange}
      disabled={disabled}
    />
  );
}
