import { cnts, ThisActionType } from './hello-actions';

const initialState = {
  defaultText: 'hello',
  text: 'hello',
  beforeRevert: ''
};

export default function hello(state = initialState, action: ThisActionType) {
  switch (action.type) {
    case cnts.RESET:
      return {
        ...state,
        beforeRevert: state.text,
        text: state.defaultText
      };
    case cnts.REVERT:
      return {
        ...state,
        beforeRevert: state.text,
        text: state.beforeRevert
      };
    case cnts.TF_CHANGE:
      return {
        ...state,
        text: action.payload
      };
    default:
      return state;
  }
}
