import React  from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import { useParams } from 'react-router-dom';

const SearchResultsHeader = ({
  searchResults,
  searching,
}) => {
  const { searchString } = useParams();

  if (searching) {
    return (<div>Searching <strong>${searchString}</strong>...</div>    );
  }
  const rows = [];
  if (Object.values(searchResults).find(e => e.length > 0)) {
    Object.entries(searchResults).forEach(([type, data]) => {
      data.forEach(item => {
        rows.push({
          type,
          ...item,
        });
      });
    });
  }
  return (<div>{rows.length} results for <strong>{searchString}</strong></div>);
};

const mapState = ({ admin: {
  searchResults,
  searching,
} }) => ({
  searchResults,
  searching,
});

export default hot(module)(connect(mapState, {})(SearchResultsHeader));
