import { takeLatest, put, select, all } from 'redux-saga/effects';
import queryString from 'query-string';
import { assetsLanding as apis } from '../../../api';
import { apiSaga } from '../../../root-sagas';

function* getSupplierDataForALRequest() {
  const { senderId } = yield select(state => state.AL.metaData);
  yield* apiSaga(
    'AL_getSupplierDataForALRequest',
    apis.getSupplierDataForALRequest,
    [{ buyerId: senderId }]
  );
}

function* getRequest(requestId) {
  yield* apiSaga('AL_getContractRequestInfo', apis.getContractRequestInfo, [
    { requestId }
  ]);
}

function* getRespondedContracts(requestId) {
  yield* apiSaga('AL_getRespondedContracts', apis.getRespondedContracts, [
    { requestId }
  ]);
}

const showCESsurvey = rateFormat =>
  function*() {
    const { email } = yield select(state => state.AL.metaData);
    // eslint-disable-next-line no-undef
    delighted.survey({
      email
    });
  };

function* onSubmitTCRatesSuccess() {
  const { requestId } = yield select(state => state.AL.request);
  yield all([
    getSupplierDataForALRequest(),
    getRequest(requestId),
    getRespondedContracts(requestId),
    showCESsurvey('TC')()
  ]);
}

function* onSubmitExternalRatesSuccess() {
  yield showCESsurvey('External')();
}

function* switchRequest() {
  const { requestId } = yield select(state => state.AL.request);
  yield all([getRequest(requestId), getRespondedContracts(requestId)]);
}

function* authAL({
  payload: {
    location: { pathname, search }
  }
}) {
  if (pathname.indexOf('assets-landing') > -1) {
    const query = queryString.parse(search || '');
    if (query.hashToken) sessionStorage.setItem('hashtoken', query.hashToken);
    yield put({ type: 'AL_INIT', payload: query });
    yield apiSaga('AL_retrieveTokenPayload', apis.retrieveTokenPayload, [
      query.hashToken
    ]);
  }
}

function* loadData({ payload: { contractIds } }) {
  if (window.location.pathname.indexOf('-request') > -1) {
    const { requestId } = yield select(state => state.AL.request);
    const id = requestId || contractIds[0];
    if (!requestId) {
      yield put({ type: 'AL_updateRequestIdIfNotInQuery', payload: { id } });
    }
    yield all([
      getSupplierDataForALRequest(),
      getRespondedContracts(id),
      apiSaga('AL_getBuyerDummyData', apis.getBuyerDummyData, [
        { requestId: id }
      ]),
      // get all request data
      ...contractIds.map(getRequest)
    ]);
    yield put({ type: 'AL_finishedLoading' });
  }
}
function* doSubmitTCRates({ type, payload }) {
  const {
    request: { requestId, to, startDate, endDate }
  } = yield select(state => state.AL);
  const body = {
    ...payload,
    requestId,
    subject: `${to.name} ${
      new Date(startDate).getFullYear() === new Date(endDate).getFullYear()
        ? `${new Date(endDate).getFullYear()}`
        : `${new Date(startDate).getFullYear()}-${new Date(
            endDate
          ).getFullYear()}`
    } Rates`
  };
  yield apiSaga(type, apis.submitTCRates, [body]);
}

function* doCreateRateSheet() {
  const {
    supplier: {
      supplierId,
      productType,
      oneOrMulti,
      locations,
      draftRateType,
      draftCommissionPercentage,
      draftNettCommission,
      sourceTemplates
    },
    request
  } = yield select(state => state.AL);
  const newDraftStartDate = request.startDate;
  const newDraftEndDate = request.endDate;
  const payload = {
    supplierCompanyName: request.to.name,
    supplierId,
    productType,
    oneOrMulti,
    newDraftStartDate,
    newDraftEndDate,
    draftRateType,
    draftCommissionPercentage,
    draftNettCommission,
    sourceTemplates,
    templateName: `${
      new Date(newDraftStartDate).getFullYear() ===
      new Date(newDraftEndDate).getFullYear()
        ? `${new Date(newDraftEndDate).getFullYear()}`
        : `${new Date(newDraftStartDate).getFullYear()}-${new Date(
            newDraftEndDate
          ).getFullYear()}`
    } Rates`,
    // if importing from buyer
    // we will generate locations based on the templates user selected
    locations: sourceTemplates.length
      ? sourceTemplates.map(t => {
          const retVal = {
            name: t.locationName,
            checked: true,
            products: []
          };
          if (!t.isBuyerRates) retVal.locationId = t.locationId;
          return retVal;
        })
      : locations
  };
  yield apiSaga('AL_createRateSheets', apis.createRateSheets, [payload]);
}

// This could comes from power user dialog or from the contract list directly
function* createDraftForContractUpdates({ type, payload }) {
  const {
    powerUserEdit: { template, contract }
  } = yield select(state => state.AL);
  yield apiSaga(type, apis.createEditDraft, [
    {
      templateName: payload.templateName,
      templateId: (payload.template || template)._id,
      contractId: (payload.contract || contract)._id
    }
  ]);
}

// assumption: supplier should have an account for this function
function* createNewAndImportForEdit({ type, payload: { templateName } }) {
  const {
    request,
    supplier,
    powerUserEdit: { template }
  } = yield select(state => state.AL);
  const body = {
    supplierId: supplier.supplierId,
    newDraftStartDate: request.startDate,
    newDraftEndDate: request.endDate,
    draftRateType: template.rateType,
    draftCommissionPercentage: template.commissionPercentage,
    draftNettCommission: template.nettCommission,
    sourceTemplates: [template],
    templateName,
    locations: [
      {
        locationId: template.locationId,
        checked: true,
        name: template.locationName,
        products: []
      }
    ]
  };
  yield apiSaga(type, apis.createRateSheets, [body]);
}

function* doSendExternalRates({ type, payload }) {
  const {
    metaData: { email },
    request: { requestId, to, startDate, endDate },
    supplier: { userId }
  } = yield select(state => state.AL);
  const body = {
    files: payload.files,
    message: payload.message,
    requests: [{ _id: requestId }],
    fromEmail: email,
    userId,
    subject: `${to.name} ${
      new Date(startDate).getFullYear() === new Date(endDate).getFullYear()
        ? `${new Date(endDate).getFullYear()}`
        : `${new Date(startDate).getFullYear()}-${new Date(
            endDate
          ).getFullYear()}`
    } Rates`
  };
  yield apiSaga(type, apis.sendExternalRates, [body]);
}

function* doSupplierSignUp({ type }) {
  const { password, companyName } = yield select(
    state => state.AL.supplierSignUp
  );
  yield apiSaga(type, apis.supplierSignup, [{ password, companyName }]);
}
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest('@@router/LOCATION_CHANGE', authAL),
  takeLatest('AL_retrieveTokenPayload_SUCCESS', loadData),
  takeLatest('AL_reloadRateSheets', getSupplierDataForALRequest),
  takeLatest('AL_closeIframe', getSupplierDataForALRequest),
  takeLatest('AL_submitTCRates', doSubmitTCRates),
  takeLatest('AL_createRateSheets', doCreateRateSheet),
  takeLatest('AL_submitTCRates_SUCCESS', onSubmitTCRatesSuccess),
  takeLatest('AL_switchRequest', switchRequest),
  takeLatest(
    [
      'AL_createRateSheets_SUCCESS',
      'AL_createNewAndImportForEdit_SUCCESS',
      'AL_createDraftForContractUpdates_SUCCESS'
    ],
    getSupplierDataForALRequest
  ),
  takeLatest('AL_createDraftForContractUpdates', createDraftForContractUpdates),
  takeLatest('AL_createNewAndImportForEdit', createNewAndImportForEdit),
  takeLatest('AL_submitExternalRates', doSendExternalRates),
  takeLatest('AL_submitExternalRates_SUCCESS', onSubmitExternalRatesSuccess),
  takeLatest('AL_requestSupplierDoSignUp', doSupplierSignUp)
];
