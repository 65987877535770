import queryString from 'query-string';
import {
  getContractRequestQForAL,
  getBuyerDummyDataForAL
} from './graphql-queries.js';

const API_URL = process.env.REACT_APP_API_URL;
const CONTENTFUL_HELPSIDEBAR = process.env.REACT_APP_CONTENTFUL_HELPSIDEBAR;
const FILEMATCH_URL = process.env.REACT_APP_FILEMATCH_URL;
const TI2_URL = process.env.REACT_APP_TI2_URL;
const API_CONTENTFUL = 'https://cdn.contentful.com/';

const jsonDecode = async response => {
  try {
    const jsonRet = await response.json();
    return jsonRet;
  } catch (jsonErr) {
    return {};
  }
}

const callApi = async (endpoint, method = 'get', bodyParams = null, token = null) => {
  let fullUrl = (/^https?:\/\/*/.test(endpoint)) ? endpoint : `${API_URL}${endpoint}`;
  const body = (method.toLowerCase() !== 'get' && bodyParams) ? JSON.stringify(bodyParams) : null;
  if (method.toLowerCase() === 'get' && bodyParams) {
    const params = new URLSearchParams(bodyParams);
    if (params.toString()) {
      fullUrl = `${fullUrl}?${params.toString()}`;
    }
  }
  const jwt = token || localStorage.getItem('jwt') || '';
  let response;
  try  {
    response = await fetch(fullUrl, {
      method,
      // credentials: 'same-origin',
      // mode: 'no-cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(jwt ? { Authorization: `Bearer ${jwt}` } : {}),
      },
      body
    });
  } catch (err) {
  console.log("err", err);
    response = err;
  }
  const json = await jsonDecode(response);
  if (!response.ok) {
    if (json.code === 'InvalidCredentials') {
      if (json.message.indexOf('jwt') > -1) {
        localStorage.removeItem('jwt');
      }
      window.location.assign(
        `${process.env.REACT_APP_OLD_APP_URL}/signin?auth=true&reauth=true&redirect_to=${window.location.href}`
      );
    }
    return {
      error: {
        response,
        json,
        request: {
          url: fullUrl,
          body,
          method,
        },
      },
    };
  }
  const newToken = response.headers.get('new-token');
  if (Boolean(newToken)) localStorage.setItem('jwt', newToken);

  return { response: json };
};
const callTi2Api = async (ti2token, endpoint, method = 'get', bodyParams = null) =>
  callApi(`${TI2_URL}${endpoint}`, method, bodyParams, ti2token);

const callFileMatchApi = async (endpoint, method = 'get', bodyParams = null) => {
  return callApi(`${FILEMATCH_URL}${endpoint}`, method, bodyParams);
};
export const callApiForAL = async (endpoint, method = 'get', bodyParams = null) => {
  const fullUrl = /^https?:\/\/*/.test(endpoint) ? endpoint : API_URL + endpoint;
  const body = bodyParams ? JSON.stringify(bodyParams) : null;
  const hashtoken = sessionStorage.getItem('hashtoken') || '';
  let response;
  try  {
    response = await fetch(fullUrl, {
      method,
      // credentials: 'same-origin',
      // mode: 'no-cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        hashtoken
      },
      body
    });
  } catch (err) {
    response = err;
  }
  const json = await jsonDecode(response);
  if (!response.ok) {
    return {
      error: {
        response,
        json,
        request: {
          url: fullUrl,
          body,
          method,
        },
      },
    };
  }
  return { response: json };
}
// api services
export const account = {
  getAccountInfo: () => callApi(`/users`, 'get'),
  hydrateSettings: () => callApi('/settings/hydrate', 'get'),
  updateUser: payload => callApi('/user', 'put', payload),
  omniSearch: payload => callApi('/omni-search', 'post', payload),
  updateUIpreferences: payload => callApi('/updateUIpreferences', 'put', payload),
  signUpBookingAiUser: payload => callApi('/signUpBookingAiUser', 'post', payload),
  signUpGenerativeAiUser: payload => callApi('/signUpGenerativeAiUser', 'post', payload),
  // check if email is registered
  decryptEmail: payload => callApi(`/decryptEmail/${payload.email}`, 'get'),
  getBillingInfo: () => callApi(`/billing`),
  createSubscriptions: payload => callApi('/billing/subscriptions', 'post', payload),
  cancelSubscriptions: payload => callApi('/billing/subscriptions', 'delete', payload),
  getCouponInfo: ({ couponCode }) => callApi(`/coupon/${couponCode}`),
  joinAffiliate: () => callApi('/affiliate', 'post', {}),
  resetPassword: payload => callApi('/settings/reset', 'put', payload),
  updateUserSettings: payload => callApi('/settings/saveEditedUser', 'put', payload),
  deleteUser: payload => callApi(`/userManagement/editUser/${payload.userId}`, 'delete'),
  addUser: payload => callApi('/userManagement/addUser', 'put', payload),
  updatePaymentMethod: payload => callApi('/billing/source', 'put', payload),
  getInvoices: () => callApi(`/billing/invoices`, 'get'),
};

export const generativeAi = {
  getAllCategories: () => {
    return callFileMatchApi('/generative-ai-asset-categories', 'get');
  },
  getAllAssets: ({ contentType }) => {
    return callApi(`/generative-ai/listContent/${contentType || 'vectorstore'}`, 'get');
  },
  uploadAsset: payload => {
    return callApi('/generative-ai/uploadContent', 'post', payload);
  },
  updateAsset: payload => {
    return callApi('/generative-ai/updateContent', 'put', payload);
  },
  deleteAsset: payload => {
    return callApi('/generative-ai/deleteContent', 'delete', payload);
  },
  getItineraries: payload => {
    return [];
  },
  getItinerary: id => {
    return [];
  },
  deleteItineray: id => {
    return [];
  },
  saveItinerary: payload => {
    return [];
  },
  saveAISettings: payload => {
    return callApi('/addin/saveSettings', 'post', payload);
  },
  sendPrompt: payload => {
    return callFileMatchApi('/vectorQA', 'post', payload);
  },
  vectorProcess: payload => callFileMatchApi('/vectorProcess', 'post', payload),
  fileProcess: payload => callFileMatchApi('/attachment/llmFileProcess', 'post', payload),
  checkVectorJobStatus: payload => callFileMatchApi(`/vectorProcess/status`, 'post', payload),
  generateQuote: payload => {
    return callFileMatchApi('/vectorQA', 'post', payload);
  },
  getPromptLibrary: params => callFileMatchApi(`/promptLibrary${params ? `?${queryString.stringify(params)}` : ''}`, 'get'),
  getChatThreads: emailIdOrTool => callApi(`/generative-ai/${emailIdOrTool}/chat-threads`, 'get'),
  openaiAssistant: payload => callFileMatchApi('/openaiAssistant', 'post', {
    ...payload,
    backendJwt: localStorage.getItem('jwt'),
  }),
  checkOpenaiRunStatusAndMessages: payload => callFileMatchApi('/openaiAssistant/status', 'post', {
    ...payload.jobs,
    backendJwt: localStorage.getItem('jwt'),
  }),
  deassocChatThread: tcThreadId => callApi(`/generative-ai/chat-thread/${tcThreadId}`, 'delete'),
  getNotInternetEnabledOptions: payload => callFileMatchApi('/getNotInternetEnabledOptions', 'post', payload),
  createRule: payload => callFileMatchApi('/ai-rules', 'post', payload),
  getStagingAIRules: payload => {
    return callFileMatchApi('/staging-ai-rules', 'get', payload);
  },
  updateStagingAIRules: payload => {
    return callFileMatchApi('/staging-ai-rules', 'put', payload);
  },
  getLLMParser: payload => callFileMatchApi(`/LLMParser?toolId=${payload.toolId}&partnerId=${payload.partnerId}`, 'get'),
  saveLLMParser: payload => callFileMatchApi('/LLMParser', 'post', payload),
};

export const aiRules = {
  getAliases: () => callApi('/addin/alias', 'get'),
  deleteAlias: payload => callApi('/addin/alias', 'delete', payload),
  addAlias: payload => callApi('/addin/alias', 'post', payload),
  createRule: payload => callFileMatchApi('/ai-rules', 'post', payload),
  updateRule: ({ id, ...payload }) => callFileMatchApi(`/ai-rules/${id}`, 'put', payload),
  getAIRules: () => callFileMatchApi('/ai-rules', 'get'),
  deleteAIRule: id => callFileMatchApi(`/ai-rules/${id}`, 'delete'),
  productRuleEval: payload => callFileMatchApi('/evalMatch', 'post', payload),
}

export const ti2 = {
  getAllAppKeys: ({ companyId, ti2token }) => callTi2Api(ti2token, `/user/${companyId}/apps`, 'get'),
  getAppKey: ({ companyId, app, hint, ti2token }) => callTi2Api(ti2token, `/${app}/${companyId}/${hint}/token`, 'get'),
  updateAppKey: ({ companyId, app, hint, ti2token, configuration }) => callTi2Api(ti2token, `/${app}/${companyId}`, 'put', { hint, configuration }),
  getProducts: ({ companyId, ti2token, app, hint, searchInput, optionId }) => callTi2Api(ti2token, `/products/${app}/${companyId}/${hint}/search`, 'post', {
    searchInput,
    optionId,
  }),
}
export const matcher = {
  checkStatus: payload => callFileMatchApi(`/mail/jobStatus?jobId=${payload.jobs[0].jobId}`, 'post', payload),
};

export const notifications = {
  getStatusMessage: () => callApi('/statusMessages'),
  getNFNotifications: () => callApi('/notifications'),
  markNFAsRead: activityIds =>
    callApi('/notifications', 'put', { activityIds }),
  acceptConnection: connectionId =>
    callApi(`/connections/${connectionId}/accepted`, 'put'),
  rejectConnection: connectionId =>
    callApi(`/connections/${connectionId}/rejected`, 'put'),
  markAcceptedRead: connectionIds =>
    callApi('/statusMessages/markConnectionAcceptedAsRead', 'post', {
      connectionIds
    }),
  sendNotification: payload => callApi('/sendNotification', 'post', payload)
};
export const partnerManagement = {
  getTags: () => callApi('/partner-management/tags', 'get'),
  saveTagChanges: (payload = {}) =>
    callApi('/partner-management/tags', 'post', {
      ...payload,
      items: payload.items.map(item => ({
        ...item,
        partnerId: item.type === 'partner' ? item.id : undefined,
        contactId: item.type === 'contact' ? item.id : undefined
      }))
    }),
  getFields: () => callApi('/partner-management/fields', 'get'),
  getPartners: (searchParams = {}) =>
    callApi('/partner-management/partners', 'post', searchParams),
  getContacts: (searchParams = {}) =>
    callApi('/partner-management/contacts', 'post', searchParams),
  createOnePartner: (payload = {}) =>
    callApi('/partner-management/partner', 'post', payload),
  mergePartners: (payload = {}) =>
    callApi('/partner-management/merge-partners', 'put', payload),
  archivePartners: ({ partnerIds, archiveContactsBool }) =>
    callApi('/partner-management/archive-partner', 'put', {
      partnerIds,
      archiveContactsBool
    }),
  archiveContacts: ({ contactIds }) =>
    callApi('/partner-management/archive-contacts', 'put', {
      contactIds
    }),
  unarchivePartner: ({ partnerId }) =>
    callApi('/partner-management/unarchive-partner', 'put', { partnerId }),
  unarchiveContact: ({ contactId }) =>
    callApi('/partner-management/unarchive-contact', 'put', { contactId }),
  exportCSV: ({ items, headers }) =>
    callApi('/partner-management/export', 'post', { items, headers }),
  importFile: payload =>
    callApi('/partner-management/import', 'post', payload),
  sendAssets: payload =>
    callApi('/partner-management/send-assets', 'post', payload),
  getBounceList: () =>
    callApi('/partner-management/bounce-list', 'get'),
  markDoneFromBounceList: payload =>
    callApi('/partner-management/bounce-list', 'put', { contactIds: payload.contactIds }),
  createNewProperty: payload =>
    callApi('/partner-management/settings/property', 'post', payload),
  updateProperty: payload =>
    callApi('/partner-management/settings/property', 'put', payload),
  archiveProperty: payload =>
    callApi('/partner-management/settings/property', 'delete', payload),
  createNewTemplate: payload =>
    callApi('/partner-management/message-templates', 'post', payload),
  getAllTemplates: () =>
    callApi('/partner-management/message-templates', 'get'),
  updateATemplate: payload =>
    callApi('/partner-management/message-templates', 'put', payload),
  deleteATemplate: payload =>
    callApi('/partner-management/message-templates', 'delete', payload),
  updateSignature: payload =>
    callApi('/partner-management/message-signature', 'put', payload),
};

export const partner = {
  getPartner: ({ partnerId }) => callApi(`/partner/${partnerId}`, 'get'),
  updatePartnerDetails: ({ partnerId, ...extra }) =>
    callApi(`/partner/${partnerId}`, 'put', extra),
  getActivities: ({ assetId, activityType }) =>
    callApi(
      `/asset-activities/${assetId}?activityType=${activityType || ''}`,
      'get'
    ),
  addConnectionAsPartner: ({ connectionId, targetCompanyId }) =>
    callApi(`/partner-management/partner/connection`, 'post', {
      connectionId,
      targetCompanyId
    }),
  saveContacts: (payload = {}) =>
    callApi(`/partner/${payload.partnerId}/contacts`, 'put', {
      contacts: payload.contacts,
      deletedContacts: payload.deletedContacts
    }),
  unassocAMessage: ({ messageId, assetId }) =>
    callApi(`/asset-activity/${messageId}`, 'put', { assetId })
};

export const contact = {
  getContact: ({ contactId }) =>
    callApi(`/partner-management/contact/${contactId}`, 'get'),
  updateContactDetails: ({ contactId, ...payload }) =>
    callApi(`/partner-management/contact/${contactId}`, 'put', payload),
  associatePartner: payload =>
    callApi(`/partner-management/contact/${payload.contactId}/associatePartner`, 'put', payload),
};

export const contracting = {
  getContracts: ({ query, includeWorkspacesIfBuyer = false }) => {
    const qs = query
      ? queryString.stringify({
          additionalQuery: JSON.stringify(query),
          includeWorkspacesIfBuyer
        })
      : '';
    return callApi(`/contracting/contracts?${qs}`);
  },
  contractFromHF: payload =>
    callApi(
      `contracting/contractIdFromHistoryFeedId/${payload.historyFeedId}`,
      'post',
      payload
    ),
  unassocSingleActivity: ({ feedIndex, historyFeedId }) =>
    callApi('/contracting/contract/unassocSingleActivity', 'delete', {
      feedIndex,
      historyFeedId
    }),
  getTemplates: additionalQuery =>
    callApi(
      `/contracting/templates?${
        additionalQuery
          ? queryString.stringify({
              additionalQuery: JSON.stringify(additionalQuery)
            })
          : ''
      }`
    ),
  getWorkspaceList: payload => callApi(`/contracting/workspace-list`, 'post', payload),
  getContractList: payload => callApi('/contracting/buyer-contract-list', 'post', payload),
  createFolder: payload => callApi('/contracting/contract-list/folder', 'post', payload),
  getAllFolders: () => callApi('/contracting/contract-list/folder', 'get'),
  // updatePartnerTags
  updateFolders: payload => callApi('/contracting/contract-list/folder', 'put', payload),
  deleteFolder: payload => callApi('/contracting/contract-list/folder', 'delete', payload),
  updateWorkspaceContract: payload => callApi('/contracting/workspace-contract', 'put', payload),
  assignFolders: payload => callApi('/contracting/assignFolder', 'put', payload),
  archiveOrUnArchive: payload => callApi('/contracting/archiveOrUnarchive', 'put', payload),
  deleteOrUndelete: payload => callApi('/contracting/deleteOrUndelete', 'put', payload),
  sendRequest: payload => callApi(`/contracting/resendContract/${payload.contractId}`, 'put', payload),
  bulkResendContracts: payload => callApi('/contracting/bulkResendContracts', 'put', payload),
  mergeWorkspaces: payload => callApi('/contracting/workspaces/merge', 'put', payload),
  loadDataForIframe: payload => {
    return callApi(`/contracting/getContractInfo/${payload.rateDocId}`, 'post', {
      query: `
          query getContract ($contractId: ID!, $collection: String!, $viewerId: ID!, $viewBy: String!, $realActorEmail: String) {
            DraftnTemplate (id: $contractId, collection: $collection, viewerId: $viewerId, viewBy: $viewBy) {
              completionPercent
              acceptedBy
              crIds
              ownerIds
              defaultInvitees
              magicLink (realActorEmail: $realActorEmail)
              signatures {
                 invitee
                 inviteSentAt
                 signedBy
                 signedAt
              }
              originFrom
              draftType
              editHistory {
                detail
                productId
                ratePackageName
              }
            }
          }
        `,
      contractId: payload.rateDocId,
      collection: payload.collection,
    });
  },
  getDiffReport: ({ docs, onlyGetSummary }) => callApi(`/contracting/report/diff/${docs}?onlyGetSummary=${onlyGetSummary}`, 'get'),
  acceptDummyTemplate: payload => callApi('/contracting/dummy/template/accept', 'put', payload),
  sendRCMsg: payload => callApi('/contracting/send-rc-msg', 'post', payload),
};

export const admin = {
  resendWorkspaceMessage: payload => callApi('/admin/resendWorkspaceMessage', 'put', payload),
  checkToken: ({ token }) => callApi('/admin/checkToken', 'post', { token }),
  search: ({
    token,
    searchString,
    searchCategory = 'All',
  }) =>  callApi(`/admin/search`, 'get', {
    searchString,
    searchCategory,
  }, token),
  getCompany: ({
    token,
    companyId,
  }) =>  callApi(`/admin/company/${companyId}`, 'get', {}, token),
  getLogs: ({
    token,
    id,
    type,
    queryParams
  }) =>  callApi(`/admin/logs/${type}/${id}`, 'get', queryParams, token),
};

export const travelML = {
  getStopSaleExports: payload =>
    callApi(
      `/addin/export?${payload ? queryString.stringify(payload) : ''}`,
      'get'
    ),
  getOfferExports: payload =>
    callApi(
      `/addin/exportOffer?${payload ? queryString.stringify(payload) : ''}`,
      'get'
    ),
  updateEntityLevels: payload => callApi(`/addin/levels`, 'put', payload),
  getAllCollection: ({ rowsPage, rowsPerPage, rowsEntityName }) => {
    const offset = rowsPage * rowsPerPage;
    const params = new URLSearchParams({
      offset,
      limit: rowsPerPage,
      ...(rowsEntityName ? { 'elementName.match': rowsEntityName } : {})
    });
    return callApi(`/addin/collection?${params.toString()}`, 'get');
  },
  addCollection: payload => callApi(`/addin/collection`, 'post', payload),
  removeCollectionItem: payload =>
    callApi(`/addin/collection`, 'delete', payload),
  updateACollectionRow: payload => callApi(`/addin/collection`, 'put', payload),
  deleteARowFromCollection: payload =>
    callApi(`/addin/collection`, 'delete', payload),
  retryStopSale: payload => callApi('/addin/retryStopSale', 'post', payload),
  lookupMeta: ({ value, parentId }) => {
    const params = new URLSearchParams({
      limit: 1,
      elementId: value,
      parentId
    });
    return callApi(`/addin/collection?${params.toString()}`, 'get');
  }
};

export const assetsLanding = {
  retrieveTokenPayload: hashToken =>
    callApiForAL(`/verifyToken/${hashToken}`, 'post', {}),
  getContractRequestInfo: ({ requestId }) => {
    return callApiForAL(`/landing/getContractInfo/${requestId}`, 'post', {
      query: getContractRequestQForAL,
      contractId: requestId,
      collection: 'contract'
    });
  },
  getBuyerDummyData: ({ requestId }) => {
    return callApiForAL(`/landing/getContractInfo/${requestId}`, 'post', {
      query: getBuyerDummyDataForAL,
      contractId: requestId,
      collection: 'contract'
    });
  },
  getSupplierDataForALRequest: payload =>
    callApiForAL(`/landing/getSupplierDataForAL`, 'post', payload),
  getRespondedContracts: payload =>
    callApiForAL(
      `/contracting/request/${payload.requestId}/contracts?al=true`,
      'get'
    ),
  createRateSheets: payload =>
    callApiForAL(`/landing/createRateSheets`, 'post', payload),
  submitTCRates: payload =>
    callApiForAL('/landing/submitTCRates', 'post', payload),
  createEditDraft: payload =>
    callApiForAL(`/contracting/draft/${payload.contractId}`, 'post', payload),
  sendExternalRates: payload =>
    callApiForAL('/landing/sendExternalRates', 'post', payload),
  supplierSignup: payload =>
    callApiForAL('/landing/supplierSignup', 'post', {
      password: payload.password,
      companyName: payload.companyName
    })
};

export const workspace = {
  conversationsRead: ({ assetId, assetType = 'partner' }) =>
    callApi(`/workspace/markRead`, 'post', { assetId, assetType }),
  addANote: ({ partnerIds, contactIds, messageText, files }) =>
    callApi(`/workspace/newMessage`, 'post', {
      partnerIds,
      contactIds,
      type: 'note',
      messageText,
      files
    })
};

export const MMQuery = `query getMyOwnMM ($companyId: ID!) {
  companyProfile  (id: $companyId) {
    media {
      url
      mediaType
      created_at
      name
      size
      storageProvider
      username
      password
      tags
    }
    locations {
      name
      id
      products {
        id
        name
      }
    }
  }
}`.replace(/ +(?= )/g, '');

export const marketing = {
  getMarketingMaterials: () => callApi(`/profile?query=${MMQuery}`)
};

export const downloads = {
  downloadTCContractingDoc: payload => {
    // window.open(fileUrls.getDownloadTCContractingDoc(payload));
    const downloadUrl = fileUrls.getDownloadTCContractingDoc(payload);
    const a = document.createElement('a');
    a.download = true;
    a.target = '_blank';
    a.setAttribute('download', true);
    a.href = downloadUrl;
    a.dispatchEvent(new MouseEvent('click'));
  },
  getXls: (docType, docId, exportType) => callApi(`contracting/exportXls/${docType}/${docId}?type=${exportType}`, 'get'),
};
export const fileUrls = {
  getDownloadUrlSync: path => {
    if (path && path.indexOf('/') > -1) return `/s3/file?key=${path}`;
    return `/filebyhash/${path}`;
  },
  getDownloadTCContractingDoc: ({ docId, collection = 'contract', locale, ratesOnly, downloadRateFormat, forAL }) => {
    let url;
    if (forAL) {
      url = (downloadRateFormat || '').split('_')[0] === 'quickview'
        ? '/landingproxy/contracting/pipeXls' : `/landingproxy/download-pdf`;
    } else {
      url = (downloadRateFormat || '').split('_')[0] === 'quickview'
        ? '/proxy/contracting/pipeXls' : `/proxy/download-pdf`;
    }
    const qs = queryString.stringify({
      docId,
      locale,
      collection,
      ratesOnly: downloadRateFormat === 'condensed' || ratesOnly,
      type: (downloadRateFormat || '').indexOf('quickview') > -1
        ? (downloadRateFormat || '').split('_')[1] : '',
    });
    return (`${url}?${qs}`);
  },
  downloadGroup: ({
    collection = 'contract',
    locale,
    downloadRateFormat,
    forAL,
    docIds,
  }) => {
    const qs = queryString.stringify({
      locale,
      collection,
      downloadRateFormat,
    });
    const url = `/contracting/download-group?${qs}`;
    const params = { docIds };
    const callFunc = forAL ? callApiForAL : callApi;
    return callFunc(url, 'post', params);
  },
  checkJobUrl: ({ jobUrl, forAL }) => {
    const callFunc = forAL ? callApiForAL : callApi;
    return callFunc(jobUrl, 'get');
  },
};

// Contentful for sidebar

const buildURLQuery = obj =>
  Object.entries(obj)
    .map(pair => pair.map(encodeURIComponent).join('='))
    .join('&');

function contentfulApi({
  accessToken = `${CONTENTFUL_HELPSIDEBAR}`,
  bodyParam = null,
  endpoint,
  environment = 'master',
  method = 'get',
  searchParams = {},
  space = 'wwevjgfp8hg4'
} = {}) {
  searchParams.access_token = accessToken;
  const fullUrl = `${API_CONTENTFUL}spaces/${space}/environments/${environment}/${endpoint}?${buildURLQuery(
    searchParams
  )}`;
  const body = bodyParam ? JSON.stringify(bodyParam) : null;
  return fetch(fullUrl, {
    method,
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body
  })
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      if (Array.isArray(json)) return json;
      return Object.assign({}, json);
    })
    .then(
      response => ({ response }),
      error => ({ error: error || 'Something bad happened' })
    );
}

export const contentful = {
  sidebarHelp: {
    getAllEntries: () =>
      contentfulApi({
        endpoint: 'entries',
        searchParams: {
          content_type: 'sidebarHelp',
          select: 'sys.id,fields.matchingUrls,fields.matchingRole,fields.title'
        }
      }),
    getHelp: articleId =>
      contentfulApi({
        endpoint: `entries`,
        searchParams: { content_type: 'sidebarHelp', 'sys.id': articleId }
      })
  }
};
