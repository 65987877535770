import { all, put, select, takeLatest } from 'redux-saga/effects';
import { apiSaga } from '../../../../root-sagas';
import {
  contracting as conApi,
  workspace as wpApi,
  partner as partnerApi,
  contact as contactApi,
  partnerManagement as pmApi,
} from '../../../../api';
import * as cnt from './constants.js';

const getRoute = state => state.router.location.pathname;

export function* refreshContactInfo() {
  const contactId = yield select(
    state => state.PM.contactDetails.contactInfo.id
  );
  yield* apiSaga(cnt.GET_CONTACT_INFO, contactApi.getContact, [{ contactId }]);
}

function* getContactDetails({
  payload: {
    location: { pathname }
  }
}) {
  if (pathname.split('/')[2] === 'contact-details') {
    const contactId = pathname.split('/')[3];
    yield put({ type: cnt.CLEAR_STORE });

    yield all([
      apiSaga(cnt.GET_CONTACT_INFO, contactApi.getContact, [{ contactId }]),
      _getFiles(contactId),
      _getActivities(contactId)
    ]);
  }
}

export function* refreshActivities() {
  const pathname = yield select(getRoute);
  if (pathname.split('/')[2] === 'contact-details') {
    const contactId = pathname.split('/')[3];
    yield* _getActivities(contactId);
  }
}

function* _getActivities(contactId) {
  yield* apiSaga(cnt.GET_ACTIVITY, partnerApi.getActivities, [
    { assetId: contactId }
  ]);
}

function* _getFiles(contactId) {
  yield* apiSaga(cnt.GET_FILES, conApi.getContracts, [
    {
      query: { contactIds: contactId },
      includeWorkspacesIfBuyer: true
    }
  ]);
}

function* markAsRead() {
  // markgin all conversation as read (general an contracting)
  const pathname = yield select(getRoute);
  if (pathname.split('/')[2] === 'contact-details') {
    const contactId = pathname.split('/')[3];
    yield apiSaga(cnt.CONVERSATIONS_READ, wpApi.conversationsRead, [
      { assetId: contactId, assetType: 'contact' }
    ]);
  }
}

function* doUpdateContactDetails({ type, payload }) {
  const contactId = yield select(
    state => state.PM.contactDetails.contactInfo.id
  );
  yield* apiSaga(type, contactApi.updateContactDetails, [
    {
      ...payload,
      contactId 
    }
  ]);
}

function* doUnassocMsg({ payload }) {
  const confirm = window.confirm(
    'Are you sure you want to remove this activity from this partner?'
  );
  if (confirm) {
    const assetId = yield select(state => state.PM.contactId.contactInfo.id);
    yield* apiSaga(cnt.UNASSOC_A_MESSAGE, partnerApi.unassocAMessage, [
      { messageId: payload.messageId, assetId }
    ]);
  }
}

function* doSearchPartners({ type, payload }) {
  const searchParams = {
    listBy: 'partner',
    searchInput: payload.searchInput,
    lastInteracted: {},
    owners: [],
    connectionStatuses: [],
    hasTagsCheckedForPartner: false,
    hasTagsForPartner: [],
    withoutTagsCheckedForPartner: false,
    withoutTagsForPartner: [],
    hasTagsCheckedForContact: false,
    hasTagsForContact: [],
    withoutTagsCheckedForContact: false,
    withoutTagsForContact: [],
    basicInfoFilters: [],
    defaultSearchInput: '',
    sortBy: {},
    fromUrl: true,
  }
  yield* apiSaga(type, pmApi.getPartners, [searchParams]);
}

function* doAssociatePartner({ type, payload }) {
  yield* apiSaga(type, contactApi.associatePartner, [payload]);
}
// eslint-disable-next-line
export default [
  takeLatest('@@router/LOCATION_CHANGE', getContactDetails),
  takeLatest(`@partner_contact_details/ADD_A_NOTE_SUCCESS`, refreshActivities),
  takeLatest(`${cnt.GET_ACTIVITY}_SUCCESS`, markAsRead),
  takeLatest(cnt.UPDATE_CONTACT_DETAILS, doUpdateContactDetails),
  takeLatest(`${cnt.UPDATE_CONTACT_DETAILS}_SUCCESS`, refreshContactInfo),
  takeLatest(cnt.UNASSOC_A_MESSAGE, doUnassocMsg),
  takeLatest([
    `${cnt.UNASSOC_A_MESSAGE}_SUCCESS`,
    `${cnt.UPDATE_CONTACT_DETAILS}_SUCCESS`,
  ], refreshActivities),
  takeLatest('@associate_partner/searchPartners', doSearchPartners),
  takeLatest('@associate_partner/associatePartner', doAssociatePartner),
  takeLatest('@associate_partner/associatePartner_SUCCESS', refreshContactInfo),
];
