import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
// import FormControl from '@mui/material/FormControl';
// import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
// import FormHelperText from '@mui/material/FormHelperText';
// import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';

type Props = TextFieldProps;

const StyledTF = styled(TextField)({
  label: {
    fontSize: '13px'
  },
  input: {
    fontSize: '12px'
  },
  textarea: {
    fontSize: '12px'
  }
});

export default function LayoutTextFields(props: Props) {
  return (
    <StyledTF
      variant="outlined"
      size="small"
      color="secondary"
      fullWidth
      {...props}
    />
  );
}
