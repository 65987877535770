import { combineReducers } from 'redux';
import * as R from 'ramda';
import workspaceList from '../workspace/__store__/workspace-list-reducer';
import contractList from '../contract-list/__store__/reducer';
import sendRequest from '../workspace/__store__/send-request-reducer';
import mergeWorkspaces from '../workspace/__store__/merge-workspaces-reducer';
import downloadDocs from './download-docs.reducer';
import ratesDocIframe, { requestSigs }  from './rates-doc-iframe.reducer';

/*
  folders: ['Tier 1', 'Tier 1/Bora', 'Tier 2', 'Tier 2/Bing', 'Follow Up', 'Tier 3', 'Tier 3/Bong/Chanandler'],
  folderStructure: {
    "Follow Up": {
        children: {},
        folderName: "Follow Up",
        folderPath: "Follow Up"
    },
    "Tier 1": {
        children: {
            Bora: {
                children: [],
                folderName: "Bora",
                folderPath: "Tier 1/Bora"
            }
        },
        folderName: "Tier 1",
        folderPath: "Tier 1"
    },
    "Tier 2": {
        children: {
            Bing: {
                children: [],
                folderName: "Bing",
                folderPath: "Tier 2/Bing"
            }
        },
        folderName: "Tier 2",
        folderPath: "Tier 2"
    },
    "Tier 3": {
        children: {
            Bong: {
                children: {
                    Chanandler: {
                        children: [],
                        folderName: "Chanandler",
                        folderPath: "Tier 3/Bong/Chanandler"
                    }
                },
                folderName: "Bong",
                folderPath: "Tier 3/Bong"
            }
        },
        folderName: "Tier 3",
        folderPath: "Tier 3"
    }
  },
*/
const getFolderStructure = (folders = []) => {
  return folders.reduce((result, folderPath) => {
    const pathArr = folderPath.split('>');
    if (R.path(R.intersperse('children', pathArr), result)) return result;
    if (pathArr.length === 1) {
      return {
        ...result,
        [folderPath]: {
          folderName: folderPath,
          folderPath,
          children: {},
        },
      };
    }
    const parentPath = R.dropLast(1, pathArr);
    const parentAbsolutePath = R.intersperse('children', parentPath);
    const folderName = R.last(pathArr);
    const node = R.pathOr({
      folderPath: parentPath.join('>'),
      folderName: R.last(parentPath),
      children: {},
    },
    parentAbsolutePath,
    result);
    const children = R.propOr({}, 'children', node);
    return R.set(
      R.lensPath(parentAbsolutePath),
      {
        ...node,
        children: {
          ...children,
          [folderName]: {
            folderPath,
            folderName,
            children: {},
          },
        },
      },
      result);
  }, {});
};
const intialMetaData = {
  folders: [],
  folderStructure: {},
  inited: false,
};

const metaData = (state = intialMetaData, { type, payload }) => {
  switch (type) {
    case '@contracting/init':
      return {
        ...state,
        inited: true,
      };
    case '@contracting/getAllFolders_SUCCESS':
      return {
        ...state,
        folders: R.sort(R.ascend, payload.folders),
        folderStructure: getFolderStructure(R.sort(R.ascend, payload.folders)),
      };
    default:
      return state;
  }
};

const initialSearchState = {
  searchInput: '',
  defaultSearchInput: '',
  contractingDate: {},
  createdDate: {},
  updatedDate: {},
  results: {
    workspaces: [],
    contracts: [],
    rateSheets: [],
  },
};

const search = (state = initialSearchState, { type, payload, args }) => {
  switch (type) {
    case '@contracting/updateSearchParams':
      return {
        ...state,
        ...payload,
      };
    case '@contracting/search_SUCCESS':
      return {
        ...state,
        results: {
          ...state.results,
          ...payload,
        },
      };
    case '@@router/LOCATION_CHANGE':
      if (payload.location.pathname.indexOf('/contracting/search-results') === -1
        && (state.searchInput || !R.isEmpty(state.contractingDate) || !R.isEmpty(state.createdDate) || !R.isEmpty(state.updatedDate))) {
          return initialSearchState;
      }
      return state;
    default:
      return state;
  }
};

const initialAssignFolder = {
  open: false,
  docIds: [],
  existingFolders: [],
};

const assignFolders = (state = initialAssignFolder, { type, payload, args }) => {
  switch (type) {
    case '@contracting/openAssignFolders':
      return {
        ...state,
        open: true,
        ...payload,
      };
    case '@contracting/assignFolders_SUCCESS':
    case '@contracting/closeAssignFolders':
      return initialAssignFolder;
    default:
      return state;
  }
};

export default combineReducers({
  metaData,
  workspaceList,
  contractList,
  search,
  assignFolders,
  sendRequest,
  mergeWorkspaces,
  ratesDocIframe,
  requestSigs,
  downloadDocs,
});
