import React  from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

const showProp = value => {
  if (!value) return '';
  return ` [${value}]`;
}

const SearchResults = ({
  searchResults,
  searching,
}) => {

  if (searching) {
    return (
      <Skeleton variant="rectangular" width={210} height={118} />
    );
  }
  if (Object.values(searchResults).find(e => e.length > 0)) {
    const rows = [];
    Object.entries(searchResults).forEach(([type, data]) => {
      data.forEach(item => {
        rows.push({
          type,
          ...item,
        });
      });
    });
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.type}</TableCell>
                <TableCell component="th" scope="row">
                  {row.company_name || row.email} {showProp(row.role)} {showProp(R.path(['address', 'country'], row))}
                </TableCell>
                <TableCell>
                  <Link
                    to={`/admin/logs/${(row.type === 'users') ? 'userId' : 'companyId'  }/${row._id}`}
                  >
                    {row._id}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return null;
};

const mapState = ({ admin: {
  searchResults,
  searching,
} }) => ({
  searchResults,
  searching,
});

export default hot(module)(connect(mapState, {})(SearchResults));
