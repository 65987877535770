/* eslint-disable import/no-anonymous-default-export */
export default ({ getState, dispatch }) => next => action => {
  const { type, payload } = action;
  const { PM, account } = getState();
  if (!account.company.plan || account.company.plan === 'basic') {
    if (type === '@send-assets/SET_DEFAULTS') {
      if (payload.recipients && payload.recipients.length > 5) {
        dispatch({
          type: 'SHOW_BULK_SEND_ASSETS_BLOCKER',
          payload: { skipBack: false }
        });
        return dispatch({
          type,
          payload: {
            ...payload,
            recipients: payload.recipients.slice(
              0,
              5 - PM.sendAssets.recipients.length
            )
          }
        });
      }
    }
    if (type === '@send-assets/SELECT_ALL_PARTNERS_CONTACTS') {
      if (payload.partners.length > 5) {
        return dispatch({
          type: 'SHOW_BULK_SEND_ASSETS_BLOCKER',
          payload: { skipBack: true }
        });
      }
    }
    if (type === '@send-assets/SELECT_PARTNER' && payload.checked) {
      if (PM.sendAssets.recipients.length + 1 > 5) {
        return dispatch({
          type: 'SHOW_BULK_SEND_ASSETS_BLOCKER',
          payload: { skipBack: true }
        });
      }
    }
    if (type === '@send-assets/SELECT_CONTACT' && payload.checked) {
      if (PM.sendAssets.recipients.length + 1 > 5) {
        return dispatch({
          type: 'SHOW_BULK_SEND_ASSETS_BLOCKER',
          payload: { skipBack: true }
        });
      }
    }
  }
  return next(action);
};
