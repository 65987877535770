const prefix = '@notifications/';
export const cnts = {
  GET_NOTIFICATIONS: `${prefix}GET_NOTIFICATIONS`,
  GET_NF_NOTIFICATIONS: `${prefix}GET_NF_NOTIFICATIONS`,
  GET_SM_NOTIFICATIONS: `${prefix}GET_SM_NOTIFICATIONS`,
  STATUS_CONNECTIONS_MARK_READ: `${prefix}STATUS_CONNECTIONS_MARK_READ`,
  ACCEPT_CONNECTION: `${prefix}ACCEPT_CONNECTION`,
  IGNORE_CONNECTION: `${prefix}IGNORE_CONNECTION`,
  MARK_NEWSFEED_AS_READ: `${prefix}MARK_NEWSFEED_AS_READ`,
  UPDATE_NEWSFEED_PROPS: `${prefix}UPDATE_NEWSFEED_PROPS`
};

export const getNotifications = () => ({
  type: cnts.GET_NOTIFICATIONS
});
export const markAcceptedRead = connectionIds => ({
  type: cnts.STATUS_CONNECTIONS_MARK_READ,
  payload: { connectionIds }
});
export const acceptConnection = connectionId => ({
  type: cnts.ACCEPT_CONNECTION,
  payload: { connectionId }
});
export const ignoreConnection = connectionId => ({
  type: cnts.IGNORE_CONNECTION,
  payload: { connectionId }
});

export const markNFAsRead = ids => ({
  type: cnts.MARK_NEWSFEED_AS_READ,
  payload: { ids }
});

export const updateNFProps = update => ({
  type: cnts.UPDATE_NEWSFEED_PROPS,
  payload: update
});
