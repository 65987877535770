import { combineReducers } from 'redux';
import * as R from 'ramda';

function metaData(
  state = {
    hashToken: '',
    initialLoading: true,
    error: '',
    allContractingDocs: []
  },
  { type, payload }
) {
  switch (type) {
    case 'AL_INIT':
      return {
        ...state,
        hashToken: payload.hashToken
      };
    case 'AL_retrieveTokenPayload_SUCCESS':
      return {
        ...state,
        ...payload
      };
    case 'AL_getContractRequestInfo_SUCCESS':
      return {
        ...state,
        allContractingDocs: R.uniqBy(o => o._id, [
          ...state.allContractingDocs,
          payload
        ])
      };
    case 'AL_finishedLoading':
      return {
        ...state,
        initialLoading: false
      };
    case 'AL_getContractRequestInfo_ERROR':
      return {
        ...state,
        initialLoading: false,
        error: payload
      };
    default:
      return state;
  }
}

const initialRequest = {
  requestId: '',
  contracts: [],
  submitting: false,
  submitTCRatesSuccess: false,
  submitExternalRatesSuccess: false,
  loadingRequest: false
};

function request(state = initialRequest, { type, payload }) {
  switch (type) {
    case 'AL_INIT':
      return {
        ...state,
        requestId: payload.contractId
      };
    case 'AL_updateRequestIdIfNotInQuery':
      return {
        ...state,
        requestId: payload.id
      };
    case 'AL_switchRequest':
      return {
        ...state,
        loadingRequest: true,
        requestId: payload.requestId
      };
    case 'AL_getContractRequestInfo_SUCCESS':
      if (payload._id === state.requestId) {
        return {
          ...state,
          ...payload,
          loadingRequest: false
        };
      }
      return state;
    case 'AL_getRespondedContracts_SUCCESS':
      return {
        ...state,
        contracts: payload.list
      };
    case 'AL_submitExternalRates':
    case 'AL_submitTCRates':
      return {
        ...state,
        submitting: true
      };
    case 'AL_submitExternalRates_ERROR':
    case 'AL_submitTCRates_ERROR':
      return {
        ...state,
        submitting: false
      };
    case 'AL_submitTCRates_SUCCESS':
      return {
        ...state,
        submitting: false,
        submitTCRatesSuccess: true
      };
    case 'AL_submitExternalRates_SUCCESS':
      return {
        ...state,
        submitting: false,
        submitExternalRatesSuccess: true
      };
    case 'AL_dismissSuccessScreen':
      return {
        ...state,
        submitTCRatesSuccess: false,
        submitExternalRatesSuccess: false
      };
    default:
      return state;
  }
}

const initialWizardState = {
  wizardOpen: false,
  wizardTitle: 'Create Your First Rate Sheet',
  wizardStep: 'askQ',
  wizardPrev: [],
  sourceTemplates: [],
  hideOneOrMulti: true,
  creating: false
};
const initialSupplierState = {
  supplierId: '',
  supplierName: '', // need to get it from the contract request, it's the partner name
  oneOrMulti: '',
  productType: '',
  locations: [],
  templates: [],
  contractsSubmitted: [],
  drafts: [],
  docsToSign: [],
  loadingRequest: false,
  ...initialWizardState
};
const _populateLocs = locations => [
  ...locations,
  {
    name: '',
    products: []
  }
];
function supplier(state = initialSupplierState, { type, payload = {} }) {
  switch (type) {
    case 'AL_getSupplierDataForALRequest_SUCCESS':
      return {
        ...state,
        ...payload,
        oneOrMulti: payload.oneOrMulti || 'multi',
        locations: payload.locations.length
          ? _populateLocs(payload.locations)
          : [
              {
                name: '',
                checked: false,
                products: []
              }
            ]
      };
    case 'AL_updateWizardPropsForCreatingTemplate':
      return {
        ...state,
        ...payload
      };
    case 'AL_createRateSheets':
      return {
        ...state,
        creating: true
      };
    case 'AL_createRateSheets_ERROR':
      return {
        ...state,
        creating: false
      };
    case 'AL_createRateSheets_SUCCESS':
    case 'AL_onCloseWizard':
      return {
        ...state,
        ...initialWizardState
      };
    default:
      return state;
  }
}

const initialBuyerDummyState = {
  templates: [],
  drafts: []
};

function buyerDummy(state = initialBuyerDummyState, { type, payload }) {
  switch (type) {
    case 'AL_getBuyerDummyData_SUCCESS':
      return {
        ...state,
        templates: payload.dummySupplierForCR.templatesForAL.map(o => ({
          ...o,
          isBuyerRates: true
        }))
      };
    default:
      return state;
  }
}

const initialIframe = {
  url: '',
  open: false
};

function iframe(state = initialIframe, { type, payload }) {
  switch (type) {
    case 'AL_setIframe':
      return {
        ...state,
        ...payload
      };
    case 'AL_closeIframe':
      return initialIframe;
    default:
      return state;
  }
}

const initialSignUpState = {
  open: false,
  signingUp: false,
  signUpSuccess: false,
  password: '',
  confirmPw: '',
  templateListMagicLink: '',
  companyName: ''
};

function supplierSignUp(state = initialSignUpState, { type, payload }) {
  switch (type) {
    case 'AL_requestOpenSupplierSignUp':
      return {
        ...state,
        open: true
      };
    case 'AL_requestSupplierSignUpUpdateProps':
      return {
        ...state,
        ...payload
      };
    case 'AL_requestSupplierDoSignUp':
      return {
        ...state,
        signingUp: true
      };
    case 'AL_requestSupplierDoSignUp_ERROR':
      return {
        ...state,
        signingUp: false
      };
    case 'AL_requestSupplierDoSignUp_SUCCESS':
      return {
        ...state,
        signingUp: false,
        signUpSuccess: true,
        templateListMagicLink: payload.templateListMagicLink
      };
    case 'AL_requestSupplierSignUpDismiss':
      return initialSignUpState;
    default:
      return state;
  }
}

const initialPowerUser = {
  open: false,
  template: {},
  /*
    option enum: [makeCopy, split]
      makeCopy: user attempt to edit a template before submitting to buyerA,
        but that template has been previously submitted to other buyers.
        We will offer user to create an initial template draft and import all data from this template
      split: user attempt to update a contract, whose template has been submitted to multiple buyers
        We will offer user to create an edited template draft for this contract
  */
  from: '',
  contract: {},
  callingApi: false
};

function powerUserEdit(state = initialPowerUser, { type, payload }) {
  switch (type) {
    case 'AL_openPowerUser':
      return {
        ...state,
        ...payload,
        open: true
      };
    case 'AL_powerUserUpdateProps':
      return {
        ...state,
        ...payload
      };
    case 'AL_createDraftForContractUpdates':
    case 'AL_createNewAndImportForEdit':
      return {
        ...state,
        callingApi: true
      };
    case 'AL_createDraftForContractUpdates_ERROR':
    case 'AL_createNewAndImportForEdit_ERROR':
      return {
        ...state,
        callingApi: true
      };
    case 'AL_createDraftForContractUpdates_SUCCESS':
    case 'AL_createNewAndImportForEdit_SUCCESS':
    case 'AL_powerUserDismiss':
      return initialPowerUser;
    default:
      return state;
  }
}
export default combineReducers({
  metaData,
  request,
  supplier,
  buyerDummy,
  iframe,
  supplierSignUp,
  powerUserEdit
});
