const initialState = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  companyName: '',
  dateFormat: 'DD/MM/YYYY',
  emailClient: '',
  companyId: '',
  userId: '',
  genAiProduct: '',
  autoLoginLink: '',
  shouldSend3rdPartyEmail: false,
  checkingEmail: false,
  emailChecked: false,
  signingUp: false,
  signedUp: false,
  schedulesOpen: false,
  error: '',
  successMessage: '',
};


export default function generativeAiSignUp(state = initialState, { type, payload }) {
  switch(type) {
    case 'generativeAiSignUp_onLoad': {
      return {
        ...state,
        genAiProduct: payload.genAiProduct,
      };
    }
    case 'generativeAiSignUp_updateProps': {
      return {
        ...state,
        ...payload,
      }
    }
    case 'generativeAiSignUp_doEmailCheck': {
      return {
        ...state,
        checkingEmail: true,
      };
    }
    case 'generativeAiSignUp_doEmailCheck_SUCCESS': {
      return {
        ...state,
        userId: payload.userId,
        companyId: payload.companyId,
        checkingEmail: false,
        emailChecked: true,
        error: '',
      };
    }
    case 'generativeAiSignUp_doEmailCheck_ERROR': {
      return {
        ...state,
        checkingEmail: false,
        emailChecked: false,
        error: payload,
      };
    }
    case 'generativeAiSignUp_doSignUp': {
      return {
        ...state,
        signingUp: true,
      };
    }
    case 'generativeAiSignUp_doSignUp_SUCCESS': {
      return {
        ...state,
        signingUp: false,
        signedUp: Boolean(payload.companyId),
        error: payload.companyId ? '' : state.error,
        autoLoginLink: payload.autoLoginLink,
      };
    }
    case 'generativeAiSignUp_doSignUp_ERROR': {
      return {
        ...state,
        signingUp: false,
        signedUp: false,
        error: 'There is an error, please try again or contact support@tourconnect.com.',
      };
    }
    case 'generativeAiSignUp_openMeetingSchedules': {
      return {
        ...state,
        schedulesOpen: true,
      };
    }
    case 'generativeAiSignUp_successMessage':
      return {
        ...state,
        successMessage: payload,
      };
    default:
      return state;
  }
}