import { cnts } from './actions';
import { takeLatest, all } from 'redux-saga/effects';
import { notifications as apis } from '../../../../api';
import { apiSaga } from '../../../../root-sagas';

function* doGetNotifications() {
  yield all([
    apiSaga(cnts.GET_NF_NOTIFICATIONS, apis.getNFNotifications, []),
    apiSaga(cnts.GET_SM_NOTIFICATIONS, apis.getStatusMessage, [])
  ]);
}

function* markAcceptedRead({ type, payload }) {
  yield* apiSaga(type, apis.markAcceptedRead, [payload.connectionIds]);
}

function* acceptConnection({ type, payload }) {
  yield* apiSaga(type, apis.acceptConnection, [payload.connectionId]);
}

function* rejectConnection({ type, payload }) {
  yield* apiSaga(type, apis.rejectConnection, [payload.connectionId]);
}

function* markNFAsRead({ type, payload }) {
  yield* apiSaga(type, apis.markNFAsRead, [payload.activityIds]);
}
function* doGetVerified({ type }) {
  window.open('https://www.tourconnect.com/tc-verified/');
  yield* apiSaga(type, apis.sendNotification, [{ action: 'getVerified' }]);
}

export const notifications = [
  takeLatest(cnts.GET_NOTIFICATIONS, doGetNotifications),
  takeLatest(cnts.STATUS_CONNECTIONS_MARK_READ, markAcceptedRead),
  takeLatest(cnts.ACCEPT_CONNECTION, acceptConnection),
  takeLatest(cnts.IGNORE_CONNECTION, rejectConnection),
  takeLatest(cnts.MARK_NEWSFEED_AS_READ, markNFAsRead),
  takeLatest(
    `${cnts.STATUS_CONNECTIONS_MARK_READ}_SUCCESS`,
    doGetNotifications
  ),
  takeLatest(`${cnts.ACCEPT_CONNECTION}_SUCCESS`, doGetNotifications),
  takeLatest(`${cnts.IGNORE_CONNECTION}_SUCCESS`, doGetNotifications),
  takeLatest(`${cnts.MARK_NEWSFEED_AS_READ}_SUCCESS`, doGetNotifications),
  takeLatest('{profile} getVerified', doGetVerified)
];
