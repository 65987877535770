import * as cnt from './constants';

const initialState = {
  activities: [],
  activitiesLoading: false,
  activitiesLoaded: false,
  partners: [],
  partnersLoading: false,
  partnersLoaded: false,
  files: [],
  filesLoading: false,
  filesLoaded: false,
  contactInfo: {},
  contactInfoLoading: false,
  contactInfoLoaded: false,
};
// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${cnt.GET_CONTACT_INFO}_STARTED`:
      return { ...state, contactInfoLoading: true, partnersLoading: true };
    case `${cnt.GET_CONTACT_INFO}_ERROR`:
      return {
        ...state,
        contactInfoLoading: false,
        partnersLoading: false,
        error: payload
      };
    case `${cnt.GET_CONTACT_INFO}_SUCCESS`:
      const { contact, partners, tags } = payload;
      return {
        ...state,
        partnersLoading: false,
        partnersLoaded: true,
        contactInfoLoading: false,
        contactInfoLoaded: true,
        contactInfo: contact,
        tags,
        partners,
        error: ''
      };
    case `${cnt.GET_FILES}_STARTED`:
      return { ...state, filesLoading: true };
    case `${cnt.GET_FILES}_SUCCESS`:
      return {
        ...state,
        files: payload.contracts,
        filesLoaded: true,
        filesLoading: false
      };
    case `${cnt.GET_FILES}_ERROR`:
      return { ...state, filesLoaded: false, filesLoading: false };
    case `${cnt.GET_ACTIVITY}_STARTED`:
      return { ...state, activitiesLoading: true };
    case `${cnt.GET_ACTIVITY}_SUCCESS`:
      return {
        ...state,
        activitiesLoaded: true,
        activitiesLoading: false,
        activities: payload
      };
    case `${cnt.GET_ACTIVITY}_ERROR`:
      return { ...state, activitiesLoaded: false, activitiesLoading: false };
    case cnt.CLEAR_STORE:
      return initialState;
    default:
      return state;
  }
};
