import {
  // all, 
  // call,
  delay,
  put,
  spawn,
} from 'redux-saga/effects';
// import * as R from 'ramda';

import { apiSaga } from '../root-sagas';
import {
  matcher as apiMatch,
} from '../api';

export function fetchAsyncJob (jobName, ep) {
  return function* ({ jobs, additionalPayload }) {
    const workingStatus = ['pending', 'working', 'active', 'waiting'];
    // const successStatus = ['succeeded', 'success'];
    // const completedStatus = [...successStatus, 'failure', 'failed', 'stuck'];
    const statusResponse = yield apiSaga(`${jobName}_checkStatus`, ep || apiMatch.checkStatus, [{
      jobs,
      skipLoader: true,
    }]);
    if (!Array.isArray(statusResponse)) { // something wrong happened
      yield spawn(fetchAsyncJob(jobName, ep), { jobs, additionalPayload });
      return;
    }
    const pendings = statusResponse.filter(
      ({ status }) => workingStatus.includes(status)
    );
    // const completed = statusResponse.filter(({ status }) => completedStatus.includes(status));
    if (pendings.length > 0) {
      yield delay(3e3);
      yield spawn(fetchAsyncJob(jobName, ep), { jobs, additionalPayload });
    } else {
      yield put({ type: `${jobName}_JobCheckComplete`, payload: statusResponse, additionalPayload });
    }
  }
}
