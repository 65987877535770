// import * as R from 'ramda';
import {
  takeLatest,
  select,
  spawn,
  // put,
} from 'redux-saga/effects';
import {
  generativeAi as genAiApis,
} from '../../../api.js';
import { apiSaga } from '../../../root-sagas.js';
import { fetchAsyncJob as fetchAsyncJobImport } from '../../../utils/fetchAsyncJob.js';

function* getItineraries(payload = {}) {
  yield apiSaga('genAi_getItineraries', genAiApis.getItineraries, [payload])
}

// function* getItinerary(id) {
//   yield apiSaga('genAi_getItinerary', genAiApis.getItinerary, [id])
// }

function* saveItineray(payload) {
}

function* onLocationChange({ payload }) {
  // if (R.path(['location', 'pathname'], payload).includes('generative-ai/itineraryAssist/list')) {
  //   yield getItineraries();
  // }
  // if (R.path(['location', 'pathname'], payload).includes('/generative-ai/itineraryAssist/itinerary/')) {
  //   const id = R.path(['location', 'pathname'], payload).replace('/generative-ai/itineraryAssist/itinerary/', '');
  //   yield put({ type: 'genAI_setCurrentItineraryId', payload: { id } });
  //   yield getItinerary(id);
  // }
}

function* doGenerateQuote() {
  const fileDialog = yield select(state => state.generativeAi.itineraries.fileDialog);
  // Step One, call bb-api to update files in database (files should be automatically uploaded during apiSaga)
  const { files, id: itineraryId } = yield saveItineray({
    id: fileDialog.itineraryId,
    files: fileDialog.files,
  });
  // Step Two, call ai to generate quote
  const apiPayload = {
    itineraryId,
    attachments: files,
    prompt: ``,
    skipLoader: true,
  }
  const retVal = yield apiSaga('genAI_generateQuote', genAiApis.generateQuote, [apiPayload])
  if (retVal) {
    const { jobs } = retVal;
    if (jobs && jobs.length > 0) {
      yield spawn(fetchAsyncJobImport('genAI_generateQuoteJob', genAiApis.checkVectorJobStatus), { jobs });
      yield saveItineray({ id: fileDialog.itineraryId, jobs, lastAction: 'Generating Quote' })
    }
  }
}

function* onGenerateQuoteJobSuccess({ payload }) {
  yield saveItineray({
    id: payload[0].itineraryId,
    jobs: payload,
    lastAction: payload.some(o => o.status !== 'success') ? 'Process Failed' : 'Quote Ready',
  });
}

function* onSaveItinerarySuccess() {
  yield getItineraries();
}

// eslint-disable-next-line
export default [
  takeLatest('@@router/LOCATION_CHANGE', onLocationChange),
  takeLatest('genAI_generateQuote', doGenerateQuote),
  takeLatest('genAI_generateQuoteJob_JobCheckComplete', onGenerateQuoteJobSuccess),
  takeLatest('genAi_saveItinerary_SUCCESS', onSaveItinerarySuccess),
];