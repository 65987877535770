import * as cnt from './constants';

const initialState = {
  indexLoading: false,
  indexError: false,
  helpIndex: [],
  contentLoading: false,
  contentError: false,
  helpArticle: undefined
};

const regExpEscape = s => s.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
const wildcardToRegExp = s =>
  new RegExp(
    `^${s
      .split(/\*+/)
      .map(regExpEscape)
      .join('.*')}$`
  );

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  return {
    ...state,
    ...(() => {
      switch (type) {
        case `${cnt.LOAD_HELPFILES}_STARTED`:
          return { indexLoading: true };
        case `${cnt.LOAD_HELPFILES}_ERROR`:
          return { indexError: false, indexLoading: false };
        case `${cnt.LOAD_HELPFILES}_SUCCESS`:
          const helpIndex = payload.items.map(e => ({
            expressions: e.fields.matchingUrls.map(wildcardToRegExp),
            entryId: e.sys.id,
            matchingRole: e.fields.matchingRole.map(role =>
              role.replace('Buyer', 'Contractor')
            ),
            title: e.fields.title
          }));
          return {
            indexError: false,
            indexLoading: false,
            helpIndex
          };
        case `${cnt.LOAD_HELP_ARTICLE}_STARTED`:
          return { contentLoading: true };
        case `${cnt.LOAD_HELP_ARTICLE}_ERROR`:
          return { contentLoading: false, contentError: true };
        case `${cnt.LOAD_HELP_ARTICLE}_SUCCESS`:
          const {
            items: [
              {
                sys: { id: entryId },
                fields
              }
            ],
            includes: { Entry = [] } = [{ Entry: [] }]
          } = payload;
          return {
            contentLoading: false,
            contentError: false,
            helpArticle: {
              entryId,
              ...fields,
              onThisPage: (fields.onThisPage || []).map(e => ({
                id: e.sys.id,
                ...Entry.find(include => include.sys.id === e.sys.id).fields
              }))
            }
          };
        default:
          return {};
      }
    })()
  };
};
