import * as moment from 'moment';

const initialStateForDownloadDocs = {
  docId: '',
  downloadRateFormat: '',
  contractIds: [], // for zip download contracts only
  templates: [], // for zip download template group only
  templateGroupName: '', // for zip download template group only
  collection: '', // draft, template, contract
  locale: moment().utcOffset(),
  open: false,
  anchorEl: null,
  validLengthForBulkDownload: 0,
  downloadedCount: 0,
  loading: false,
  success: false,
  error: false,
  forAL: false,
  fromEmail: false,
  wouldYouAcceptDialog: false,
  downloadUrl: null,
};
export default function downloadDocs(state = initialStateForDownloadDocs, { type, payload }) {
  switch (type) {
    case '[contracting-download]OPEN_DOWNLOAD_CONTRACTING_DOCS_POPOVER':
      return {
        ...state,
        ...payload,
        downloadRateFormat: (() => {
          if (payload.downloadRateFormat) return payload.downloadRateFormat;
          if (state.downloadRateFormat) return state.downloadRateFormat;
          if (localStorage && localStorage.getItem('rateFormat')) return localStorage.getItem('rateFormat');
          return 'condensed';
        })(),
        open: true,
      };
    case '[contracting-download]UPDATE_PROPS':
      return {
        ...state,
        ...payload,
      };
    case '[contracting-download]ASK_ACCEPT_AFTER_DOWNLOAD':
      return {
        ...state,
        wouldYouAcceptDialog: true,
      };
    case '[contracting-download]DOWNLOAD_SINGLE_CONTRACT_DOC_START':
    case '[contracting-download]BULK_DOWNLOAD_STARTED':
    case '[contracting-download] downloadGroup_STARTED':
      return {
        ...state,
        loading: true,
        downloadUrl: null,
      };
    case '[contracting-download] downloadGroup_RETRY':
      return {
        ...state,
        loading: false,
        success: false,
        error: false,
      };
    case '[contracting-download]DOWNLOAD_SINGLE_CONTRACT_DOC_SUCCESS':
    case '[contracting-download]BULK_DOWNLOAD_SUCCESS':
    case '[contracting-download] downloadGroup_SUCCESS_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        downloadUrl: payload.url,
      };
    case '[contracting-download]BULK_DOWNLOAD_ERROR':
    case '[contracting-download] downloadGroup_ERROR':
    case '[contracting-download] downloadGroup_SUCCESS_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
      };
    case '[contracting-download]EXPORT_EXCEL_STARTED':
      return {
        ...state,
        loading: true,
      };
    case '[contracting-download]EXPORT_EXCEL_ERROR':
    case '[contracting-download]EXPORT_EXCEL_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case '[contracting-download]ACCEPT_RATES_STARTED':
    case '[contracting-download]ACCEPT_RATES_SUCCESS':
    case '[contracting-download]CLOSE_DOWNLOAD_POPOVER':
      return {
        ...initialStateForDownloadDocs,
        downloadRateFormat: state.downloadRateFormat,
      };
    default:
      return state;
  }
}
