import { combineReducers } from 'redux';
import { partnerListCnts as plCnts, pmCnts } from './pm-actions';
import { ThisActionType } from './pm-store-types';
import { PartnerType, ContactType, PartnerListSearchParams } from 'AllTypes';
import * as R from 'ramda';

type StateType = {
  partnerSelected: string[];
  contactSelected: string[];
  partners: PartnerType[];
  contacts: ContactType[];
  totalNo: number;
  initialLoaded: boolean;
  initialContactsLoaded: boolean;
  loadingList: boolean;
};

const initialState = {
  partnerSelected: [],
  contactSelected: [],
  partners: [],
  contacts: [],
  totalNo: 0,
  initialLoaded: false,
  initialContactsLoaded: false,
  loadingList: false
};

function list(state: StateType = initialState, action: ThisActionType) {
  switch (action.type) {
    case pmCnts.EXPORT_CSV:
    case plCnts.GET_PARTNERS_STARTED:
    case plCnts.GET_CONTACTS_STARTED:
    case plCnts.REFRESH_CONTACTS_STARTED:
    case plCnts.REFRESH_PARTNERS_STARTED:
      return {
        ...state,
        loadingList: true
      };
    case pmCnts.EXPORT_CSV_SUCCESS:
      return {
        ...state,
        loadingList: false
      };
    case plCnts.GET_PARTNERS_SUCCESS:
    case plCnts.REFRESH_PARTNERS_SUCCESS:
      const {
        payload: { partners }
      } = action;
      return {
        ...state,
        initialLoaded: true,
        loadingList: false,
        partners,
        totalNo: action.payload.partners.length
      };
    case plCnts.GET_CONTACTS_SUCCESS:
    case plCnts.REFRESH_CONTACTS_SUCCESS:
      return {
        ...state,
        initialContactsLoaded: true,
        loadingList: false,
        contacts: action.payload.contacts,
        totalNo: action.payload.contacts.length
      };
    case plCnts.SELECT_AN_ITEM:
      if (action.payload.type === 'partner') {
        return {
          ...state,
          partnerSelected: action.payload.checked
            ? [...state.partnerSelected, action.payload.id]
            : state.partnerSelected.filter(
                (x: string) => x !== action.payload.id
              )
        };
      }
      return {
        ...state,
        contactSelected: action.payload.checked
          ? [...state.contactSelected, action.payload.id]
          : state.contactSelected.filter((x: string) => x !== action.payload.id)
      };
    case pmCnts.MERGE_PARTNERS_SUCCESS:
      return {
        ...state,
        partnerSelected: []
      };
    case plCnts.SELECT_ALL_ITEMS:
      if (!action.payload.checked) {
        return {
          ...state,
          partnerSelected: [],
          contactSelected: []
        };
      }
      if (action.payload.type === 'partner') {
        return {
          ...state,
          partnerSelected: state.partners.map(R.prop('id'))
        };
      }
      return {
        ...state,
        contactSelected: state.contacts.map(R.prop('id'))
      };
    case pmCnts.ARCHIVE_CONTACTS_SUCCESS:
    case pmCnts.ARCHIVE_PARTNERS_SUCCESS:
      return {
        ...state,
        partnerSelected: [],
        contactSelected: []
      };
    default:
      return state;
  }
}

export const initialSearchParams = {
  listBy: 'partner',
  searchInput: '',
  lastInteracted: {},
  owners: [],
  connectionStatuses: [],
  hasTagsCheckedForPartner: false,
  hasTagsForPartner: [],
  withoutTagsCheckedForPartner: false,
  withoutTagsForPartner: [],
  hasTagsCheckedForContact: false,
  hasTagsForContact: [],
  withoutTagsCheckedForContact: false,
  withoutTagsForContact: [],
  basicInfoFilters: [],
  defaultSearchInput: '',
  sortBy: {
    column: '',
    arrow: ''
  },
  fromUrl: false
} as PartnerListSearchParams;

function searchParams(
  state: PartnerListSearchParams = initialSearchParams,
  action: ThisActionType
) {
  switch (action.type) {
    case plCnts.DO_SEARCH_WITH_PARAMS:
      if (state.listBy !== action.payload.listBy) {
        return {
          ...initialSearchParams,
          listBy: action.payload.listBy,
        };
      }
      return {
        ...state,
        ...action.payload
      };
    case plCnts.SORT_LIST:
      return {
        ...state,
        sortBy: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}

export default combineReducers({
  list,
  searchParams
});
