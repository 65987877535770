import { takeLatest, takeEvery, select, put } from 'redux-saga/effects';
import * as cnt from './constants';
import { apiSaga } from '../../../root-sagas';
import { contentful as Api } from '../../../api';

const getPath = state => state.router.location.pathname;
const getLoadingIndex = state => state.sidebarHelp.helpIndex;
const getCurrentHelp = state => state.sidebarHelp.helpArticle;
const getCompanyType = state =>
  state.assetsLandingReducer
    ? state.assetsLandingReducer.viewAs
    : state.account.user.type;
const getSupplierType = state =>
  state.assetsLandingReducer ? '' : state.account.company.productType; // TODO: test

function* init() {
  // get help index list
  yield* apiSaga(cnt.LOAD_HELPFILES, Api.sidebarHelp.getAllEntries, []);
}

function* fetchHelp() {
  const path = yield select(getPath);
  const companyType = yield select(getCompanyType);
  const supplierType = (companyType === 'Supplier'
    ? yield select(getSupplierType)
    : ''
  ).replace(/-/gi, ' ');
  const fullIndex = yield select(getLoadingIndex);
  const helpIndex = fullIndex.filter(
    ({ matchingRole }) =>
      matchingRole.indexOf('Everyone') > -1 ||
      (companyType === 'Contractor' &&
        matchingRole.indexOf(companyType) > -1) ||
      (companyType === 'Supplier' &&
        matchingRole.indexOf(companyType) > -1 &&
        matchingRole.indexOf(supplierType) > -1)
  );
  if (helpIndex.length > 0) {
    const helpArticle = (() => {
      const indexFound = helpIndex.findIndex(article =>
        article.expressions.some(expression => expression.test(path))
      );
      if (indexFound > -1) return indexFound;
      return helpIndex.findIndex(article =>
        article.expressions.some(expression => expression.test('404'))
      );
    })();
    const currentHelp = yield select(getCurrentHelp);
    if (helpIndex[helpArticle]) {
      const { entryId } = helpIndex[helpArticle];
      const { entryId: currentEntryId, title } = currentHelp || {
        entryId: null
      };
      if (currentEntryId !== entryId) {
        yield* apiSaga(cnt.LOAD_HELP_ARTICLE, Api.sidebarHelp.getHelp, [
          entryId
        ]);
      } else {
        // opened help is already loaded
        yield put({
          type: 'TRACK_EVENT',
          payload: {
            ga: {
              eventCategory: 'sidebarhelp',
              eventAction: 'open',
              eventLabel: title,
              eventValue: entryId
            }
          }
        });
      }
    }
  }
}

function* trackOpen({
  payload: {
    items: [
      {
        fields: { title },
        sys: { id }
      }
    ]
  }
}) {
  // TRACK_EVENT
  yield put({
    type: 'TRACK_EVENT',
    payload: {
      ga: {
        eventCategory: 'sidebarhelp',
        eventValue: id,
        eventAction: 'open',
        eventLabel: title
      }
    }
  });
}

function* closeHelp() {
  yield put({ type: 'HELPWIDGET_CLOSE' });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest('HELPWIDGET_OPEN', fetchHelp),
  takeLatest(cnt.SIDEBARHELP_INIT, init),
  takeLatest('@@router/LOCATION_CHANGE', closeHelp),
  takeEvery(`${cnt.LOAD_HELP_ARTICLE}_SUCCESS`, trackOpen)
];
