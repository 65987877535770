import moment from 'moment';

export const navLang = (() => {
  if (typeof navigator.languages !== 'undefined') return navigator.languages[0];
  else return navigator.language;
})();

const isUS = (() => {
  return /(-US)\b/.test(navLang);
})();

export const dateFormat = () => (isUS ? 'MM/DD/YYYY' : 'DD/MM/YYYY');
export const dateFormatPicker = () => (isUS ? 'MM/dd/yyyy' : 'dd/MM/yyyy');

export const utcToLocal = dateParam => {
  if (dateParam && moment(dateParam).isValid()) {
    const utcDate = moment.utc(dateParam).toDate();
    const localDate = new Date(
      utcDate.getFullYear(),
      utcDate.getMonth(),
      utcDate.getUTCDate()
    ).toISOString();
    return localDate;
  }
};
export const localToUtc = dateParam => {
  if (moment(dateParam).isValid()) {
    const utcDate = new Date(
      Date.UTC(
        dateParam.getFullYear(),
        dateParam.getMonth(),
        dateParam.getDate()
      )
    ).toISOString();
    return utcDate;
  }
};
