/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import CreatableMultiSelect, {
  SelectValueType,
  // ActionMetaType,
  OptionType
} from './creatable-multi-select';
import { PMTagType } from 'AllTypes';
import { SxProps } from '@mui/system';

type Props = {
  allTags: PMTagType[];
  onChange: (tags: PMTagType[]) => void;
  isCreatable?: boolean;
  tagSelected?: PMTagType[] | [];
  sx?: SxProps;
};

export default (props: Props) => {
  const { isCreatable, allTags = [], onChange, tagSelected, sx } = props;
  const options = allTags.map((tag: PMTagType) => ({
    label: tag.name,
    value: tag.name,
    original: tag
  }));
  const [tags = [], setTags] = React.useState<PMTagType[] | [] | undefined>(
    tagSelected
  );
  React.useEffect(() => {
    if (tagSelected && tagSelected.length) setTags(tagSelected);
  }, [tagSelected]);
  const value = tags.map((tag: PMTagType) => ({
    label: tag.name,
    value: tag.name,
    original: tag
  }));
  if (isCreatable) {
    const onChangeCreatableTags = (newValue: SelectValueType) => {
      let thisSelected = [];
      if (newValue && Array.isArray(newValue)) {
        thisSelected = newValue.map(val => {
          if (val.__isNew__) {
            return {
              name: val.label,
              id: 'new'
            };
          }
          return val.original;
        });
      }
      onChange(thisSelected);
      return setTags(thisSelected);
    };
    return (
      <CreatableMultiSelect
        freeSolo
        placeholder="Select tags..."
        options={options}
        value={value}
        onChange={onChangeCreatableTags}
        sx={sx}
      />
    );
  }

  const onChangeTags = (newValue: SelectValueType) => {
    let thisSelected = [];
    if (newValue && Array.isArray(newValue)) {
      thisSelected = newValue.map((val: OptionType) => val.original);
    }
    onChange(thisSelected);
    return setTags(thisSelected);
  };
  return (
    <CreatableMultiSelect
      options={options}
      placeholder="Select tags..."
      value={value}
      onChange={onChangeTags}
      freeSolo={false}
      sx={sx}
    />
  );
};
